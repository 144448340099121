<template>

  <span class="isKautionBezahltWrapper">

    <span class="strikediag"
              v-if="isKautionBefreit"
              v-b-tooltip.hover.right
              title="Studierende*r ist von Kaution befreit.">
      <b-icon icon="cash-coin"></b-icon>
    </span>
    <span v-else-if="value.bewerbungen == null || value.bewerbungen.length < 1"
              v-b-tooltip.hover.right
              title="Noch keine Bewerbungen vorhanden.">
      <b-icon icon="x" style="color: red"></b-icon>
    </span>
    <span v-else-if="value.bewerbungen && value.bewerbungen.length > 0">

      <ul class="list-unstyled">
        <li v-for="bewerbung in value.bewerbungen" :key="bewerbung.id">
          <kautions-status :bewerbung="bewerbung" :bewerbungen="value.bewerbungen" />
        </li>
      </ul>

    </span>
    <span v-else-if="status == 'KEINE_ZAHLUNG_NOETIG'"
          v-b-tooltip.hover.right
          title="AZ SGB nicht erforderlich.">
      <b-icon icon="check" style="color: green"></b-icon>
    </span>
    <span v-else-if="kautionNoetig"
          v-b-tooltip.hover.right
          title="AZ SGB nicht bezahlt">
      <b-icon icon="exclamation-circle" style="color: red"></b-icon>
    </span>

  </span>

</template>

<script>

  import axios from 'axios'
  import KautionsStatus from '@/components/bewerbung/kautionsStatus.vue'
  axios.defaults.withCredentials = true

  export default {

    name: 'is-kaution-bezahlt',
    components: {
      KautionsStatus
    },
    props: ['value'], // = studentUser Objekt
    data () {
      return {
        status: null,
        title: null,
        isKautionBefreit: false,
        /** noch keine abgeschlossene Bewerbung, aber Kaution nötig */
        kautionNoetig: false,
      }
    },
    watch: {

      value: function () {
        this.init()
      }

    },
    mounted: function () {
      this.init()
    },
    methods: {

      init: function () {

        if (this.value && this.value.id) {

          this.status = null
          this.title = null
          const that = this
          const url = process.env.VUE_APP_SERVER_URL + '/kaution/isKautionBezahlt'
          const params = {
            user_id: this.value.id
          }
          axios.get(url, {
            params: params,
            withCredentials: true
          }).then( response => {

            that.status = response.data.isKautionBezahlt
            if (response.data.isKautionBezahlt == 'KAUTION_BEFREIT') {
              console.log('Student ist Kautionsbefreit - nichts anzeigen', response.data)
              that.isKautionBefreit = true
            } else if (response.data.isKautionBezahlt != 'KAUTION_KEINE_ZAHLUNG_NOETIG') {
              that.kautionNoetig = true
            }

          })

        }

      },

    }

  }

</script>
