<template>

  <div id="fhUserWrapper">

    <transition name="fade" mode="out-in">

      <div>

          <nav id="nav" class="mb-3">

            <div class="row">
              <div class="col-md-12 text-right">

                <button class="button secondary black btn-sm" @click="addUser">
                  <b-icon icon="plus-circle"></b-icon> Benutzer anlegen
                </button>
                <button class="button secondary black btn-sm" @click="listStudenten">
                  <b-icon icon="file-person"></b-icon> Studenten
                </button>
                <button class="button secondary black btn-sm" @click="logout(true)">logout</button>

              </div>
            </div>

          </nav>

          <check-login></check-login>

          <h1>FH Benutzer</h1>

          <div v-if="benutzerliste && benutzerliste.length > 0">

            <!-- Ergebnis Tabelle Filtern -->
            <div id="filter" class="row border-top mb-3 pt-3">

              <div class="col-md-11">

                <b-input-group size="sm">
                  <b-form-input
                    v-model="keyword"
                    type="search"
                    id="filterInput"
                    placeholder="Filtern ...">
                  </b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                  </b-input-group-append>
                </b-input-group>

              </div>
              <div class="col-md-1 text-right">

                <b-form-group horizontal label="" class="">
                  <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
                </b-form-group>

              </div>

            </div>

            <b-table striped
                     small
                     :fields="fields"
                     :items="items"
                     :keyword="keyword"
                     :current-page="currentPage"
                     :per-page="perPage"
                     hover
                     :responsive="true">

              <template #cell(role)="row">
                <span v-if="row.item.role == 'ROLE_ADMIN'">
                  Administrator
                </span>
                <span v-else>
                  FH Benutzer
                </span>
              </template>

              <template #cell(actions)="row">
                <b-button variant="outline-primary" size="sm" @click="editUser(row.item)">
                  <b-icon icon="pencil"></b-icon> ändern
                </b-button>
              </template>

              <template #cell(enabled)="row">
                <span v-if="row.item.enabled">
                  <b-icon icon="check" style="color: green"></b-icon>
                </span>
                <span v-else>
                  <b-icon icon="x" style="color: red"></b-icon>
                </span>
              </template>

            </b-table>

            <b-row>
              <b-col md="12" class="my-1">
                <b-pagination :total-rows="totalRows"
                              :per-page="perPage"
                              v-model="currentPage"
                              class="my-0" />
              </b-col>
            </b-row>

          </div>

      </div>

    </transition>

    <!-- Model um Benutzer zu ändern oder hinzuzufügen -->
    <b-modal id="modal-edit"
             :title="benutzer && benutzer.id != -1 ? 'Benutzer ändern' : 'Benutzer hinzufügen'"
             size="lg">

      <b-form @submit.prevent="onSubmit" v-if="benutzer">

        <!-- Benutzername = E-Mail -->
        <b-form-group id="input-group-username"
                      label="Benutzername:"
                      label-for="username">

          <b-form-input id="username"
                        v-model="benutzer.username"
                        type="email"
                        maxlength="200"
                        required
                        placeholder="E-Mail-Adresse"
                        :state="usernameValidation"
          />

          <b-form-invalid-feedback :state="usernameValidation">
            Der Benutzername ist ein Pflichtfeld, muss eindeutig und eine E-Mail-Adresse sein.
          </b-form-invalid-feedback>

        </b-form-group>

        <!-- Passwort 1 -->
        <b-form-group id="input-group-pass1"
                      label="Passwort:"
                      label-for="pass1">

          <b-form-input id="pass1"
                        v-model="benutzer.password"
                        type="password"
                        maxlength="200"
                        required
                        :state="passValidation"
          />

          <b-form-invalid-feedback :state="passValidation">
            <span v-if="benutzer.password != password2">
              Die Passwörter stimmen nicht überein.
            </span>
            <span v-else>
              Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen ($@$!%*#?&) enthalten. Das Passwort muss mindestens 8 Zeichen lang sein.
            </span>
          </b-form-invalid-feedback>

        </b-form-group>
        <!-- Passwort 2 -->
        <b-form-group id="input-group-pass2"
                      label="Passwort wiederholen:"
                      label-for="pass2">

          <b-form-input id="pass2"
                        v-model="password2"
                        type="password"
                        maxlength="200"
                        required
                        :state="passValidation"
          />

          <b-form-invalid-feedback :state="passValidation">
            <span v-if="benutzer.password != password2">
              Die Passwörter stimmen nicht überein.
            </span>
            <span v-else>
              Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen ($@$!%*#?&) enthalten. Das Passwort muss mindestens 8 Zeichen lang sein.
            </span>
          </b-form-invalid-feedback>

        </b-form-group>

        <b-form-group id="input-group-name"
                      label="Name:"
                      maxlength="200"
                      label-for="realName">

          <b-form-input id="realName"
                        v-model="benutzer.realName"
                        type="text"
          />

        </b-form-group>

        <b-form-group id="input-group-role"
                      label="Rolle:"
                      label-for="role">

          <b-form-select id="role"
                         v-model="benutzer.role"
                         :options="roles">
          </b-form-select>

        </b-form-group>

        <b-form-group id="input-group-enabled"
                      label=""
                      label-for="checkbox-enabled">
          <b-form-checkbox id="checkbox-enabled"
                           v-model="benutzer.enabled"
                           name="checkbox-enabled"
                           value="true"
                           unchecked-value="false">
            aktiv
          </b-form-checkbox>
        </b-form-group>

      </b-form>

      <template v-slot:modal-footer="{ hide }">

        <b-overlay :show="showOverlay" rounded="sm">
          <b-button class="mr-1" size="sm" variant="warning" @click="hide()">
            abbrechen
          </b-button>
          <b-button variant="success" type="submit" @click="onSubmit" :disabled="!usernameValidation">
            <b-icon icon="check" />&nbsp;speichern
          </b-button>
        </b-overlay>

      </template>

    </b-modal>

  </div>

</template>
<script>

  import CheckLogin from '@/components/login/CheckLogin.vue';
  import axios from 'axios';
  axios.defaults.withCredentials = true;
  import bootbox from 'bootbox';

  export default {

    name: 'FhUser',
    components: {
      CheckLogin,
    },
    data () {
      return {
        showOverlay: false,
        benutzerliste: null,
        fields: [
          {
            key: 'realName',
            label: 'Name',
            sortable: true
          },
          {
            key: 'username',
            label: 'Benutzername',
            sortable: true
          },
          {
            key: 'role',
            label: 'Rolle',
            sortable: true
          },
          {
            key: 'enabled',
            label: 'aktiv',
            sortable: true
          },
          {
            key: 'actions',
            label: '',
            sortable: false,
            class: 'text-right'
          },
        ],
        /** Filter Query String für Suche nach Position */
        keyword: '',
        /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
        pageOptions: [ 5, 10, 15, 20, 50, 100 ],
        /** ausgewählter Wert aus den pageOptions */
        perPage: 20,
        /** Pagination der Ergenistabelle */
        currentPage: 1,
        /** der Benutzer der gerade editiert oder angelegt wird */
        benutzer: null,
        /** mögliche Benutzerrollen */
        roles: [
          { value: 'ROLE_FH', text: 'FH Benutzer' },
          { value: 'ROLE_ADMIN', text: 'Administrator' },
        ],
        password2: null
      }
    },
    mounted: function () {

      this.init();

    },
    computed: {

      /** prüfe oder Benutzername bereits verwendet wird und ob genug Zeichen eingegeben wurden */
      usernameValidation: function () {

        if (this.benutzer && this.benutzer.username && this.benutzer.username.length > 3) {

          const emailRegex =
            new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');
          if (!emailRegex.test(this.benutzer.username)) return false;

          // prüfe ob nicht schon vorhanden
          for (let i = 0; i < this.benutzer.length; i++) {
            if (this.benutzer.username == this.benutzer[i].username) {
              return false;
            }
          }

          return true;

        }
        return false;

      },
      passValidation: function () {

        if (this.password2 != this.benutzer.password) return false;

        return this.checkPasswordRules(this.benutzer.password);

      },
      items: function () {

        var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

        var selectedRows = lowercase
          ? this.benutzerliste.filter( item =>

            ( item.realName && item.realName.toLowerCase().includes(lowercase) ) ||
            ( item.username && item.username.toLowerCase().includes(lowercase) )

          )
          : this.benutzerliste;

        return selectedRows;

      },
      totalRows: function () {
        return this.items.length;
      }

    },
    methods: {

      listStudenten: function () {
        this.$router.push('Studenten');
      },
      /**
       * neuen User oder Änderungen speichern
       */
      onSubmit: async function () {

        const url = process.env.VUE_APP_SERVER_URL + '/fhUser/exists';
        let response = await axios({
          method: 'POST',
          url: url,
          params: {
            username: this.benutzer.username,
            id: this.benutzer.id
          },
          withCredentials: true
        });

        if (response && response.data && response.data.id) {

          bootbox.alert({
            message: 'Es existiert bereits ein Benutzer mit der E-Mail-Adresse ' +
              this.benutzer.username +
              '. (Der Benutzer kann auch ein bereits registrierter Student sein.)',
            backdrop: true,
          });

        } else {

          this.showOverlay = true;
          const url = process.env.VUE_APP_SERVER_URL + '/fhUser/save';
          response = await axios({
            method: 'POST',
            url: url,
            params: this.benutzer,
            withCredentials: true
          });

          console.log('response', response);

          if (response.data.id) {

            if (this.benutzer.id < 0) {
              this.benutzerliste.unshift(response.data);
            } else {
              let newlist = [];
              for (let i = 0; i < this.benutzerliste.length; i++) {
                let check = this.benutzerliste[i];
                if (check.id === this.benutzer.id) {
                  this.benutzerliste[i] = response.data;
                }
                newlist.push(this.benutzerliste[i]);
              }
              this.benutzerliste = newlist;
            }

          }
          this.showOverlay = false;
          this.$bvModal.hide('modal-edit');

        }

      },
      /**
       * öffnet Modal um Benutzer zu ändern
       */
      editUser: function (obj) {

        this.benutzer = obj;
        this.password2 = obj.password;
        // Modal öffnen
        this.$bvModal.show('modal-edit');

      },
      /**
       * öffnet Modal um neuen Benutzer anzulegen
       */
      addUser: function () {

        this.benutzer = {
          id: -1,
          realName: '',
          username: '',
          enabled: true,
          role: 'ROLE_FH'
        }
        this.password2 = '';
        // Modal öffnen
        this.$bvModal.show('modal-edit');

      },
      init: async function () {

        console.log('init fhUser ...');
        // this.showSpinner();

        // hole bestehende Bewerbungen des Users
        const url = process.env.VUE_APP_SERVER_URL + '/fhUser/index';
        const response = await axios({
          method: 'GET',
          url: url,
          withCredentials: true
        });
        this.benutzerliste = response.data;

        console.log('response', response.data);
        this.hideSpinner();

      }

    }

  }
</script>
