<template>

  <div class="selectBundeslandWrapper" v-show="options">

    <select v-model="selected"
            class="custom-select"
            v-bind:class="{ 'is-invalid': !isValid }"
            :disabled="readonly">
      <option
        v-for="option in options"
        v-bind:value="option"
        v-bind:key="option.id">
        {{ option.name }}
      </option>
    </select>
    <div class="invalid-feedback" v-if="invalidMessage">
      {{invalidMessage}}
    </div>

  </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;

  export default {

    name: 'select-bundesland',
    props: {
      bundesland: {
        type: Object,
        required: false
      },
      invalidMessage: {
        type: String,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        /** mögliche Bundesländer */
        options: null,
        /** vom User ausgewähltes Bundesland */
        selected: null
      }
    },
    mounted: function () {

      this.init();

    },
    watch: {

      /** Bundesland wurde von aussen übergeben */
      bundesland: function (val) {
        this.selected = val;
      },
      /** Bundesland wurde vom Benutzer ausgewählt */
      selected: function (val) {
        this.emitLand(val);
      }

    },
    computed: {

      /** prüft ob das Bundesland eingegeben wurde */
      isValid: function () {

        if (!this.selected || this.selected.id == null) {
          return false;
        }
        return true;

      }

    },
    methods: {

      init: function() {

        var that = this;

        // hole alle Länder:
        var url  = process.env.VUE_APP_SERVER_URL + '/bundesland';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        })
        .then(function (response) {

          that.options = response.data;

          // ist was vorbelegt?
          that.checkExisting();

        });

      },
      checkExisting: function() {

        console.log('prüfe existierende ' + this.bundesland);
        if (this.bundesland && this.options) {

          for (var i = 0; i < this.options.length; i++) {
            if (this.options[i].id === this.bundesland.id) {
              this.selected = this.options[i];
              break;
            }
          }

        }

      },
      /** emit Bundesland */
      emitLand: function(val) {

        if (val) this.selected = val;
        this.$emit('set-bundesland', this.selected);

      }

    }

  }

</script>
