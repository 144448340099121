<template>

  <div class="selectOrtWrapper" v-if="messages">

    <v-select
      label="name"
      :options="orte"
      @search="orteUrlEndpoint"
      :filterable="false"
      v-model="selected"
      :placeholder="messages['fh.userDate.searchOrt']"
      class="form-control"
      @input="addOrt">

      <template slot="no-options">
        -- {{messages['fh.userDate.searchOrt.empty']}} --
      </template>

      <template slot="option" slot-scope="option">
            <span class="optionName">
                {{ option.plz }} {{ option.name }}
            </span>
      </template>

      <template slot="selected-option" slot-scope="option">
            <span class="optionName">
                {{ option.plz }} {{ option.name }}
            </span>
      </template>

    </v-select>
    <div class="invalid-feedback" v-if="invalidMessage && !isValid" style="display: block;">
      {{invalidMessage}}
    </div>

  </div>

</template>

<script>

  import axios from 'axios';
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  axios.defaults.withCredentials = true;

  export default {

    name: 'selectOrt',
    components: {
      vSelect
    },
    props: {
      ort: {
        type: Object,
        required: false
      },
      invalidMessage: {
        type: String,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        /** mögliche Orte */
        orte: [],
        /** vom User ausgewählter Ort */
        selected: null,
        messages: null
      }
    },
    mounted: function () {

      this.init();
      if (this.ort) {
        this.selected = this.ort;
      }

    },
    watch: {

      /** Ort wurde von aussen übergeben */
      ort: function (val) {
        this.selected = val;
      },
      /** Ort wurde vom Benutzer ausgewählt */
      selected: function (val) {
        this.emitOrt(val);
      }

    },
    computed: {

      /** prüft ob der Ort ausgewählt wurde */
      isValid: function () {

        if (!this.selected || this.selected.id == null) {
          return false;
        }
        return true;

      }

    },
    methods: {

      /** Orte per AJAX suchen */
      orteUrlEndpoint: function (search) {

        if (search && search.length > 2) {

          var url    = process.env.VUE_APP_SERVER_URL + '/ort/search?order=plz&q=' + search;
          var that   = this;
          var params = {
            bewerbung_id: this.getBewerbung()
          }
          axios.get(url, {
            params: params,
            withCredentials: true
          })
          .then(function (response) {

            that.orte = response.data;

          });

        }

      },
      init: function() {

        this.fetchMessages();
        // Listen for the set-language event on EventBus
        EventBus.$on('set-language', lang => {
          this.fetchMessages(lang);
        });

      },
      addOrt: function(obj) {
        this.selected = obj;
        this.emitOrt(obj);
      },
      /** emit Ort */
      emitOrt: function(val) {

        if (val) this.selected = val;
        this.$emit('set-ort', this.selected);

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        this.sprache = language || localStorage.language;

        var messages = [
          'fh.userDate.searchOrt',
          'fh.userDate.searchOrt.empty'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      }

    }

  }

</script>
