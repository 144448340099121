<!-- Tabellenzeile einer Vertragszustimmung -->
<template>

  <span>

    <h3 v-if="contractsForAgreements && contractsForAgreements.length > 0">{{ studiengang.name }}</h3>

    <b-overlay :show="showOverlay" rounded="sm" style="min-height: 100px">

      <table class="table table-hover mt-3" v-if="messages && contractsForAgreements && contractsForAgreements.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ messages['fh.vertragszustimmung.bezeichnung'] }}</th>
            <th scope="col">{{ messages['fh.vertragszustimmung.bis'] }}</th>
            <th scope="col">{{ messages['fh.vertragszustimmung.zugestimmtAm'] }}</th>
            <th scope="col">{{ messages['fh.vertragszustimmung.oeffnen'] }}</th>
            <th scope="col">{{ messages['fh.vertragszustimmung.zustimmen'] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="contractsForAgreement in contractsForAgreements" :key="contractsForAgreement.id">
            <td>
              {{ contractsForAgreement.contract.name }}
            </td>
            <td>
              <span v-if="contractsForAgreement.deadlineForAgreement">
                {{ formatDateToHtml5Date(contractsForAgreement.deadlineForAgreement) }}
              </span>
            </td>
            <td>
              <span v-if="contractsForAgreement.contractAgreementDate">
                {{ formatDateToHtml5Date(contractsForAgreement.contractAgreementDate) }}
              </span>
            </td>
            <td>
              <button type="button"
                      v-if="contractsForAgreement && contractsForAgreement.contractId"
                      class="button secondary black btn-sm"
                      @click="showContract(contractsForAgreement)">
                <b-icon icon="download" class="mr-2"/> {{ messages['fh.vertragszustimmung.vertragAnsehen'] }}
              </button>
            </td>
            <td>
              <span v-if="isVertragszustimmungPossible(contractsForAgreement)">
                <!-- einfache Zustimmung per Button -->
                <button type="button"
                        v-if="!contractsForAgreement.contractAgreementDate && contractsForAgreement.contractUploadExpected != 'J' && contractsForAgreement.contractId"
                        class="button secondary black btn-sm"
                        @click="zustimmen(contractsForAgreement)">
                  <b-icon icon="check-circle" class="mr-2"/> {{ messages['fh.vertragszustimmung.zustimmen'] }}
                </button>
                <!-- Zustimmung erfordert Upload -->
                <span v-else-if="!contractsForAgreement.contractAgreementDate && contractsForAgreement.contractUploadExpected == 'J'">
                  <file-upload v-on:set-file="setFile"
                               size="sm"
                               :contract-agreements-id="contractsForAgreement.id"
                               :prefix="getPrefix(contractsForAgreement)">
                  </file-upload>
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Dummy Form um File zu holen und anzuzeigen -->
      <form ref="form"
            action=""
            id="form_id"
            target="_blank"
            method="post"
            style="position: fixed; left: -1000px; top: -1000px;">
        <input name="contract_id"  id="contract_id"  value="" />
        <input name="bewerbung_id" id="bewerbung_id" value="" />
      </form>

      <template #overlay v-if="messages">
        <div class="text-center">
          <b-icon icon="hourglass-split" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label" class="mt-2">{{ messages['fh.vertragszustimmung.vertraegeWerdenGeladen'] }}</p>
        </div>
      </template>

    </b-overlay>

  </span>

</template>

<script>

import axios from 'axios';
import { EventBus } from '@/event-bus'
import bootbox from 'bootbox';
axios.defaults.withCredentials = true;
import FileUpload from '../upload/FileUpload';
import moment from 'moment';

export default {

  name: 'table-row-contract',
  props: [
    /** StudentAufnahmeverfahrenDatum bei Student */
    'studentAufnahmeverfahrenDatum',
    /** angestrebterStudiengang bei AO und Incoming */
    'studiengang'
  ],
  components: {
    FileUpload
  },
  data () {
    return {
      messages: null,
      /** Liste mit Verträgen */
      contractsForAgreements: null,
      /** Overlay während des Ladens */
      showOverlay: true
    }
  },
  mounted: function () {

    this.fetchMessages();
    this.init();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** prüft ob eine Vertragszustimmung noch möglich ist oder ob das Datum abgelaufen ist */
    isVertragszustimmungPossible: function (contractsForAgreement) {

      if (!contractsForAgreement.deadlineForAgreement) return true;

      var deadlineMoment = this.javaDateToMoment(contractsForAgreement.deadlineForAgreement);
      // setzte auf Mitternacht dieses Tages:
      deadlineMoment = deadlineMoment.set({"hour": 23, "minute": 59, "second": 59});
      console.log('deadlineMoment = ' + deadlineMoment.format() + ' jetzt = ' + moment().format());
      // wenn heute vor dem Ablaufdatum ist, true zurückliefern:
      return moment().isSameOrBefore(deadlineMoment);

    },
    /** erzeugt ein Prefix für den Dateiupload mit der ID des contracts */
    getPrefix: function (contractsForAgreement) {

      var str = contractsForAgreement.id;
      return str.replace(/null/g, '_') + '_';

    },
    /** eine neue Datei wurde hochgeladen als Bestätigung */
    setFile: function (obj) {

      var contractAgreementsId = obj.contractAgreementsId;
      var file                 = obj.file;

      console.log('neuer File als contract Bestätigung hinzugefügt: ' + file.id);
      if (file.id) {
        // speichere die Zustimmung
        for (var i = 0; i < this.contractsForAgreements.length; i++) {
          if (this.contractsForAgreements[i].id == contractAgreementsId) {
            this.zustimmen(this.contractsForAgreements[i], file.id);
          }
        }
      }

    },
    formatDateToHtml5Date: function (dat) {
      return this.javaDateToHtml5Date(dat);
    },
    /** dem Vertrag wird zugestimmt */
    zustimmen: function (contractsForAgreement, file_id) {

      var that = this;
      var msg = this.messages['fh.vertragszustimmung.zustimmen.confirm'];
      msg = msg.replace('{0}', contractsForAgreement.contract.name);
      var bewerbungId = this.getBewerbung();
      bootbox.confirm(msg, function(result) {

        if (result) {

          that.showSpinner();

          var url = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/save';
          var params = {
            contract_id:                        contractsForAgreement.contractId,
            bewerbung_id:                       bewerbungId,
            student_aufnahmeverfahren_datum_id: that.studentAufnahmeverfahrenDatum ? that.studentAufnahmeverfahrenDatum.id : null,
            file_id:                            file_id,
            studiengang_id:                     that.studiengang ? that.studiengang.id : null
          };

          axios({
            method: 'post',
            url: url,
            params: params,
            withCredentials: true
          })
            .then(function (response) {

              that.hideSpinner();
              if (response.data.contractAgreementDate) {

                contractsForAgreement.contractAgreementDate = response.data.contractAgreementDate

                // prüfe ob alles vom Studenten erfasst wurde und setzte das finalAgreementDate falls ja
                url  = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/isVertragszustimmungValid';
                axios({
                  method: 'post',
                  url: url,
                  params: {
                    bewerbung_id: bewerbungId
                  },
                  withCredentials: true
                })
                  .then(function (response) {

                    if (response.data && response.data.valid) {

                      const txt = that.messages['fh.vertragszustimmung.isComplete'];
                      bootbox.alert({
                        message: txt,
                        backdrop: true,
                        callback: function () {

                          EventBus.$emit('is-vertragszustimmung-valid', response.data.valid);

                        }
                      });
                    }

                  });

              }

            });

        }

      });

    },
    /** einen Vertrag runterladen aus der Oracle und anzeigen */
    showContract: function (contractsForAgreement) {

      console.log('showContract', contractsForAgreement)

      if (contractsForAgreement && contractsForAgreement.contractId) {

        const url = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/getContract'
        document.getElementById('form_id').action      = url
        document.getElementById('contract_id').value   = contractsForAgreement.contractId
        document.getElementById('bewerbung_id').value  = this.getBewerbung()
        document.getElementById('form_id').submit()

      } else {
        console.error('contractsForAgreement.contractId ist null, Contract kann nicht angezeigt werden!')
      }

    },
    init: function () {

      var that = this;

      // hole die Verträge aus der Oracle:
      var url  = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/getContractsForAgreement';
      var params = {
        bewerbung_id:                       this.getBewerbung(),
        student_aufnahmeverfahren_datum_id: this.studentAufnahmeverfahrenDatum ? this.studentAufnahmeverfahrenDatum.id : null,
        studiengang_id:                     this.studiengang ? this.studiengang.id : null
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          if (!response.data.error) {
            that.contractsForAgreements = response.data;
          }
          that.showOverlay = false;

        });

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.vertragszustimmung.bezeichnung',
        'fh.vertragszustimmung.zugestimmtAm',
        'fh.vertragszustimmung.oeffnen',
        'fh.vertragszustimmung.zustimmen',
        'fh.vertragszustimmung.vertragAnsehen',
        'fh.vertragszustimmung.zustimmen.confirm',
        'fh.vertragszustimmung.bis',
        'fh.vertragszustimmung.vertraegeWerdenGeladen',
        'fh.vertragszustimmung.isComplete'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
      });

    }

  }

}

</script>
