<template>

  <div class="selectStudienjahrSemesterWrapper" v-if="semester1Label && semester2Label">

    <div class="row">
      <div class="col">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="semester1" v-model="semesterCheckbox1" :disabled="readonly">
          <label class="custom-control-label" for="semester1">{{semester1Label}}</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="semester2" v-model="semesterCheckbox2" :disabled="readonly">
          <label class="custom-control-label" for="semester2">{{semester2Label}}</label>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';

  export default {

    name: 'select-studienjahr-semester',
    props: {
      studienjahr: {
        type: Number,
        required: false
      },
      semester: {
        type: String,
        required: false
      },
      studienjahr2: {
        type: Number,
        required: false
      },
      semester2: {
        type: String,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        /** vom User ausgewähltes Studienjahr */
        selected: null,
        messages: null,
        selectedSemester: null,
        semesterCheckbox1: false,
        semesterCheckbox2: false,
        semester1Label: null,
        semester2Label: null
      }
    },
    mounted: function () {

      this.init();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
          this.fetchMessages(lang);
      });

    },
    watch: {

      /** Semester wurde von aussen übergeben */
      semester: function () {
        this.checkSemesterStrings();
      },
      /** Studiengang wurde von aussen übergeben */
      studienjahr: function () {
        this.checkSemesterStrings();
      },
      /** Semester2 wurde von aussen übergeben */
      semester2: function () {
        this.checkSemesterStrings();
      },
      /** Studiengang2 wurde von aussen übergeben */
      studienjahr2: function () {
        this.checkSemesterStrings();
      },
      /** Semester1 wurde ausgewählt */
      semesterCheckbox1: function() {
        this.emit();
      },
      /** Semester2 wurde ausgewählt */
      semesterCheckbox2: function() {
        this.emit();
      }

    },
    methods: {

      init: function() {

         this.fetchMessages();

      },
      /**
       * schreibt die labels der Semester Auswahl
       * Achtung messages müssen dazu gesetzt sein
       * */
      setSemesterLabels: function() {

        var now = new Date();
        var year = now.getFullYear();

        var ersterFuenfter = new Date(year,  5, 1, 0, 0, 0, 0);
        var ersterElfter   = new Date(year, 11, 1, 0, 0, 0, 0);

        if (ersterFuenfter.getTime() > now.getTime()) {
          // vor 1.5.YYYY "WS. YYYY"+SS."YYYY+1" anbieten;
          this.semester1Label = this.messages['fh.userData.incomings.studienjahrSemester.WS'] + ' ' + year;
          this.semester2Label = this.messages['fh.userData.incomings.studienjahrSemester.SS'] + ' ' + (year + 1);
        } else if (ersterElfter.getTime() > now.getTime()) {
          // vor 1.11.YYYY "SS YYYY+1"+"WS. YYYY+1" anbieten.
          this.semester1Label = this.messages['fh.userData.incomings.studienjahrSemester.SS'] + ' ' + (year + 1);
          this.semester2Label = this.messages['fh.userData.incomings.studienjahrSemester.WS'] + ' ' + (year + 1);
        } else {
          // nach dem 1.11. wieder nächstes Jahr und Semester
          this.semester1Label = this.messages['fh.userData.incomings.studienjahrSemester.WS'] + ' ' + (year + 1);
          this.semester2Label = this.messages['fh.userData.incomings.studienjahrSemester.SS'] + ' ' + (year + 2);
        }

        this.checkSemesterStrings();

      },
      /** prüft ob ein Semester vorausgewählt wurde */
      checkSemesterStrings: function() {

        if (this.semester && this.studienjahr) {

          var semester = this.semester === 'SS' ? this.messages['fh.userData.incomings.studienjahrSemester.SS'] : this.messages['fh.userData.incomings.studienjahrSemester.WS'];

          var check = semester + ' ' + this.studienjahr;
          if (check === this.semester1Label) this.semesterCheckbox1 = true;
          if (check === this.semester2Label) this.semesterCheckbox2 = true;

        }
        if (this.studienjahr2 && this.semester2) {

          var semester = this.semester2 === 'SS' ? this.messages['fh.userData.incomings.studienjahrSemester.SS'] : this.messages['fh.userData.incomings.studienjahrSemester.WS'];

          var check = semester + ' ' + this.studienjahr2;
          if (check === this.semester1Label) this.semesterCheckbox1 = true;
          if (check === this.semester2Label) this.semesterCheckbox2 = true;

        }

      },
      /** emit Studiengang */
      emit: function() {

        var semester1    = null;
        var studienjahr1 = null;
        var semester2    = null;
        var studienjahr2 = null;
        if (this.semesterCheckbox1) {

          console.log('this.semester1Label = ' + this.semester1Label);
          var splitted = this.semester1Label.split(' ');
          studienjahr1 = Number(splitted[splitted.length - 1]);

          var text     = this.semester1Label.replace(' ' + splitted[splitted.length - 1], '');
          semester1    = (text === this.messages['fh.userData.incomings.studienjahrSemester.SS']) ? 'SS' : 'WS';

        }
        if (this.semesterCheckbox2) {

          console.log('this.semester2Label = ' + this.semester2Label);
          var splitted = this.semester2Label.split(' ');
          studienjahr2 = Number(splitted[splitted.length - 1]);

          var text     = this.semester2Label.replace(' ' + splitted[splitted.length - 1], '');
          semester2    = (text === this.messages['fh.userData.incomings.studienjahrSemester.SS']) ? 'SS' : 'WS';

        }

        var obj = null;
        if (semester1 || semester2) {
          obj = {
            semester1:    semester1,
            studienjahr1: studienjahr1,
            semester2:    semester2,
            studienjahr2: studienjahr2
          };
        }
        this.$emit('set-studienjahr-semester', obj);

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

          var messages = [
            'fh.userData.incomings.studienjahrSemester.SS',
            'fh.userData.incomings.studienjahrSemester.WS'
          ];
          var that = this;
          this.getMessages(language, messages, function(resp) {
              that.messages = resp;
              that.setSemesterLabels();
          });

      }

    }

  }

</script>
