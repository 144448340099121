<template>

  <div v-if="messages">

    <div v-if="readonly">
      <input type="text" disabled="disabled" class="form-control" :value="messages['fh.studiengang.bewerbergruppe.' + selected]" />
    </div>

    <div class="list-group">

      <a href="#"
         v-if="!readonly || (user && user.bewerbergruppe === 'STUDENT')"
         class="list-group-item list-group-item-action flex-column align-items-start"
         :class="{active : (selected == 'STUDENT')}"
         v-on:click="setBewerbergruppe('STUDENT')">
        <div class="d-flex w-100 justify-content-between">
          <strong class="mb-1">
            <div class="custom-control custom-radio">
              <input type="radio"
                     id="radioSTUDENT"
                     name="bewerbergruppe"
                     class="custom-control-input"
                     value="STUDENT"
                     v-model="selected">
              <label class="custom-control-label" for="radioSTUDENT">{{messages['fh.studiengang.bewerbergruppe.STUDENT']}}</label>
            </div>
          </strong>
        </div>
        <p class="mb-1">{{messages['fh.studiengang.bewerbergruppe.STUDENT.description']}}</p>
      </a>

      <a href="#"
         v-if="!readonly || (user && user.bewerbergruppe === 'AUSSERORDENTLICHER_HOERER')"
         class="list-group-item list-group-item-action flex-column align-items-start"
         :class="{active : (selected == 'AUSSERORDENTLICHER_HOERER')}"
         v-on:click="setBewerbergruppe('AUSSERORDENTLICHER_HOERER')">
        <div class="d-flex w-100 justify-content-between">
          <strong class="mb-1">
            <div class="custom-control custom-radio">
              <input type="radio"
                     id="radioAUSSERORDENTLICHER_HOERER"
                     name="bewerbergruppe"
                     class="custom-control-input"
                     value="AUSSERORDENTLICHER_HOERER"
                     v-model="selected">
              <label class="custom-control-label" for="radioAUSSERORDENTLICHER_HOERER">{{messages['fh.studiengang.bewerbergruppe.AUSSERORDENTLICHER_HOERER']}}</label>
            </div>
          </strong>
        </div>
        <p class="mb-1">{{messages['fh.studiengang.bewerbergruppe.AUSSERORDENTLICHER_HOERER.description']}}</p>
      </a>

      <a href="#"
         v-if="!readonly || (user && user.bewerbergruppe === 'INCOMING')"
         class="list-group-item list-group-item-action flex-column align-items-start"
         :class="{active : (selected == 'INCOMING')}"
         v-on:click="setBewerbergruppe('INCOMING')">
        <div class="d-flex w-100 justify-content-between">
          <strong class="mb-1">
            <div class="custom-control custom-radio">
              <input type="radio"
                     id="radioINCOMING"
                     name="bewerbergruppe"
                     class="custom-control-input"
                     value="INCOMING"
                     v-model="selected">
              <label class="custom-control-label" for="radioINCOMING">{{messages['fh.studiengang.bewerbergruppe.INCOMING']}}</label>
            </div>
          </strong>
        </div>
        <p class="mb-1">{{messages['fh.studiengang.bewerbergruppe.INCOMING.description']}}</p>
      </a>

    </div>

  </div>

</template>

<script>

  import axios from 'axios';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  axios.defaults.withCredentials = true;

  export default {

    name: 'select-bewerbergruppe-list-group',
    props: {
      readonly: null
    },
    data: function () {
      return {
        messages: null,
        /** ausgewählte Bewerbergruppe */
        selected: null,
        user: null
      }
    },
    mounted: function () {

      this.fetchMessages();
      this.getStudent();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    watch: {

      selected: function(newVal, oldVal) {
        console.log('Bewerbergruppe ausgewählt newVal = ' + newVal + ' oldVal = ' + oldVal);
        if (newVal !== oldVal) {
          this.emitBewerbergruppe(newVal);
        }
      }

    },
    methods: {

      /** Beschreibung der Bewerbergruppe anzeigen */
      showPopOver: function(element) {
        console.log('show PopOver: ' + element);
        $('#' + element).popover('show');
        // var that = this;
        // setTimeout(function () { that.hideAllPopOvers() }.bind(this), 10000);
        setTimeout(function () {  $('#' + element).popover('hide'); }, 5000);
      },
      /** Beschreibung der Bewerbergruppe ausblenden */
      hidePopOver: function(element) {
        $('#' + element).popover('hide')
      },
      hideAllPopOvers: function() {
        $('#STUDENT').popover('hide');
        $('#AUSSERORDENTLICHER_HOERER').popover('hide');
        $('#INCOMING').popover('hide');
      },
      /** Bewerbergruppe wurde ausgewählt */
      setBewerbergruppe: function(val) {
        this.selected = val;
        this.hideAllPopOvers();
      },
      /** holt den eingeloggten Studenten */
      getStudent: function() {

        var that = this;
        var url  = process.env.VUE_APP_SERVER_URL + '/student';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        })
          .then(function (response) {

            console.log('Studenten/User Daten: ' + response.data);
            if (response.data) {
              that.user = response.data.user;
              if (response.data.bewerbergruppe) {
                that.selected = response.data.bewerbergruppe;
              }
            }

          });

      },
      /** Bewerbergruppe emmiten */
      emitBewerbergruppe: function (val) {

        if (!val) {
          val = this.selected;
        }

        console.log('emit Bewerberbergruppe ' + val);
        if (val && this.user && this.user.id) {

          // Speichere bei Studenten
          var url  = process.env.VUE_APP_SERVER_URL + '/student/saveBewerbergruppe';
          var that = this;
          var params = {
            id: this.user.id,
            bewerbergruppe: this.selected,
            bewerbung_id: this.getBewerbung()
          };
          axios({
            method: 'post',
            url: url,
            params: params,
            withCredentials: true
          })
            .then(function (response) {

              if (response.data.id) {
                console.log('Bewerbergruppe erfolgreich gespeichert.');
                that.setBewerbung(response.data.id);
                sessionStorage.bewerbergruppe = val;
                that.$emit('set-bewerbergruppe', val);
                EventBus.$emit('set-bewerbergruppe', val);
              } else {
                console.log('Fehler beim Speichern der Bewerbergruppe.');
              }

            });

        }

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        var messages = [
          'fh.studiengang.bewerbergruppe.ariaLabel',
          'fh.studiengang.bewerbergruppe.STUDENT',
          'fh.studiengang.bewerbergruppe.AUSSERORDENTLICHER_HOERER',
          'fh.studiengang.bewerbergruppe.INCOMING',
          'fh.studiengang.bewerbergruppe.STUDENT.description',
          'fh.studiengang.bewerbergruppe.AUSSERORDENTLICHER_HOERER.description',
          'fh.studiengang.bewerbergruppe.INCOMING.description'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      }

    }

  }
</script>
