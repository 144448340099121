<template>

  <div v-if="studiengang && sprache">

    <span v-if="sprache === 'de'">
      {{ studiengang.labelDe }} ({{ studiengang.kuerzel }})
    </span>
    <span v-else>
      {{ studiengang.labelEn }} ({{ studiengang.kuerzel }})
    </span>

  </div>

</template>

<script>

  import axios from 'axios';
  import { EventBus } from '../../event-bus'
  axios.defaults.withCredentials = true;

  export default {

    name: 'studiengang-name',
    props: {
      admissionProcessId: null
    },
    data: function () {
      return {
        studiengang: null,
        sprache: null
      }
    },
    mounted: function () {

      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.init(lang);
      });
      this.init();

    },
    watch: {

      admissionProcessId: function () {
        this.init();
      }

    },
    methods: {

      /** holt den Studiengang */
      init: function (lang) {

        this.sprache = lang ? lang : localStorage.language;

        if (this.admissionProcessId) {
          var that = this;
          var url  = process.env.VUE_APP_SERVER_URL + '/studiengang/getStudiengang';
          var params = {
            admissionProcessId: this.admissionProcessId
          }
          axios.get(url, {
            params: params,
            withCredentials: true
          })
            .then(function (response) {

              if (response.data && !response.data.error) {
                that.studiengang = response.data;
              } else {
                console.log('Fehler beim Abrufen des Studiengangs ' + response.data.error);
              }

            });

        }
      }

    }

  }
</script>
