<template>

  <div class="selectLanguageWrapper">

    <span class="cursor-pointer mr-2"
          v-for="option in options"
          v-bind:key="option.value"
          v-bind:class="{ 'strong': (selected && selected.value == option.value) }"
          v-on:click="selected = option">{{ option.text }}</span>

  </div>

</template>

<script>

import axios from 'axios';
// Import the EventBus
import { EventBus } from '../../event-bus.js';
axios.defaults.withCredentials = true;

export default {

  name: 'Language',
  data () {
    return {

      messages: null,
      selected: null,
      options: [
        { text: 'DE', value: 'de' },
        { text: 'EN', value: 'en' }
      ]

    }
  },
  mounted: function () {

    var langtocheck = 'en';
    if (localStorage.language) {

      langtocheck = localStorage.language;

    } else {

      // Hole Sprache des Browsers:
      var userLang = navigator.language || navigator.userLanguage;
      if (userLang.startsWith('de')) {
        langtocheck = 'de';
      }

    }

    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].value === langtocheck) {
        this.selected = this.options[i];
        break;
      }
    }

  },
  watch: {

    selected: function (val, oldVal) {

      if (val && oldVal) console.log('ändere Sprache von ' + oldVal.value + ' auf ' + val.value);

      if (this.selected) {

        var url    = process.env.VUE_APP_SERVER_URL + '/main/changeLanguage';
        var params = {
          lang: this.selected.value,
          bewerbung_id: this.getBewerbung()
        };

        var that = this;
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true
        }).then( () => {

            localStorage.language = that.selected.value;
            EventBus.$emit('set-language', that.selected.value);

        });

      }

    }

  }

}

</script>
