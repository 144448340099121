<template>

  <div id="footerDatenschutzWrapper" v-if="messages">

    <footer id="footer">

      <div class="footer-bottom-bar footer">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-sm">
              Fachhochschule Salzburg GmbH © {{ year }}
            </div>
            <div class="col-sm text-center utilo" v-if="version">
              <a href="https://utilo.eu/"
                 title="UTILO | DI Christian Osterrieder-Schlick | Web- and App Development"
                 target="_blank">FH Bewerbungsportal Version {{ env }} {{ version }} | developed by UTILO</a>
            </div>
            <div class="col-sm">
              <ul class="footer-bottom-navigation">
                <li>
                  <a href="#" data-toggle="modal" data-target=".bd-datenschutz-modal-lg">{{ messages['fh.footer.datenschutz'] }}</a>
                </li>
                <li>
                  <a href="https://www.fh-salzburg.ac.at/impressum/">{{ messages['fh.footer.impressum'] }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </footer>

    <div class="modal fade bd-datenschutz-modal-lg" tabindex="-1" role="dialog" aria-labelledby="datenschutzModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="closeme" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <datenschutz-text></datenschutz-text>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ messages['default.button.close.label'] }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>

  import DatenschutzText from '../datenschutz/DatenschutzText';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';

  export default {

    name: 'FhFooter',
    components: {
      DatenschutzText
    },
    data () {
      return {
        messages: null,
        version: null,
        env: null,
        year: 2023
      }
    },
    mounted: function () {

      let date  = new Date();
      this.year = date.getFullYear();
      this.fetchMessages();
      this.fetchVersion();
      this.fetchEnv();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    methods: {

      /** holt App Version */
      fetchVersion: function () {

        var that = this;
        this.getVersion(function(resp) {
          that.version = resp;
        });

      },
      fetchEnv: function () {
          var that = this;
          this.getEnvironment(function(resp) {
              that.env = resp;
          });
      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        var messages = [
          'fh.footer.impressum',
          'fh.footer.datenschutz',
          'default.button.close.label'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      }

    }

  }

</script>
