<template>

  <span class="kautionBefreienWrapper">

    <b-button variant="outline-warning"
                  size="sm"
                  v-if="status == 'OFFEN' || status == 'ABGELEHNT' || status == 'IN_PRUEFUNG'"
                  v-b-tooltip.hover.right
                  @click="confirmIt"
                  title="Studierende*n von Kaution befreien.">
      <span class="strikediag">&nbsp;<b-icon icon="cash-coin"></b-icon>&nbsp;</span>
    </b-button>

  </span>

</template>

<script>

  import axios from 'axios';
  import bootbox from "bootbox";
  axios.defaults.withCredentials = true;

  export default {

    name: 'kaution-befreien',
    props: ['value'], // value = Student
    data () {
      return {
        // Status der Kautionszahlung
        status: null,
        /** Bewerbung auf die sich die Befreiung bezieht */
        bewerbung_id: null
      }
    },
    watch: {

      value: function () {
        this.init()
      }

    },
    mounted: function () {
      this.init()
    },
    methods: {

      confirmIt: function () {

        const that = this
        const msg = 'Sind Sie sicher, dass Sie den Studenten ' + this.value.nachname + ' von der Kaution befreien wollen?'
        bootbox.confirm(msg, result => {

          if (result) {

            that.showSpinner()

            var url = process.env.VUE_APP_SERVER_URL + '/kaution/doKautionBefreiung'
            var params = {
              user_id: this.value.id,
              bewerbung_id: this.bewerbung_id,
            };

            axios({
              method: 'POST',
              url: url,
              params: params,
              withCredentials: true
            }).then( response => {

              that.hideSpinner()
              if (response.data && response.data.id) {
                that.$emit('kaution-befreit', 'KAUTION_BEFREIT')
              }

            })

          }

        })

      },
      init: function () {

        const that = this;
        const url = process.env.VUE_APP_SERVER_URL + '/kaution/isKautionBezahlt';
        const params = {
          user_id: this.value.id
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then( response => {

          that.status = response.data.isKautionBezahlt
          that.bewerbung_id = response.data.bewerbung_id

        })

      },

    }

  }

</script>
