<template>

  <form @submit="checkForm" name="uploadMedienForm" id="uploadMedienForm">

    <transition name="fade" mode="out-in">

      <div v-if="messages">

        <check-login></check-login>
        <nav-tabs :active="'UploadMedien'"></nav-tabs>

        <h2 v-html="messages['fh.fileUpload.medienUpload.headline']"></h2>

        <hr class="mt-5 mb-5">

        <transition name="fade" mode="out-in">

          <div class="table-responsive mt-3" v-if="mediafiles && mediafiles.length > 0">
            <table class="table table-sm">
              <thead>
              <tr>
                <th scope="col">{{ messages['fh.fileUpload.medienUpload.datum'] }}</th>
                <th scope="col">{{ messages['fh.fileUpload.medienUpload.fachbereich'] }}</th>
                <th scope="col">{{ messages['fh.fileUpload.studiengang'] }}</th>
                <th scope="col">{{ messages['fh.fileUpload.filename'] }}</th>
                <th scope="col"><!-- falls durch FH gelöscht --></th>
                <th scope="col"><!-- löschen --></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(file, index) in mediafiles" v-bind:key="file.id">
                <td>{{ javaDateToTime(file.dateCreated) }}</td>
                <td>{{ messages['fh.fileUpload.medienUpload.fachbereich.' + file.bezeichnung] }}</td>
                <td>
                  <span v-if="lang == 'de' && file.studiengang">
                    {{ file.studiengang.labelDe }}
                  </span>
                  <span v-else-if="file.studiengang">
                    {{ file.studiengang.labelEn }}
                  </span>
                </td>
                <td>{{ file.originalFilename }}</td>
                <td>
                  <span v-if="file.deletedByJob">
                    {{ printDeletedByJob(file.deletedByJob) }}
                  </span>
                </td>
                <td class="text-right">
                  <button type="button"
                          v-if="!file.deletedByJob"
                          class="btn btn-warning btn-sm"
                          v-on:click="deleteFile(file, index)">{{ messages['fh.fileUpload.remove'] }}</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </transition>

        <hr class="mt-5 mb-5" v-if="mediafiles && mediafiles.length > 0" />

        <transition name="fade" mode="out-in">
          <div v-if="((aufnahmeverfahren && aufnahmeverfahren.length > 0) || (studiengaenge && studiengaenge.length > 0)) && mediafiles != null">

            <div class="row" v-for="item in aufnahmeverfahren" :key="item.id">
              <div class="col">

                <!-- Medien Upload für MMA -->
                <medien-upload
                  v-if="!uploadForStudiengangExists(item.studiengang)"
                  :studiengang="item.studiengang"
                  :aufnahmeverfahren="item"
                  v-on:set-file="setFile"
                />

              </div>
            </div>
            <div class="row" v-for="item in studiengaenge" :key="item.id">
              <div class="col">

                <!-- Medien Upload für MMA für AO Hörer und Incoming -->
                <medien-upload
                  v-if="!uploadForStudiengangExists(item)"
                  :studiengang="item"
                  v-on:set-file="setFile"
                />

              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-10 offset-col-md-2">
                <button type="button"
                        v-on:click="next()"
                        v-if="abgeschlossen"
                        class="button primary">{{ messages['default.button.next.label'] }}</button>
                <button type="submit"
                        v-else
                        class="button primary"
                        :disabled="!formValid">{{ messages['default.button.forward.label'] }}</button>
              </div>
            </div>

          </div>
        </transition>
        <div v-if="(!aufnahmeverfahren || aufnahmeverfahren.length < 1) && (!studiengaenge || studiengaenge.length < 1)">
          <!-- Fehlermeldung ausgeben falls noch keine Studiengänge gewählt wurden -->
          <error-message :errormessage="messages['fh.studiengang.select']"></error-message>
        </div>

      </div>

    </transition>

  </form>

</template>

<script>

import axios from 'axios';
import NavTabs from './layout/NavTabs.vue';
import bootbox from 'bootbox';
import CheckLogin from './login/CheckLogin.vue';
import ErrorMessage from './layout/ErrorMessage.vue';
import MedienUpload from '@/components/upload/MedienUpload';
// Import the EventBus.
import { EventBus } from '../event-bus.js';
axios.defaults.withCredentials = true;

export default {

  name: 'UploadMedien',
  data () {
    return {
      messages: null,
      files: [],
      /** MMA Aufnahmeverfahren des Bewerbers */
      aufnahmeverfahren: null,
      lang: null,
      /** bereits hochgeladene Medien Files der Bewerbung */
      mediafiles: null,
      abgeschlossen: null,
      bewerbergruppe: null,
      /** studiengang bei Incoming und AO Hörer = MMA */
      studiengaenge: null
    }
  },
  mounted: function () {

    this.init();

  },
  components: {
    NavTabs,
    CheckLogin,
    ErrorMessage,
    MedienUpload
  },
  watch: {
    files: function() {
      // checkIfAllRequiredFilesAvailable muss ausgelöst werden
      this.$forceUpdate();
    }
  },
  computed: {

    /**
     * prüfe ob die Formulardaten korrekt erfasst wurden
     */
    formValid: function () {

      if (this.mediafiles && this.mediafiles.length > 0) {
        return true;
      }

      // immer true zurückgeben, da diese Page keine Pflichteingabe ist
      return true;

    }

  },
  methods: {

    /**
     * prüft ob für den Studiengang schon ein Medienfile hochgeladen wurde
     */
    uploadForStudiengangExists: function (studiengang) {

      console.log('uploadForStudiengangExists' + studiengang.id);
      if (!this.mediafiles || this.mediafiles.length < 1) return false;
      for (let i = 0; i < this.mediafiles.length; i++) {
        // auch darauf achten ob gelöscht!
        if (studiengang.id == this.mediafiles[i].studiengang.id && !this.mediafiles[i].deletedByJob) return true;
      }
      return false;

    },
    /** einen File wieder löschen */
    deleteFile: function (file, index) {

      let that = this;
      let msg = this.messages['fh.fileUpload.remove.sure'];
      msg = msg.replace('{0}', file.originalFilename);
      const bewerbungId = this.getBewerbung();
      bootbox.confirm(msg, function (result) {

        if (result) {

          that.showSpinner();

          var url = process.env.VUE_APP_SERVER_URL + '/file/deleteMediaFile';
          var params = {
            file_id: file.id,
            bewerbung_id: bewerbungId
          };

          axios({
            method: 'POST',
            url: url,
            params: params,
            withCredentials: true
          }).then( () => {

              that.hideSpinner();
              that.mediafiles.splice(index, 1);

          });

        }

      });

    },
    /**
     * zeigt dem Benutzer an, dass der File aus datenschutzerrechtlichen Gründen gelöscht wurde
     */
    printDeletedByJob: function (javadate) {

      let txt = this.messages['fh.fileUpload.fileDeletedByJob'];
      txt = txt.replace('{0}', this.javaDateToHtml5Date(javadate));
      return txt;

    },
    /** holt alle Medien Files der Bewerbung */
    findAllMediaFiles: function () {

      let that = this;
      this.mediafiles = null;
      const url    = process.env.VUE_APP_SERVER_URL + '/file/findAllMediaFiles';
      const params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then( response => {

          if (response.data.error) {
            console.log('Fehler beim Lesen der Files!');
          } else {
            that.mediafiles = response.data;
          }

      });

    },
    /** eine neue Datei wurde hochgeladen, zur Liste der Files hinzufügen */
    setFile: function (obj) {

      console.log('neuer File hinzugefügt: ' + obj);
      this.findAllMediaFiles();

    },
    init: function () {

      let that = this;
      this.getBewerbergruppe(function (result) {

        that.bewerbergruppe = result;

        console.log('init uploadMedien', that.bewerbergruppe);

        that.fetchMessages();
        // Listen for the set-language event on EventBus
        that.lang = localStorage.language;
        // Listen for the set-language event on EventBus
        EventBus.$on('set-language', lang => {
          that.lang = lang;
          that.fetchMessages(lang);
        });

        // hole Studiengänge des Bewerbers:
        that.aufnahmeverfahren = null;
        that.studiengaenge     = null;
        if (that.bewerbergruppe === 'STUDENT') {

          const url = process.env.VUE_APP_SERVER_URL + '/studiengang/findAufnahmeverfahrenOfStudent';
          const params = {
            bewerbung_id: that.getBewerbung(),
            isMMA: true
          }
          axios.get(url, {
            params: params,
            withCredentials: true
          }).then(response => {

            if (response.data.error) {
              console.log('Fehler beim Lesen der Aufnahmeverfahren für MMA!');
            } else {
              that.aufnahmeverfahren = response.data;
            }

          });

        } else {

          // AO Hörer und Incoming haben keine Aufnahmeverfahren!
          // hole den Studiengang:
          const url = process.env.VUE_APP_SERVER_URL + '/studiengang/findStudiengaengeOfStudent';
          const params = {
            bewerbung_id: that.getBewerbung(),
            isMMA: true
          }
          axios.get(url, {
            params: params,
            withCredentials: true
          }).then(response => {

            if (response.data.error) {
              console.log('Fehler beim Lesen der Studiengänge für MMA!');
            } else {
              that.studiengaenge = response.data;
            }

          });

        }

        // prüfe ob Bewerbung bereits abgeschickt wurde
        that.isAbgeschlossen( res => {
          that.abgeschlossen = res;
        });

        // hole bestehende Files des Studenten
        const url    = process.env.VUE_APP_SERVER_URL + '/file/findAllMediaFiles';
        const params = {
          bewerbung_id: that.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then(function (response) {

          that.mediafiles = response.data;

        });

      });

    },
    /** Formulardaten prüfen und Form absenden */
    checkForm: function (e) {

      e.preventDefault();
      this.next();

    },
    next: function () {

      // für Studenten weiter zur Umfrage
      if (this.bewerbergruppe === 'STUDENT') {
        this.$router.push('Umfrage');
        return
      }

      // weiter zum Datenschutz für AO und Incoming
      this.$router.push('Datenschutz');

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      var messages = [
        'default.button.forward.label',
        'fh.studiengang.select',
        'fh.fileUpload.newFilesUploaded',
        'default.button.next.label',
        'fh.fileUpload.medienUpload.headline',
        'fh.fileUpload.filename',
        'fh.fileUpload.medienUpload.datum',
        'fh.fileUpload.fileDeletedByJob',
        'fh.fileUpload.remove',
        'fh.fileUpload.remove.sure',
        'fh.fileUpload.medienUpload.fachbereich',
        'fh.fileUpload.medienUpload.fachbereich.AU',
        'fh.fileUpload.medienUpload.fachbereich.FI',
        'fh.fileUpload.medienUpload.fachbereich.KD',
        'fh.fileUpload.medienUpload.fachbereich.CA',
        'fh.fileUpload.medienUpload.fachbereich.CD',
        'fh.fileUpload.medienUpload.fachbereich.PD',
        'fh.fileUpload.studiengang'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        let title = that.messages['fh.fileUpload.medienUpload.headline'];
        title = title.replaceAll('&shy;', '');
        document.title = title;
      });

    }

  }

}
</script>
