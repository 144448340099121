<!-- Schulbildung für einen AO Hörer wählen -->
<template>

  <transition name="fade" mode="out-in">
    <div class="schulbildungsrow" v-if="messages">

      <select-land-and-ort :land="land"
                           :land-error-message="messages['fh.reife.schulbildung.staat.insert']"
                           :land-label="messages['fh.reife.schulbildung.staat']"
                           :readonly="readonly"
                           :ort="schulbildungOrt"
                           :ort-label="messages['fh.reife.schulbildung.ort']"
                           :ort-error-message="messages['fh.reife.schulbildung.ort.insert']"
                           :ortobj="ortobj"
                           v-on:set-land-and-ort="setLandAndOrt">
      </select-land-and-ort>

      <transition name="fade" mode="out-in">
        <div v-if="land">
          <div class="form-group row">

            <div class="col-md-2">
              <label for="schulbildung-von" class="col-form-label">{{ messages['fh.reife.schulbildung.von'] }}</label>
            </div>
            <div class="col-md-10">
              <utilo-date-picker
                :datum="schulbildungVon"
                v-on:input="setSchulbildungVon"
                id="schulbildung-von"
                class="form-control"
                v-if="sprache && !readonly"
                v-bind:class="{ 'is-invalid': !isSchulbildungVonValid }"
                name="schulbildung-von">
              </utilo-date-picker>
              <readonly-date-input v-else :date="schulbildungVon"></readonly-date-input>
              <div class="invalid-feedback date-picker">
                {{ messages['fh.reife.schulbildung.von.insert'] }}
              </div>
            </div>

          </div>
          <div class="form-group row">

            <div class="col-md-2">
              <label for="schulbildung-bis" class="col-form-label">{{messages['fh.reife.schulbildung.bis']}}</label>
            </div>
            <div class="col-md-10">
              <utilo-date-picker
                :datum="schulbildungBis"
                id="schulbildung-bis"
                class="form-control"
                v-on:input="setSchulbildungBis"
                v-if="sprache && !readonly"
                v-bind:class="{ 'is-invalid': !isSchulbildungBisValid }"
                name="schulbildung-bis">
              </utilo-date-picker>
              <readonly-date-input v-else :date="schulbildungBis"></readonly-date-input>
              <div class="invalid-feedback date-picker">
                {{messages['fh.reife.schulbildung.bis.insert']}}
              </div>
            </div>

          </div>
          <div class="form-group row">

            <div class="col-md-2">
              <label for="schulbildung-schule" class="col-form-label">{{ messages['fh.reife.schulbildung.schule'] }}</label>
            </div>
            <div class="col-md-10">
              <!-- Clustering Zugangsvoraussetzungen -->
              <select-vorbildung-tree :invalid-message="messages['fh.reife.schulbildung.schule.insert']"
                                      :vorbildung="schulbildungSchule"
                                      id="schulbildung-schule"
                                      v-on:set-vorbildung="setSchulbildungSchule"
                                      :staat="land"
                                      :readonly="readonly"
              />
            </div>

          </div>

          <div class="form-group row">

            <div class="col-md-2">
              <label for="schulbildungAbschluss" class="col-form-label">{{messages['fh.reife.schulbildung.abschluss']}}</label>
            </div>
            <div class="col-md-10">
              <utilo-date-picker
                :datum="schulbildungAbschluss"
                id="schulbildungAbschluss"
                class="form-control"
                v-on:input="setSchulbildungAbschluss"
                v-if="sprache && !readonly"
                v-bind:class="{ 'is-invalid': !isAbschlussValid }"
                name="schulbildungAbschluss">
              </utilo-date-picker>
              <readonly-date-input v-else :date="schulbildungAbschluss"></readonly-date-input>
              <div class="invalid-feedback date-picker">
                {{messages['fh.reife.schulbildung.abschluss.insert']}}
              </div>
            </div>

          </div>
        </div>
      </transition>

    </div>
  </transition>

</template>

<script>

  import SelectVorbildungTree from './SelectVorbildungTree.vue';
  import UtiloDatePicker from '../layout/UtiloDatePicker.vue';
  import ReadonlyDateInput from '../layout/ReadonlyDateInput.vue';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  import SelectLandAndOrt from '../user/SelectLandAndOrt.vue';

  export default {

    name: 'insert-schulbildung',
    components: {

      UtiloDatePicker,
      SelectLandAndOrt,
      SelectVorbildungTree,
      ReadonlyDateInput

    },
    props: {
      /** vorbelegte Schulbildung */
      schulbildung: {
        type: Object,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        messages: null,
        schulbildungVon: null,
        schulbildungBis: null,
        schulbildungSchule: null,
        schulbildungOrt: null,
        /** Orts Object für Österreich */
        ortobj: null,
        schulbildungAbschluss: null,
        land: null,
        /** Sprache des Datepicker */
        sprache: null,
      }
    },
    mounted: function () {

      this.fetchMessages();
      this.setSchulbildung(this.schulbildung);
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    computed: {

      isLandValid: function() {
        if (!this.land || this.land.id == null) return false;
        return true;
      },
      isOrtValid: function () {
        if (!this.schulbildungOrt || this.schulbildungOrt === '' || this.schulbildungOrt.length < 2) return false;
        return true;
      },
      isSchulbildungVonValid: function () {

        if (!this.schulbildungVon) return false;
        return true;

      },
      isSchulbildungBisValid: function () {

        if (!this.schulbildungBis) return false;
        return true;

      },
      isSchuleValid: function () {
        if (!this.schulbildungSchule || this.schulbildungSchule === '' || this.schulbildungSchule.length < 2) return false;
        return true;
      },
      isAbschlussValid: function () {

        if (!this.schulbildungAbschluss || this.schulbildungAbschluss === '') return false;
        return true;

      }

    },
    watch: {

      schulbildung: function (newVal) {
        this.setSchulbildung(newVal);
      },
      schulbildungVon: function () {
        this.emitSchulbildung();
      },
      schulbildungBis: function () {
        this.emitSchulbildung();
      },
      schulbildungSchule: function () {
        this.emitSchulbildung();
      },
      schulbildungOrt: function () {
        this.emitSchulbildung();
      },
      schulbildungAbschluss: function () {
        this.emitSchulbildung();
      }

    },
    methods: {

      setLandAndOrt: function (obj) {

        // hat sich das Land geändert, dann auch die Orte löschen:
        if (obj && obj.land && this.land && this.land.id !== obj.land.id) {
          this.schulbildungOrt    = null;
          this.ortobj             = null;
          this.schulbildungSchule = null;
        }
        if (obj) {
          this.schulbildungOrt  = obj.ort ? obj.ort : (obj.ortobj ? obj.ortobj.name : null);
          this.ortobj           = obj.ortobj;
          this.land             = obj.land;
        } else {
          this.schulbildungOrt  = null;
          this.ortobj           = null;
          this.land             = null;
        }
        this.emitSchulbildung();

      },
      setSchulbildungAbschluss: function (val) {
        console.log('Schulbildung Abschluß = ' + val);
        this.schulbildungAbschluss = val;
      },
      setSchulbildungBis: function (val) {
        this.schulbildungBis = val;
      },
      setSchulbildungVon: function (val) {
        this.schulbildungVon = val;
      },
      setSchulbildungSchule: function (obj) {

        this.schulbildungSchule = obj;

      },
      setSchulbildung: function (obj) {

        if (obj) {
          console.log('Schulbildung setzen: ' + obj.schule + ' Ort: ' + obj.ort);
          this.schulbildungVon        = obj.von ? this.javaDateToMoment(obj.von).format('YYYY-MM-DD') : null;
          this.schulbildungBis        = obj.bis ? this.javaDateToMoment(obj.bis).format('YYYY-MM-DD') : null;
          this.schulbildungSchule     = obj.schule;
          this.schulbildungOrt        = obj.ort ? obj.ort : (obj.ortobj ? obj.ortobj.name : null);
          this.schulbildungAbschluss  = obj.abschluss ? this.javaDateToMoment(obj.abschluss).format('YYYY-MM-DD') : null;
          this.land                   = obj.land;
          this.ortobj                 = obj.ortobj;
        }

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        this.sprache = language || localStorage.language;

        var messages = [
          'fh.reife.schulbildung.von',
          'fh.reife.schulbildung.bis',
          'fh.reife.schulbildung.schule',
          'fh.reife.schulbildung.ort',
          'fh.reife.schulbildung.von.insert',
          'fh.reife.schulbildung.bis.insert',
          'fh.reife.schulbildung.schule.insert',
          'fh.reife.schulbildung.ort.insert',
          'fh.reife.schulbildung.staat',
          'fh.reife.schulbildung.staat.insert',
          'fh.reife.schulbildung.abschluss',
          'fh.reife.schulbildung.abschluss.insert',
          'fh.reife.vorbildung.hochschulabschluss'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      },
      /** Schulbildungs-Objekt zurückschreiben */
      emitSchulbildung: function () {

        var val = {
          index:  this.index
        };

        if (this.isOrtValid && this.isSchuleValid && this.isSchulbildungVonValid && this.isSchulbildungBisValid && this.isAbschlussValid && this.isLandValid) {

          console.log('Schulbildung ist valide ' + this.schulbildungOrt);
          val = {
            von:        this.schulbildungVon,
            bis:        this.schulbildungBis,
            schule:     this.schulbildungSchule,
            ort:        this.schulbildungOrt,
            abschluss:  this.schulbildungAbschluss,
            land:       this.land,
            ortobj:     this.ortobj
          };

        } else {
          console.log('Schulbildung ist NICHT valide ' + this.schulbildungOrt);
        }

        this.$emit('set-schulbildung', val);

      }

    }

  }
</script>
