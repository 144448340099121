<template>

  <input type="date"
         name="date"
         id="date"
         v-model="formattedDate"
         readonly
         v-if="formattedDate"
         class="form-control"
  />

</template>

<script>

export default {

  name: 'readonly-date-input',
  props: {
    date: {
      type: String,
      required: false
    }
  },
  computed: {

    formattedDate: function () {

      if (this.date) {
        return this.date; //this.javaDateToHtml5Date(this.date);
      }
      return null;

    }

  }

}

</script>
