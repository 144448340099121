<template>

  <transition name="fade" mode="out-in">
    <div class="isAbgeschlossenWrapper" v-if="messages && abgeschlossen">
      <div class="alert alert-warning" role="alert">
        <span v-html="messages['fh.header.isAbgeschlossen']"></span>
      </div>
    </div>
  </transition>

</template>

<script>

  import axios from 'axios';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  axios.defaults.withCredentials = true;

  export default {

    name: 'is-abgeschlossen-warning',
    components: {
    },
    props: {
      /**
       * Abgeschlossen Text zu einer bestimmten Bewerbung anzeigen,
       * ansonsten wird die Bewerbung aus der Session bezogen
       */
      bewerbung: {
        type: Object,
        required: false
      }
    },
    data () {
        return {
            messages: null,
            abgeschlossen: null
        }
    },
    mounted: function () {

      this.init();

    },
    methods: {

      init: function () {

        var that = this;
        // wenn finalAgreementDate gesetzt ist, braucht die Warnung nicht mehr angezeigt werden
        // es ist dann eh die Abschluß Meldung zu sehen
        if (this.bewerbung && this.bewerbung.finalAgreementDate) {
          this.abgeschlossen = null;
        } else if (this.bewerbung) {

          // prüfe ob alles vom Studenten erfasst wurde, Foto, Verträge und SV Nummer:
          let url    = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/isVertragszustimmungValid';
          let params = {
            bewerbung_id: this.bewerbung ? this.bewerbung.id : this.getBewerbung()
          }
          axios.get(url, {
            params: params,
            withCredentials: true
          })
          .then(function (resp) {

              if (resp.data.valid) {
                that.abgeschlossen = null;
              } else {

                if (that.bewerbung.abgeschlossen) {

                  var obj = {
                    isAbgeschlossen: that.bewerbung.abgeschlossen
                  }
                  that.abgeschlossen = obj;

                  // prüfe ob das Mail zum Studiengang Office vorhanden ist
                  if (!that.abgeschlossen.officeEmail) {

                    url  = process.env.VUE_APP_SERVER_URL + '/datenschutz/isAbgeschlossen';
                    params = {
                      bewerbung_id: that.bewerbung ? that.bewerbung.id : that.getBewerbung()
                    }
                    axios.get(url, {
                      params: params,
                      withCredentials: true
                    })
                      .then(function (response) {

                        that.abgeschlossen = response.data;
                        that.fetchMessages();

                      });

                  } else {
                    that.fetchMessages();
                  }

                }

              }

          });

        } else {

          // prüfe ob Bewerbung bereits abgeschickt wurde
          this.isAbgeschlossen(function(res) {

            that.abgeschlossen = res;

            // hole messages falls abgeschlossen:
            if (that.abgeschlossen && that.abgeschlossen.isAbgeschlossen) {
                that.fetchMessages();
            }

          });

        }

        // Listen for the set-language event on EventBus
        EventBus.$on('set-language', lang => {
          this.fetchMessages(lang);
        });

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        var messages = [
          'fh.header.isAbgeschlossen'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {

          if (that.abgeschlossen && that.abgeschlossen.isAbgeschlossen) {

            that.messages = resp;

            // ersetze das Datum im Text
            var date = that.javaDateToHtml5Date(that.abgeschlossen.isAbgeschlossen);
            var email = that.abgeschlossen.officeEmail;
            if (!email || email === '' || email === 'null') {
                email = process.env.VUE_APP_OFFICE_MAIL; // 'office@fh-salzburg.ac.at';
            }

            var mailhtml = '<a href="mailto:' + email + '">' + email + '</a>';
            that.messages['fh.header.isAbgeschlossen'] = that.messages['fh.header.isAbgeschlossen'].replace('{0}', date).replace('{1}', mailhtml) + '. ';

          }

        });

      }

    }

  }

</script>
