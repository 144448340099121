<template>

  <span class="printStudiengaengeOfBewerbungWrapper" v-if="messages">
    <div v-if="text" v-html="text">
    </div>
    <b-alert variant="danger"
             show
             v-if="!isBewerbungsfristOk && (hauptbewerbungAbgelaufenText || bewerbungsfristenAbgelaufenText)">
      {{ hauptbewerbungAbgelaufenText }} {{ bewerbungsfristenAbgelaufenText }}
    </b-alert>
  </span>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;

  export default {

    name: 'print-studiengaenge-of-bewerbung',
    props: {
      bewerbung: null
    },
    data: function () {
      return {
        /** zusammengebauter Text aus den Studiengängen */
        text: null,
        hauptStudiengang: null,
        nebenStudiengaenge: null,
        isBewerbungsfristOk: true,
        bewerbungsfristenAbgelaufen: null,
        messages: null
      }
    },
    mounted: function () {

      this.init();

    },
    computed: {

      /** Text wenn Nebenbewerbung abgelaufen ist */
      bewerbungsfristenAbgelaufenText: function () {

        if (this.messages && this.bewerbungsfristenAbgelaufen && this.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge) {

          var text = this.messages['fh.bewerbung.nebenbewerbungAbgelaufen'];
          text     = text.replace('{1}', this.bewerbungsfristenAbgelaufen.hauptAufnahmeverfahren.label);

          var nebenbewerbungen = '';
          for (var i = 0; i < this.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge.length; i++) {
            if (i > 0) nebenbewerbungen += ', '
            nebenbewerbungen += this.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge[i].label;
          }

          return text.replace('{0}', nebenbewerbungen);

        }

        return '';

      },
      /** Text wenn die Hauptbewerbung abgelaufen ist */
      hauptbewerbungAbgelaufenText: function () {

        if (this.messages && this.bewerbungsfristenAbgelaufen && this.bewerbungsfristenAbgelaufen.hauptAufnahmeverfahrenEnded) {

          var text = this.messages['fh.bewerbung.hauptbewerbungAbgelaufen'];
          return text.replace('{0}', this.bewerbungsfristenAbgelaufen.hauptAufnahmeverfahrenEnded.label);

        }

        return '';

      }

    },
    watch: {

      bewerbung: function () {
        this.init();
      }

    },
    methods: {

      init: function () {

        this.fetchMessages();

        if (this.bewerbung) {

          let params = {
            bewerbung_id: this.bewerbung.id
          }
          this.text               = '';
          this.hauptStudiengang   = null;
          this.nebenStudiengaenge = null;
          var that                = this;
          // hole Studiengänge und Nebenstudiengänge der Bewerbung
          var url = process.env.VUE_APP_SERVER_URL + '/studiengang/findStudiengaengeOfStudent';
          axios({
            method: 'post',
            url: url,
            withCredentials: true,
            params: params
          }).then(function (response) {

            console.log('studiengänge: ' + response.data);

            if (response.data.hauptStudiengang) {
              that.text = response.data.hauptStudiengang.label;
              that.hauptStudiengang = response.data.hauptStudiengang;
            }
            if (response.data.nebenStudiengaenge && response.data.nebenStudiengaenge.length > 0) {

              that.nebenStudiengaenge = response.data.nebenStudiengaenge;

              that.text += ' <small>(';
              for (var i = 0; i < response.data.nebenStudiengaenge.length; i++) {
                if (i > 0) {
                  that.text += ', '
                }
                that.text += response.data.nebenStudiengaenge[i].label;
              }
              that.text += ')</small>';

            }

          });

          // prüfe ob die Bewerbungen noch gültig sind
          url  = process.env.VUE_APP_SERVER_URL + '/datenschutz/isBewerbungsfristenOk';
          axios.get(url, {
            params: params,
            withCredentials: true
          }).then(function (response) {

            if (response.data && response.data.success) {
              that.isBewerbungsfristOk = true;
            } else {

              console.log('Problem mit Bewerbungsfristen ' + response.data);
              that.isBewerbungsfristOk = false;
              that.bewerbungsfristenAbgelaufen = response.data;

              // lösche komplette Bewerbung wenn Hauptbewerbung abgelaufen
              /*
              if (that.bewerbungsfristenAbgelaufen.isHauptAufnahmeverfahrenEnded) {
                that.deleteBewerbung(response.data.hauptAufnahmeverfahrenEnded.id, true);
              }
              */

              // lösche Nebenbewerbungen wenn abgelaufen
              if (that.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge && that.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge.length > 0) {
                for (var i = 0; i < that.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge.length; i++) {
                  that.deleteBewerbung(that.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge[i].id, false);
                }
              }

            }

          });

        }

      },
      /** eine Neben-/oder Hauptbewerbung löschen */
      deleteBewerbung: function (id, isHaupt) {

        var url  = process.env.VUE_APP_SERVER_URL + '/student/deleteNebenBewerbung';
        if (isHaupt) {
          url  = process.env.VUE_APP_SERVER_URL + '/student/deleteHauptBewerbung';
        }
        var params = {
          id: id,
          bewerbung_id: this.bewerbung.id
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then(function (response) {

          if (response.data && response.data.id) {
            console.log('Bewerbung erfolgreich gelöscht.');
          }

        });

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        var messages = [
          'fh.bewerbung.hauptbewerbungAbgelaufen',
          'fh.bewerbung.nebenbewerbungAbgelaufen'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      }

    }

  }
</script>
