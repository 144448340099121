<template>
  <div id="app">
    <fh-header v-if="$route.name != 'ListFiles'" />
    <main class=".routerView container">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
    <transition name="fade" mode="out-in">
      <fh-footer />
    </transition>
    <spinner />
    <show-bell v-if="$route.name != 'ListFiles'" />
  </div>
</template>
<script>

  import Spinner from './components/layout/Spinner.vue';
  import FhFooter from './components/layout/FhFooter.vue';
  import FhHeader from './components/layout/FhHeader.vue';
  import ShowBell from "./components/messages/ShowBell";

  export default {
    name: 'App',
    components: {
      Spinner,
      FhFooter,
      FhHeader,
      ShowBell
    }
  }

</script>
