<template>

  <div v-if="messages">
    <h2 class="h4">{{ messages['fh.kautionszahlung.weiterleitung'] }}</h2>
  </div>

</template>

<script>

import axios from 'axios';
axios.defaults.withCredentials = true;

export default {

  name: 'HobexCallback',
  data () {
    return {
      messages: null
    }
  },
  mounted: function () {

    this.init();

  },
  methods: {

    init: function () {

      this.fetchMessages()
      const pars = this.$route.query
      console.log('Hobex Response Parameter', pars)

      // Hobex liefert die ID zurück, mit der ID gleich prüfen ob die Zahlung erfolgreich war:
      if (pars.id) {

        this.showSpinner()
        const that = this;
        const url = process.env.VUE_APP_SERVER_URL + '/kaution/getPaymentStatus';
        const params = {
          bewerbung_id: this.getBewerbung(),
          hobex_id: pars.id
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then( response => {

          console.log('Payment Prüfung erfolgreich durchgeführt', response.data)
          that.$router.push('Datenschutz')
          that.hideSpinner()

        }).catch( error => {

          console.log('Fehler bei der Payment Prüfung', error)
          that.$router.push('Datenschutz')
          that.hideSpinner()

        })

      } else {

        console.error('Keine ID von Hobex response erhalten!')
        this.$router.push('Datenschutz')

      }

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.kautionszahlung.weiterleitung'
      ];
      var that = this;
      this.getMessages(language, messages, function (resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.kautionszahlung.weiterleitung'];
      });

    }

  }

}
</script>
