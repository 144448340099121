<template>

  <form name="uploadFotoForm" id="uploadFotoForm" v-if="messages">

    <check-login></check-login>

    <h2 v-if="!imgFile || !imgFile.oracleId || imgFile.fromBewerbung">{{ messages['fh.fotoUpload.headline'] }}</h2>

    <span v-if="!imgFile || !imgFile.oracleId || imgFile.fromBewerbung">
      <p class="mb-3" v-html="messages['fh.fotoUpload.description']"></p>

      <!-- Bild hochladen -->
      <div class="form-group row">
        <div class="col">
          <file-upload v-on:set-file="setFile"
                       :upload-url="uploadUrl"
                       prefix="foto_"
                       :is-foto="true"
          />
        </div>
      </div>
    </span>

    <span v-if="img">

      <h2 class="h3 mb-3" v-if="!imgFile.oracleId">{{ messages['fh.fotoUpload.description.crop.headline'] }}</h2>
      <h2 class="h3 mb-3" v-else>{{ messages['fh.fotoUpload.fotoAusweis'] }}</h2>

      <!-- Bild zuschneiden -->
      <div class="form-group row">
        <div class="col-md-6" v-if="!imgFile.oracleId">

          <cropper
            classname="cropper"
            :src="img"
            ref="cropper"
            :stencil-props="{
              aspectRatio: 10/13
            }"
            @change="change">
          </cropper>

        </div>
        <div class="col-md-6">

          <div class="form-group row">

            <div class="col-md-12" v-if="image">

              <div id="fotoWrapper" style="position: relative">
                <div id="fotoFrame">
                  <img src="../../assets/images/FH_Salzburg_Ausweisvorlage_ohne_bild_ohne_text.png" alt="Muster Studienausweis" id="studienausweis" @load="setFotoPosition" />
                  <img :src="image" id="fotoVorschau" @load="setFotoPosition" alt="Muster Studienausweis Foto" />
                  <div id="ausweisTexte" v-if="ausweisTexte">
                    <strong>{{ ausweisTexte.name }}</strong><br/>
                    {{ ausweisTexte.geburtsdatum }}<br/><br/>
                    StudierendeR, 123456<br/>
                    {{ ausweisTexte.studiengang }}<br/>
                    fh.salzburg.ac.at<br/><br/>
                    Gültig bis xxxx/xx
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
      <!-- Fehlermeldung beim Upload des Fotos -->
      <error-message v-if="!success && error" :errormessage="error" />
      <!-- Speichern Button -->
      <div class="form-group row" v-if="!imgFile.oracleId">
        <div class="col-md-12">

          <info-message v-if="success" :message="success" />

          <button v-on:click="confirmFotoUpload()"
                  type="button"
                  class="button secondary black"
                  :disabled="!formValid">
            {{ messages['default.button.save.label'] }}
          </button>

        </div>
      </div>
    </span>

  </form>

</template>

<script>

import axios from 'axios';
import CheckLogin from '../login/CheckLogin.vue';
import { Cropper } from 'vue-advanced-cropper';
import FileUpload from './FileUpload';
import ErrorMessage from '../layout/ErrorMessage';
// Import the EventBus.
import { EventBus } from '../../event-bus.js';
import InfoMessage from '../layout/InfoMessage';
import bootbox from 'bootbox'

export default {

  name: 'FotoUpload',
  components: {
    InfoMessage,
    CheckLogin,
    Cropper,
    FileUpload,
    ErrorMessage
  },
  data () {
    return {
      messages: null,
      /** Image URL */
      img: null,
      /** hochgeladener File */
      imgFile: null,
      /** zugeschnittenes Bild als Base 64 */
      image: null,
      /** Erfolgsmeldung nach Upload */
      success: null,
      /** Fehlermeldung nach Upload */
      error: null,
      /** Flag um zu speichern, dass Foto noch nicht verändert wurde */
      firstChange: true,
      /** Controller zum Bild Upload */
      uploadUrl: null,
      /** Breite des Musterausweis */
      musterBreite: 1300,
      /** Höhe des Musterausweis */
      musterHoehe: 770,
      /** Breite des Fotos im Musterausweis */
      musterBreiteFoto: 320,
      /** Offset des Fotos im Musterausweis */
      musterOffsetFoto: 995,
      /** Offset des Fotos im Musterausweis */
      musterOffsetFotoTop: 370,
      /** Text der am Ausweis angezeigt werden soll */
      ausweisTexte: null
    }
  },
  mounted: function () {

    this.uploadUrl = process.env.VUE_APP_SERVER_URL + '/foto/saveFoto';
    this.init();

  },
  created() {
    window.addEventListener('resize', this.setFotoPosition);
  },
  watch: {
    files: function () {
      // checkIfAllRequiredFilesAvailable muss ausgelöst werden
      this.$forceUpdate();
    },
    img: function () {
      console.log('base64 wurde gesetzt.');
      this.setFotoPosition();
    }
  },
  computed: {

    /**
     * prüfe ob die Formulardaten korrekt erfasst wurden
     */
    formValid: function () {

      // das Vorschau Bild muss vorhanden sein
      if (this.image) {
        return true;
      }
      return false;

    }

  },
  methods: {

    /**
     * dem Benutzer anzeigen, dass Foto im nachhinein nicht mehr
     * geändert werden kann und Form abschicken
     */
    confirmFotoUpload: function () {

      var that = this;
      var msg = this.messages['fh.fotoUpload.confirm'];
      // var bewerbungId = this.getBewerbung();
      bootbox.confirm(msg, function(result) {

        if (result) {

          that.checkForm();

        }

      });

    },
    /** berechne die tatsächliche Breite des Musterausweises */
    musterBreiteComputed: function () {

      var img   = document.getElementById('studienausweis');
      if (img) {
        var width = img.clientWidth;
        console.log('Muster Breite: ' + width);
        return width;
      }
      return 0;

    },
    /** berechne die tatsächliche Höhe des Musterausweises */
    musterHoeheComputed: function () {

      var img   = document.getElementById('studienausweis');
      if (img) {
        var height = img.clientHeight;
        return height;
      }
      return 0;

    },
    /** berechne die tatsächliche Breite des Fotos des Musterausweises */
    musterFotoComputed: function () {

      var breiteMusterComp = this.musterBreiteComputed();
      if (breiteMusterComp && breiteMusterComp > 0) {

        console.log('Tatsaechliche Groesse des Musters: ' + breiteMusterComp);
        console.log('Original Breite des Musters: ' + this.musterBreite);

        var breiteFoto     = (breiteMusterComp * this.musterBreiteFoto) / this.musterBreite;
        console.log('Ergibt Breite des Fotos: ' + breiteFoto);

        return breiteFoto;
      }
      return 0;

    },
    /** berechne die tatsächliche offset Breite des Fotos des Musterausweises */
    musterOffsetFotoComputed: function () {

      var breiteMusterComp = this.musterBreiteComputed();
      var breiteFoto       = (breiteMusterComp * this.musterOffsetFoto) / this.musterBreite;
      return breiteFoto;

    },
    /** berechne die tatsächliche offset Höhe des Fotos des Musterausweises */
    musterOffsetFotoTopComputed: function () {

      var hoeheMusterComp = this.musterHoeheComputed();
      var breiteFoto       = (hoeheMusterComp * this.musterOffsetFotoTop) / this.musterHoehe;
      return breiteFoto;

    },
    /** eine neue Datei wurde hochgeladen */
    setFile: function (val) {

      console.log('neuer File hochgeladen: ' + (val ? val.id : ''));
      this.imgFile = val;
      this.img     = val ? val.base64 : null;
      // berechne Foto Positionierung
      if (val) this.setFotoPosition();

    },
    /** Foto Vorschau positionieren */
    setFotoPosition: function () {

      console.log('Foto positionieren: ' + this.musterFotoComputed());
      $('#fotoVorschau').css('width', this.musterFotoComputed() + 'px');
      $('#fotoVorschau').css('left',  this.musterOffsetFotoComputed() + 'px');
      $('#fotoVorschau').css('top',   this.musterOffsetFotoTopComputed() + 'px');

    },
    change: function ({coordinates, canvas}) {
      console.log('Bildcoordinaten = ' + coordinates, canvas);
      this.crop();
    },
    /** Bild zuschneiden und Vorschau generieren */
    crop: function() {
        const {coordinates, canvas} = this.$refs.cropper.getResult();
        this.coordinates = coordinates;
        // You able to do different manipulations at a canvas
        // but there we just get a cropped image
        this.image = canvas.toDataURL();
    },
    init: function() {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

      var that = this;

      // hole bestehendes Foto des Studenten
      var url    = process.env.VUE_APP_SERVER_URL + '/foto/getFoto';
      var params = {
        bewerbung_id: this.getBewerbung()
      };
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          if (response.data) {
            that.imgFile = response.data;
            that.img     = response.data.base64;
            that.image   = response.data.base64;
            // berechne Foto Positionierung
            that.setFotoPosition();
          }

      })

      // hole Texte zum Ausweis
      var url    = process.env.VUE_APP_SERVER_URL + '/foto/getAusweisTexte';
      var params = {
        bewerbung_id: this.getBewerbung()
      };
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          if (response.data) {
            that.ausweisTexte = response.data;
          }

        });

    },
    base64ToBlob: function (base64, mime) {

        mime = mime || '';
        var sliceSize = 1024;
        var byteChars = window.atob(base64);
        var byteArrays = [];

        for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {

            var slice = byteChars.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);

        }

        return new Blob(byteArrays, { type: mime });

    },
    /** Formulardaten prüfen und Form absenden */
    checkForm: function () {

      if (this.formValid) {

        this.showSpinner();

        var base64ImageContent  = this.image.replace(/^data:image\/(png|jpg);base64,/, '');
        var blob                = this.base64ToBlob(base64ImageContent, 'image/png');
        var formData            = new FormData();
        formData.append('file', blob);
        var bewerbungId         = this.getBewerbung();
        var url                 = process.env.VUE_APP_SERVER_URL + '/foto/saveFoto';
        var params              = {
          bewerbung_id: bewerbungId,
          origFilename: this.imgFile.name
        };
        var that                = this;
        this.error              = null;
        this.success            = null;

        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true,
          data: formData
        })
        .then(function (response) {

            console.log('Bild hochgeladen: ' + response);
            that.hideSpinner();
            if (response.data && response.data.id) {

              that.imgFile = response.data;
              that.success = that.messages['fh.fotoUpload.upload.success'];

              // prüfe ob alles vom Studenten erfasst wurde und setze das finalAgreementDate falls ja
              url = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/isVertragszustimmungValid';
              axios.get(url, {
                params: {
                  bewerbung_id: bewerbungId
                },
                withCredentials: true
              }).then(function (response2) {

                // wenn das Final Agreement Date gesetzt wurde, kann angezeigt werden, dass er aufgenommen ist
                if (response2.data && response2.data.valid) {
                  that.success = that.messages['fh.vertragszustimmung.isComplete'];
                  bootbox.alert({
                    message: that.success,
                    backdrop: true,
                    callback: function () {

                      EventBus.$emit('is-vertragszustimmung-valid', response2.data.valid);

                    }
                  });
                }

              });

            } else {
              that.error = that.messages['fh.fotoUpload.upload.error'];
            }

        });

      }

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      var messages = [
        'fh.fotoUpload.headline',
        'fh.fotoUpload.description',
        'default.button.save.label',
        'fh.fotoUpload.description.crop',
        'fh.fotoUpload.description.crop.headline',
        'fh.fotoUpload.crop',
        'fh.fotoUpload.upload.success',
        'fh.fotoUpload.upload.error',
        'fh.fotoUpload.confirm',
        'fh.fotoUpload.fotoAusweis',
        'fh.vertragszustimmung.isComplete'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.fotoUpload.headline'];
      });

    }

  }

}
</script>
<style scoped>

  #studienausweis {
    max-width: 100%;
    position: relative;
  }
  #fotoVorschau {
    position: absolute;
  }
  #fotoWrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  #fotoFrame {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
  }
  #ausweisTexte {
    position: absolute;
    top: 104px;
    font-size: 24px;
    line-height: 30px;
    width: 70%;
  }
  @media (max-width: 1589px) {
    #ausweisTexte {
      top: 95px;
      font-size: 24px;
      line-height: 26px;
    }
  }
  @media (max-width: 1491px) {
    #ausweisTexte {
      top: 85px;
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: 1340px) {
    #ausweisTexte {
      top: 75px;
      font-size: 18px;
      line-height: 20px;
    }
  }
  @media (max-width: 1200px) {
    #ausweisTexte {
      top: 65px;
      font-size: 16px;
      line-height: 18px;
    }
  }
  @media (max-width: 1050px) {
    #ausweisTexte {
      top: 55px;
      font-size: 14px;
      line-height: 16px;
    }
  }
  @media (max-width: 962px) {
    #ausweisTexte {
      top: 45px;
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 844px) {
    #ausweisTexte {
      top: 104px;
      font-size: 24px;
      line-height: 30px;
    }
  }
  @media (max-width: 755px) {
    #ausweisTexte {
      top: 95px;
      font-size: 24px;
      line-height: 26px;
    }
  }
  @media (max-width: 705px) {
    #ausweisTexte {
      top: 85px;
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: 640px) {
    #ausweisTexte {
      top: 75px;
      font-size: 18px;
      line-height: 20px;
    }
  }
  @media (max-width: 570px) {
    #ausweisTexte {
      top: 65px;
      font-size: 16px;
      line-height: 18px;
    }
  }
  @media (max-width: 508px) {
    #ausweisTexte {
      top: 55px;
      font-size: 14px;
      line-height: 16px;
    }
  }

</style>
