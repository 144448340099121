<template>

  <div class="selectStudiengangWrapper" v-if="options && messages">

    <select v-model="selected"
            class="custom-select"
            v-bind:class="{ 'is-invalid': !isValid }"
            :disabled="readonly">
      <option value="null" disabled>{{ messages['fh.studiengang.waehlen'] }}</option>
      <option
        v-for="option in options"
        v-bind:value="option"
        v-bind:key="option.oracle_id">
        {{ option.label }}
      </option>
    </select>
    <div class="invalid-feedback" v-if="invalidMessage">
      {{invalidMessage}}
    </div>

  </div>

</template>

<script>

  import axios from 'axios';
  import {EventBus} from '../../event-bus';
  axios.defaults.withCredentials = true;

  export default {

    name: 'select-studiengang',
    props: {
      studiengang: {
        type: Object,
        required: false
      },
      invalidMessage: {
        type: String,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        /** mögliche Studiengänge */
        options: null,
        /** vom User ausgewählter Studiengang */
        selected: null,
        messages: null
      }
    },
    mounted: function () {

      this.init();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.init(lang);
      });

    },
    watch: {

      /** Studiengang wurde von aussen übergeben */
      studiengang: function (val) {

        if (val == null) {

          this.selected = null;

        } else {

          // prüfe ob der Studiengang in der Liste der Oracle Studiengänge vorhanden ist,
          // wenn ja den selektiere ihn, wenn nein, dann füge ihn der Liste hinzu
          this.checkExisting();

        }

      },
      /** Studiengang wurde vom Benutzer ausgewählt */
      selected: function (val) {
        this.emit(val);
      }

    },
    computed: {

      /** prüft ob der Studiengang eingegeben wurde */
      isValid: function () {

        if (!this.selected || (this.selected && this.selected.oracle_id == null)) {
          return false;
        }
        return true;

      }

    },
    methods: {

      init: function (lang) {

        var that = this;

        // hole alle Länder:
        var url  = process.env.VUE_APP_SERVER_URL + '/studiengang';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        })
        .then(function (response) {

          that.options = response.data;

          // ist was vorbelegt?
          that.checkExisting();

        });

        that.fetchMessages(lang);

      },
      /** prüfe ob der Studiengang in der Liste der Oracle Studiengänge vorhanden ist,
       wenn ja den selektiere ihn, wenn nein, dann füge ihn der Liste hinzu */
      checkExisting: function () {

        var found = false;
        if (this.options) {
          for (var i = 0; i < this.options.length; i++) {
            if (this.studiengang && this.studiengang.oracle_id === this.options[i].oracle_id) {
              this.selected = this.options[i];
              found = true;
              break;
            }
          }
        }
        if (!found && this.studiengang) {
          if (!this.options) {
            this.options = [];
          }
          this.options.push(this.studiengang);
          this.selected = this.studiengang;
        }

      },
      /** emit Studiengang */
      emit: function (val) {

        if (val) this.selected = val;
        this.$emit('set-studiengang', this.selected);

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        var messages = [
          'fh.studiengang.waehlen'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      },

    }

  }

</script>
