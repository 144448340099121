import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {

        /** der eingeloggte Benutzer und dessen Rolle */
        user: null,
        /** ein FH User hat sich als Student eingeloggt */
        loggedInAs: null,
        /** muss eine Kaution bezahlt werden? */
        hasKaution: null

    },
    mutations: {

        setUser(state, user) {
            state.user = user
        },
        setLoggedInAs(state, loggedInAs) {
            state.loggedInAs = loggedInAs
        },
        setHasKaution(state, val) {
            state.hasKaution = val
        }

    },
    getters: {
        user: state => state.user,
        loggedInAs: state => state.loggedInAs,
        hasKaution: state => state.hasKaution
    }

});
