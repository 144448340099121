<template>

  <div v-if="messages">

    <span v-if="size && size == 'sm'">

      <!-- kleine Variante mit einfacher Zeile zum Upload -->
      <b-form-file
        v-model="file"
        :state="done"
        :placeholder="messages['fh.fileUpload.select']"
        :drop-placeholder="messages['fh.fileUpload.select.drop']">
      </b-form-file>

    </span>
    <span v-else>

      <!-- File Upload Default, große Fläche zum ziehen aus dem Dateisystem -->
      <form class="box"
            v-bind:class="isAdvancedFileUpload"
            method="post"
            action=""
            enctype="multipart/form-data"
            draggable="true"
            v-on:dragstart="dragstart(item, $event)"
            v-on:dragenter="dragenter(item, $event)"
            v-on:dragover="dragover(item, $event)"
            v-on:drag="drag()"
            v-on:dragleave="dragleave(item, $event)"
            v-on:dragend="dragend(item, $event)"
            v-on:drop="drop(item, $event)">

        <div class="box__input mb-3">

          <svg class="box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"/></svg>
          <p class="text-center mb-3"><span v-html="messages['fh.fileUpload.dragAndDrop']" ></span></p>
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <div class="custom-file">
                <input type="file"
                       class="custom-file-input"
                       id="file"
                       ref="file"
                       v-on:change="handleFileUpload()"
                       multiple
                />
                <label class="custom-file-label" for="file">{{ messages['fh.fileUpload.select'] }}</label>
              </div>
            </div>
            <div class="col"></div>
          </div>

        </div>
        <div class="box__uploading" v-if="uploading">
          <div class="alert alert-warning text-center" role="alert">
            <span v-html="messages['fh.fileUpload.uploading']"></span>
          </div>
        </div>
        <div class="box__success" v-if="done">
          <div class="alert alert-success text-center" role="alert">
            {{ messages['fh.fileUpload.success'] }}
          </div>
        </div>
        <div class="box__error" v-if="error">
          <div class="alert alert-danger text-center" role="alert">
            {{ messages['fh.fileUpload.error'] }}
          </div>
        </div>

      </form>

    </span>

  </div>

</template>
<style scoped>
  .box__dragndrop {
    display: none;
  }
  .box.has-advanced-upload .box__dragndrop {
    display: inline;
  }
  .box.is-dragover {
    background-color: grey;
  }
  .box.has-advanced-upload {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
  }
  .box {
    /*font-size: 1.25rem;*/
    background-color: #ffffff;
    position: relative;
    padding: 50px 20px;
  }
  .box.has-advanced-upload .box__icon {
    width: 100%;
    height: 80px;
    fill: #92b0b3;
    display: block;
    margin-bottom: 40px;
  }
</style>
<script>

  import axios from 'axios';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  import bootbox from "bootbox";
  axios.defaults.withCredentials = true;

  export default {

    name: 'file-upload',
    components: {
    },
    props: {
      /** Prefix für den Filenamen */
      prefix: null,
      /** es kann auch eine kleine einzeilige Version angezeigt werden */
      size: null,
      /** UTILO_CONTRACT_AGREEMENTS ID */
      contractAgreementsId: null,
      /** true wenn es sich um ein Studenten Foto handelt */
      isFoto: null,
      /** true wenn es sich um einen Medien Upload handelt, der aufgesplitted übertragen wird */
      isLargeFile: null
    },
    data () {
      return {
        messages: null,
        isAdvancedFileUpload: '',
        item: null,
        /** Datei wird gerade hochgeladen */
        uploading: false,
        /** Dateien erfolgreich hochgeladen */
        done: false,
        /** Fehler beim Upload */
        error: false,
        /** File der hochgeladen wird bei size = 'sm' */
        file: null
      }
    },
    mounted: function () {

      this.fetchMessages();
      this.init();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    watch: {
      file: function (newVal) {
        if (newVal) {
          this.submitFile(this.file);
        }
      }
    },
    methods: {

      dragover: function (item, event) {
        console.log('drag over ...');
        event.preventDefault();
        event.stopPropagation();
        $('.box').addClass('is-dragover');
      },
      dragenter: function (item, event) {
        console.log('drag entered ...');
        event.preventDefault();
        event.stopPropagation();
        $('.box').addClass('is-dragover');
      },
      dragstart: function (item, event) {
        console.log('drag start ...');
        event.preventDefault();
        event.stopPropagation();
      },
      drag: function (item, event) {
        console.log('onDrag ...');
        event.preventDefault();
        event.stopPropagation();
      },
      dragleave: function (item, event) {
        event.preventDefault();
        event.stopPropagation();
        $('.box').removeClass('is-dragover');
      },
      dragend: function (item, event) {
        event.preventDefault();
        event.stopPropagation();
        $('.box').removeClass('is-dragover');
      },
      drop: function (item, event) {

        event.preventDefault();
        event.stopPropagation();
        $('.box').removeClass('is-dragover');
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          this.submitFile(event.dataTransfer.files[i]);
        }
        this.emitDone();

      },
      addClassToForm: function (classvalue) {

        this.isAdvancedFileUpload += ' ' + classvalue;

      },
      init: function () {

        if (this.isAdvancedUpload()) {

          this.addClassToForm('has-advanced-upload');

        } else {
          console.log('Kein Advanced Upload möglich!');
        }

      },
      /** prüft ob drag & drop File Upload möglich ist */
      isAdvancedUpload: function() {
        var div = document.createElement('div');
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
      },
      /* Utility function to convert a canvas to a BLOB */
      dataURLToBlob: function (dataURL) {

        var BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
          var parts = dataURL.split(',');
          var contentType = parts[0].split(':')[1];
          var raw = parts[1];

          return new Blob([raw], {type: contentType});
        }

        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;

        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});

      },
      getImage: async function ({
                                canvas,
                                width,
                                height,
                                mime = 'image/jpeg',
                                quality = 0.8,
                              }) {
        return new Promise(resolve => {
          const tmpCanvas = document.createElement('canvas');
          tmpCanvas.width = width;
          tmpCanvas.height = height;

          const ctx = tmpCanvas.getContext('2d');
          ctx.drawImage(
            canvas,
            0,
            0,
            canvas.width,
            canvas.height,
            0,
            0,
            width,
            height,
          );

          tmpCanvas.toBlob(resolve, mime, quality);
        });
      },
      resizeImage: function () {

        let that           = this;
        let filesToUploads = document.getElementById('file').files;
        let file           = filesToUploads[0];
        if (file) {

          console.log('Bild zum zuschneiden verkleinern ' + file);
          let reader = new FileReader();

          // Set the image for the FileReader
          reader.onload = function (e) {

            var img = document.createElement('img');
            img.src = e.target.result;
            img.onload = function () {

              var MAX_WIDTH = 800;
              var MAX_HEIGHT = 800;

              var width = img.width;
              var height = img.height;

              // Create canvas und zeiche img
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);

              // Add the resizing logic
              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }

              canvas.height = height;
              canvas.width  = width;
              ctx.drawImage(img, 0, 0, width, height);

              // File nicht mehr an Server senden sondern als base64 zurückliefern an FotoUpload
              const fullQuality = canvas.toDataURL('image/png')
              let data = {
                base64: fullQuality,
                name: file.name
              }
              that.emitFile(data);

            }

          };

          reader.readAsDataURL(file);

        }

      },
      /**
       * hochgeladenen File an Server senden
       * @param file
       */
      doSubmitFile: function (file) {

        console.log('do submit file ' + file);

        // passt die Filegröße?
        // Achtung Änderungen auch in application.yml setzen!
        if (file.size > 11534336) {
          this.uploading = false;
          this.done = false;
          this.error = true;
          return;
        }

        this.uploading  = true;
        this.done       = false;
        this.error      = false;
        let that        = this;

        let formData = new FormData();
        formData.append('file', file);

        // File an Server senden
        var url = process.env.VUE_APP_SERVER_URL + '/file/fileUpload';
        var params = {
          prefix:               this.prefix ? this.prefix : '',
          bewerbung_id:         this.getBewerbung(),
          contractAgreementsId: this.contractAgreementsId,
          isFoto:               this.isFoto
        };
        // speichere File in Filesystem und hole neuen filename
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true,
          contentType: 'multipart/form-data',
          data: formData
        }).then(function (daten) {

          console.log('File erfolgreich hochgeladen: ' + daten);

          try {
            // schreibe Dateinamen in EventBus
            if (daten.data && daten.data.id) {
              that.uploading = false;
              that.done = true;
              that.error = false;
              that.emitFile(daten.data);
            } else {
              that.uploading = false;
              that.done = false;
              that.error = true;
            }
          } catch (e) {
            that.uploading = false;
            that.done = false;
            that.error = true;
          }

        }).catch(error => {

          console.log('Upload Error: ' + error);

          that.uploading = false;
          that.done = false;
          that.error = true;

        });

      },
      /**
       * prüft ob ein File ein heic Format ist
       */
      isHeic: function (filename) {

        filename = filename.toLowerCase()
        if (filename.endsWith('.heic')) {
          return true
        }
        return false

      },
      /**
       * prüft ob ein Bild ein jpg oder png ist
       * @param filename
       * @returns {boolean}
       */
      isJpgOrPng: function (filename) {

        filename = filename.toLowerCase()
        if (filename.endsWith('.png') || filename.endsWith('.jpg') || filename.endsWith('.jpeg')) {
          return true
        }
        return false

      },
      /*
        Submits the file to the server
      */
      submitFile: function (file) {

        console.log('submit file ' + file)

        // wenn es ein Foto ist, dann zuerst runterrechnen
        if (this.isFoto) {

            // prüfe ob png oder jpg
            if (!file || !file.name || !this.isJpgOrPng(file.name)) {
              // Fehlermeldung zurückgeben
              const msg = this.messages['fh.fotoUpload.nojpg']
              bootbox.alert(msg)
              this.filename = null
              this.uploading  = false
              this.done       = false
              return null
            }

            this.$emit('set-file', null)
            this.resizeImage()

        } else if (this.isLargeFile) {

          // einen fetten Medien File an den Parent zurückgeben,
          // er wird dort aufgesplitted und übertragen
          this.$emit('set-file', file);

        } else {

            // Apple heic Format ist nicht erlaubt
            if (file && file.name && this.isHeic(file.name)) {
              // Fehlermeldung zurückgeben
              const msg = this.messages['fh.fotoUpload.notallowed']
              bootbox.alert(msg)
              this.filename = null
              this.uploading  = false
              this.done       = false
              return null
            }

            this.doSubmitFile(file);

        }

      },
      /*
        Handles a change on the file upload
      */
      handleFileUpload: function () {

        console.log('handleFileUpload')
        this.showSpinner()
        this.filename = null
        try {
          this.submitFile(this.$refs.file.files[0])
        } catch (e) {
          this.uploading  = false
          this.done       = false
          this.error      = true
        }
        this.emitDone()

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        const messages = [
          'fh.fileUpload.select',
          'fh.fileUpload.dragAndDrop',
          'fh.fileUpload.error',
          'fh.fileUpload.success',
          'fh.fileUpload.select.drop',
          'fh.fileUpload.uploading',
          'fh.fotoUpload.nojpg',
          'fh.fotoUpload.notallowed'
        ]
        const that = this
        this.getMessages(language, messages, function(resp) {
          that.messages = resp
        })

      },
      /** Objekt zurückschreiben */
      emitFile: function (file) {

        if (this.contractAgreementsId) {
          // wenn ein Upload zu einem Contract durchgeführt wurde, auch
          // die Id des ContractAgreement zurückliefern
          var obj = {
            contractAgreementsId: this.contractAgreementsId,
            file: file
          }
          this.$emit('set-file', obj);

        } else {
          this.$emit('set-file', file);
        }

      },
      /** Alle Files wurden hochgeladen */
      emitDone: function () {
        this.$emit('set-done', true);
        this.hideSpinner();
      }

    }

  }

</script>
