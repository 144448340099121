<template>

  <div v-if="messages && studiengang">

    <h4 class="mb-3">{{ studiengang.name }}</h4>
    <p>{{ messages['fh.fileUpload.description'] }}</p>

    <!-- Beschreibung des Uploads -->
    <div class="card mt-3" v-if="aufnahmeverfahren && aufnahmeverfahren.notwendigeDokumente">

      <div class="card-header">
        {{ messages['fh.fileUpload.erforderlicheDokumente'] }}
      </div>
      <div class="card-body" v-html="aufnahmeverfahren.notwendigeDokumente"></div>

    </div>
    <!-- Tabelle mit hochgeladenen Dokumenten des Studenten -->
    <div class="uploadedDokuments mt-3" v-if="files && files.length > 0">

      <h4>{{ messages['fh.fileUpload.unterlagen'] }}</h4>

      <div class="table-responsive mt-3">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">{{ messages['fh.fileUpload.dokumenttyp'] }}</th>
              <th scope="col">{{ messages['fh.fileUpload.beschriftung'] }}</th>
              <th scope="col">{{ messages['fh.fileUpload.filename'] }}</th>
              <th scope="col"><!-- falls durch FH gelöscht --></th>
              <th scope="col"><!-- löschen --></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) in files" v-bind:key="file.id">
              <td>{{ file.dokumenttyp ? file.dokumenttyp.name : '' }}</td>
              <td>{{ file.bezeichnung }}</td>
              <td>{{ file.originalFilename }}</td>
              <td>
                <span v-if="file.deletedByJob || file.fehlermeldung">
                  <span v-if="file.fehlermeldung" style="color: red">
                    {{ file.fehlermeldung }}
                  </span>
                  <span v-else>
                    {{ printDeletedByJob(file.deletedByJob) }}
                  </span>
                </span>
              </td>
              <td class="text-right">
                <button type="button"
                        v-if="(!file.deletedByJob || file.fehlermeldung) && (!abgeschlossen || !file.uploaded || !file.oracleId)"
                        class="btn btn-warning btn-sm"
                        v-on:click="deleteFile(file, index)">{{ messages['fh.fileUpload.remove'] }}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="alert alert-warning" v-if="newFilesNotUploaded">
        {{ messages['fh.fileUpload.nichtVollstaendig'] }}
      </div>

    </div>
    <!-- hochladen eines neuen Dokuments -->
    <div class="uploadDokument mt-3">

      <h4>{{ messages['fh.fileUpload.add'] }}</h4>

      <div class="form-group row">
        <div class="col-md-2">
          <label for="dokumenttyp" class="col-form-label">{{ messages['fh.fileUpload.dokumenttyp'] }}*</label>
        </div>
        <div class="col-md-10">
          <select-dokumenttyp :invalidMessage="messages['fh.fileUpload.dokumenttyp.insert']"
                              id="dokumenttyp"
                              :dokumenttyp="dokumenttyp"
                              v-on:set-dokumenttyp="setDokumenttyp">
          </select-dokumenttyp>
        </div>
      </div>
      <div class="form-group row" v-if="dokumenttyp">

        <div class="col-md-2">
          <label for="bezeichnung" class="col-form-label">{{ messages['fh.fileUpload.beschriftung'] }}*</label>
        </div>
        <div class="col-md-10">
          <input type="text"
                 maxlength="256"
                 id="bezeichnung"
                 name="bezeichnung"
                 v-model.trim="bezeichnung"
                 class="form-control"
                 v-bind:class="{ 'is-invalid': !isBezeichnungValid() }"
                 :placeholder="messages['fh.fileUpload.bezeichnung']"
                 required
          />
          <div class="invalid-feedback">
            {{ messages['fh.fileUpload.bezeichnung.insert'] }}
          </div>
        </div>
      </div>
      <div class="form-group row" v-if="dokumenttyp && bezeichnung">
        <div class="col">
          <file-upload v-on:set-file="setFile"
                       :prefix="studiengang.kuerzel">
          </file-upload>
        </div>
      </div>

    </div>

  </div>

</template>
<script>

  import axios from 'axios';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  import FileUpload from './FileUpload.vue';
  import SelectDokumenttyp from './SelectDokumenttyp.vue';
  import bootbox from 'bootbox';
  axios.defaults.withCredentials = true;

  export default {

    name: 'studiengang-upload',
    components: {
      FileUpload,
      SelectDokumenttyp
    },
    props: {
      /** Studiengang für den der Upload durchgeführt werden soll */
      studiengang: null,
      newFilesUploadedClear: null,
      /** init wird neu ausgeführt */
      reload: null,
    },
    data () {
      return {
        messages: null,
        /** das Aufnahmeverfahren das der Student zu diesem Studiengang gewählt hat */
        aufnahmeverfahren: null,
        /** vom Studenten hochgeladene Files */
        files: [],
        bezeichnung: null,
        dokumenttyp: null,
        abgeschlossen: null
      }
    },
    computed: {

      /** falls Bewerbung bereits abgeschlossen und noch nicht alle Files übertragen wurden */
      newFilesNotUploaded: function () {

        // prüfe ob einer dieser Files noch nicht an Oracle übertragen wurde
        if (this.files && this.abgeschlossen) {
          for (var i = 0; i < this.files.length; i++) {
            if (this.files[i].oracleId == null) {
              return true;
            }
          }
        }
        return false

      }

    },
    mounted: function () {

      this.fetchMessages();
      this.init();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    watch: {

      reload: function (newVal) {
        if (newVal) {
          this.init()
        }
      },
      /** markiere Files als bereits hochgeladen */
      newFilesUploadedClear: function (val) {

        if (val) {
          var newlist = [];
          for (var i = 0; i < this.files.length; i++) {
            this.files[i].uploaded = true;
            this.files[i].oracleId = true;
            newlist.push(this.files[i]);
          }
          this.files = newlist;
        }

      }

    },
    methods: {

      /**
       * zeigt dem Benutzer an, dass der File aus datenschutzerrechtlichen Gründen gelöscht wurde
       */
      printDeletedByJob: function (javadate) {

        let txt = this.messages['fh.fileUpload.fileDeletedByJob'];
        txt = txt.replace('{0}', this.javaDateToHtml5Date(javadate));
        return txt;

      },
      /**
       * falls nach Abschluss weitere Files hochgeladen wurden
       * an Parent übermitteln, damit der Übermitteln Button angezeigt
       * werden kann
       */
      emitNewFiles: function (val) {

        if (this.abgeschlossen) {
          this.$emit('new-files-uploaded', val);
        }

      },
      setDokumenttyp: function (val) {
        this.dokumenttyp = val;
      },
      init: function () {

        // hole die Beschreibung der erforderlichen Dokumente
        var that = this;

        // prüfe ob Bewerbung bereits abgeschickt wurde
        this.isAbgeschlossen(function(res) {
          that.abgeschlossen = res;
        });

        if (this.studiengang) {

          // hole bereits hochgeladene Files zu diesem Studiengang und Studenten
          var url    = process.env.VUE_APP_SERVER_URL + '/file';
          var params = {
            bewerbung_id:   this.getBewerbung(),
            studiengang_id: this.studiengang.id
          };
          axios.get(url, {
            params: params,
            withCredentials: true
          })
            .then(function (response) {

              if (!response.data.error) {

                that.files = response.data;

                // markiere diese Files als bereits hochgeladen
                var newlist = [];
                for (var i = 0; i < that.files.length; i++) {
                  that.files[i].uploaded = true;
                  newlist.push(that.files[i]);
                }
                that.files = newlist;

              }

            });

        }

      },
      /** hole Aufnahmeverfahren mit notwendigen Dokumenten des Bewerbers */
      findAufnahmeverfahren: function () {

        var that = this;
        var url    = process.env.VUE_APP_SERVER_URL + '/studiengang/findAufnahmeverfahren';
        var params = {
          id:           this.studiengang.id,
          bewerbung_id: this.getBewerbung()
        };
        axios.get(url, {
          params: params,
          withCredentials: true
        })
          .then(function (response) {

            that.aufnahmeverfahren = response.data;

          });

      },
      isBezeichnungValid: function () {
        if (!this.bezeichnung || this.bezeichnung.length < 3) {
          return false;
        }
        return true;
      },
      /**
       * eine neue Datei wurde hochgeladen, zur Liste der Files hinzufügen
       * und speichern
       */
      setFile: function (val) {

        console.log('neuer File hingefügt: ' + val.id);
        if (!this.files) this.files = [];
        val.dokumenttyp = this.dokumenttyp;
        val.bezeichnung = this.bezeichnung;

        // File Daten speichern:
        var item = {
          id          : val.id,
          bezeichnung : this.bezeichnung,
          dokumenttyp : this.dokumenttyp.id,
          studiengang : this.studiengang.id
        }

        var str = JSON.stringify(item);

        var url    = process.env.VUE_APP_SERVER_URL + '/file';
        var params = {
          json: str,
          bewerbung_id: this.getBewerbung()
        };
        var that = this;
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true
        })
          .then( () => {

            that.files.push(val);
            that.bezeichnung = null;
            that.dokumenttyp = null;
            that.emitNewFiles(true);

          });

      },
      /** einen File wieder löschen */
      deleteFile: function (file, index) {

        var that = this;
        var msg = this.messages['fh.fileUpload.remove.sure'];
        msg = msg.replace('{0}', file.bezeichnung);
        var bewerbungId = this.getBewerbung();
        bootbox.confirm(msg, function(result) {

          if (result) {

            that.showSpinner();

            var url = process.env.VUE_APP_SERVER_URL + '/file/delete';
            var params = {
              file_id: file.id,
              bewerbung_id: bewerbungId
            };

            axios({
              method: 'post',
              url: url,
              params: params,
              withCredentials: true
            })
              .then( () => {

                that.hideSpinner();
                that.files.splice(index, 1);

                // sind noch neue Files vorhanden, wenn nein, dann den Button wieder deaktivieren um neue
                // Files an die FH zu übermitteln
                var exists = false;
                for (var i = 0; i < that.files.length; i++) {
                  if (!that.files[i].uploaded) {
                    exists = true;
                  }
                }
                if (!exists) {
                  that.emitNewFiles(false);
                }

              });

          }

        });

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        var messages = [
          'fh.fileUpload.select',
          'fh.fileUpload.description',
          'fh.fileUpload.erforderlicheDokumente',
          'fh.fileUpload.unterlagen',
          'fh.fileUpload.remove.sure',
          'fh.fileUpload.add',
          'fh.fileUpload.dokumenttyp',
          'fh.fileUpload.beschriftung',
          'fh.fileUpload.bezeichnung.insert',
          'fh.fileUpload.bezeichnung',
          'fh.fileUpload.dokumenttyp.insert',
          'fh.fileUpload.remove',
          'fh.fileUpload.filename',
          'fh.fileUpload.nichtVollstaendig',
          'fh.fileUpload.fileDeletedByJob'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
          that.findAufnahmeverfahren();
        });

      }

    }

  }

</script>
