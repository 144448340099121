<template>

  <div class="selectSprachkenntnisseScalaWrapper" v-if="messages">

    <div class="accordion" :id="'accordionSprachkenntnisse-' + idPrefix" v-if="!readonly">
      <div class="card">
        <div class="card-header" :id="'sprachkenntnisseAHeader-' + idPrefix">
          <h5 class="mb-0">
            <button class="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseA-' + idPrefix"
                    aria-expanded="true"
                    :aria-controls="'collapseA-' + idPrefix">
              {{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.A'] }}
            </button>
          </h5>
        </div>
        <div :id="'collapseA-' + idPrefix"
             class="collapse"
             v-bind:class="{ 'show': selected === 'A1' || selected === 'A2' }"
             :aria-labelledby="'sprachkenntnisseAHeader-' + idPrefix"
             :data-parent="'#accordionSprachkenntnisse-' + idPrefix">
          <div class="card-body">

            <div class="list-group">

              <a style="cursor: pointer"
                 class="list-group-item list-group-item-action flex-column align-items-start"
                 :class="{active : (selected === 'A1')}"
                 v-on:click="setQualifikation('A1')">
                <div class="d-flex w-100 justify-content-between">
                  <strong class="mb-1">
                    <div class="custom-control custom-radio">
                      <input type="radio" :id="'A1-' + idPrefix" :name="'sprachniveau-' + idPrefix" class="custom-control-input" value="A1" v-model="selected" :readonly="readonly">
                      <label class="custom-control-label" for="A1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.A1'] }}</label>
                    </div>
                  </strong>
                </div>
                <p class="mb-1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.A1.description'] }}</p>
              </a>

              <a style="cursor: pointer"
                 class="list-group-item list-group-item-action flex-column align-items-start"
                 :class="{active : (selected === 'A2')}"
                 v-on:click="setQualifikation('A2')">
                <div class="d-flex w-100 justify-content-between">
                  <strong class="mb-1">
                    <div class="custom-control custom-radio">
                      <input type="radio" :id="'A2-' + idPrefix" :name="'sprachniveau-' + idPrefix" class="custom-control-input" value="A2" v-model="selected" :readonly="readonly">
                      <label class="custom-control-label" for="A2">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.A2'] }}</label>
                    </div>
                  </strong>
                </div>
                <p class="mb-1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.A2.description'] }}</p>
              </a>

            </div>

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" :id="'sprachkenntnisseBHeader-' + idPrefix">
          <h5 class="mb-0">
            <button class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseTwo-' + idPrefix"
                    aria-expanded="false"
                    :aria-controls="'collapseTwo-' + idPrefix">
              {{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.B'] }}
            </button>
          </h5>
        </div>
        <div :id="'collapseTwo-' + idPrefix"
             class="collapse"
             v-bind:class="{ 'show': selected === 'B1' || selected === 'B2' }"
             :aria-labelledby="'sprachkenntnisseBHeader-' + idPrefix"
             :data-parent="'#accordionSprachkenntnisse-' + idPrefix">
          <div class="card-body">

            <a style="cursor: pointer"
               class="list-group-item list-group-item-action flex-column align-items-start"
               :class="{active : (selected === 'B1')}"
               v-on:click="setQualifikation('B1')">
              <div class="d-flex w-100 justify-content-between">
                <strong class="mb-1">
                  <div class="custom-control custom-radio">
                    <input type="radio" :id="'B1-' + idPrefix" :name="'sprachniveau-' + idPrefix" class="custom-control-input" value="B1" v-model="selected">
                    <label class="custom-control-label" for="B1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.B1'] }}</label>
                  </div>
                </strong>
              </div>
              <p class="mb-1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.B1.description'] }}</p>
            </a>

            <a style="cursor: pointer"
               class="list-group-item list-group-item-action flex-column align-items-start"
               :class="{active : (selected === 'B2')}"
               v-on:click="setQualifikation('B2')">
              <div class="d-flex w-100 justify-content-between">
                <strong class="mb-1">
                  <div class="custom-control custom-radio">
                    <input type="radio" :id="'B2-' + idPrefix" :name="'sprachniveau-' + idPrefix" class="custom-control-input" value="B2" v-model="selected">
                    <label class="custom-control-label" for="B2">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.B2'] }}</label>
                  </div>
                </strong>
              </div>
              <p class="mb-1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.B2.description'] }}</p>
            </a>

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" :id="'sprachkenntnisseCHeader-' + idPrefix">
          <h5 class="mb-0">
            <button class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseThree-' + idPrefix"
                    aria-expanded="false"
                    :aria-controls="'collapseThree-' + idPrefix">
              {{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.C'] }}
            </button>
          </h5>
        </div>
        <div :id="'collapseThree-' + idPrefix"
             class="collapse"
             v-bind:class="{ 'show': selected === 'C1' || selected === 'C2' }"
             :aria-labelledby="'sprachkenntnisseCHeader-' + idPrefix"
             :data-parent="'#accordionSprachkenntnisse-' + idPrefix">
          <div class="card-body">

            <a style="cursor: pointer"
               class="list-group-item list-group-item-action flex-column align-items-start"
               :class="{active : (selected === 'C1')}"
               v-on:click="setQualifikation('C1')">
              <div class="d-flex w-100 justify-content-between">
                <strong class="mb-1">
                  <div class="custom-control custom-radio">
                    <input type="radio" :id="'C1-' + idPrefix" :name="'sprachniveau-' + idPrefix" class="custom-control-input" value="C1" v-model="selected" :readonly="readonly">
                    <label class="custom-control-label" for="C1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.C1'] }}</label>
                  </div>
                </strong>
              </div>
              <p class="mb-1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.C1.description'] }}</p>
            </a>

            <a style="cursor: pointer"
               class="list-group-item list-group-item-action flex-column align-items-start"
               :class="{active : (selected === 'C2')}"
               v-on:click="setQualifikation('C2')">
              <div class="d-flex w-100 justify-content-between">
                <strong class="mb-1">
                  <div class="custom-control custom-radio">
                    <input type="radio" :id="'C2-' + idPrefix" :name="'sprachniveau-' + idPrefix" class="custom-control-input" value="C2" v-model="selected" :readonly="readonly">
                    <label class="custom-control-label" for="C2">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.C2'] }}</label>
                  </div>
                </strong>
              </div>
              <p class="mb-1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.C2.description'] }}</p>
            </a>

          </div>
        </div>
      </div>
    </div>
    <!-- readonly -->
    <div v-else>

      <strong class="mb-1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.' + selected] }}</strong>
      <p class="mb-1 mt-1">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.' + selected + '.description'] }}</p>

    </div>

  </div>

</template>

<script>

  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';

  export default {

    name: 'select-sprachkenntnisse-scala',
    props: {
      /** über v-model übergebener Level */
      value: null,
      readonly: null,
      idPrefix: null
    },
    data () {
      return {
        messages: null,
        /** die ausgewählte Qualifikation */
        selected: null
      }
    },
    mounted: function () {

      this.init();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    methods: {

      /** Bewerbergruppe wurde ausgewählt */
      setQualifikation: function(val) {
        this.selected = val;
        this.emit();
      },
      init: function() {

        if (this.value) {
          this.selected = this.value;
        }
        this.fetchMessages();

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        var messages = [
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.A',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.B',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.C',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.A1',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.A2',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.A1.description',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.A2.description',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.B1',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.B2',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.B1.description',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.B2.description',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.C1',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.C2',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.C1.description',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.C2.description'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      },
      /** emit Sprachniveau */
      emit: function() {

        this.$emit('input', this.selected);

      }

    }

  }

</script>
