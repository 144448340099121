<template>

  <div id="vertragszustimmungForm" v-if="messages">

    <form name="vertragszustimmung">

      <check-login></check-login>
      <nav-tabs :active="'Vertragszustimmung'"></nav-tabs>

      <h1 class="h2 mt-5">{{ messages['fh.vertragszustimmung.h2'] }}</h1>

      <!-- anzeigen wenn Foto hochgeladen wurde und allen Verträgen zugestimmt wurde -->
      <div class="mt-5">
        <alert-success-is-complete :message="messages['fh.vertragszustimmung.isComplete']" />
      </div>

      <hr class="mt-5 mb-5" />

      <insert-sv-nummer v-model="svnummer" v-on:has-no-sv-number="setHasNoSvNumber" />

      <hr class="mt-5 mb-5" />

      <transition name="fade" mode="out-in">
        <span v-if="svnummer || hasNoSvNumber">

          <!-- ein Student hat StudentAufnahmeverfahrenDatum -->
          <span v-for="studentAufnahmeverfahrenDatum in studentAufnahmeverfahrenDatums" v-bind:key="studentAufnahmeverfahrenDatum.id">

            <table-row-contract :student-aufnahmeverfahren-datum="studentAufnahmeverfahrenDatum"
                                :studiengang="studentAufnahmeverfahrenDatum.aufnahmeverfahrenDatum.aufnahmeverfahren.studiengang"
            />

          </span>
          <!-- ein AO Hörer und ein Incoming haben nur eine angestrebte Studientrichtung -->
          <span v-if="angestrebterStudiengang">
            <table-row-contract :studiengang="angestrebterStudiengang" />
          </span>

          <hr class="mt-5 mb-5" />

          <foto-upload />

        </span>
      </transition>

    </form>

  </div>

</template>

<script>

import axios from 'axios';
import FotoUpload from '@/components/upload/FotoUpload';
import CheckLogin from '@/components/login/CheckLogin.vue';
import InsertSvNummer from '@/components/user/InsertSvNummer';
import NavTabs from '@/components/layout/NavTabs.vue';
import TableRowContract from '@/components/vertragszustimmung/TableRowContract';
import AlertSuccessIsComplete from '@/components/vertragszustimmung/AlertSuccessIsComplete';
// Import the EventBus.
import { EventBus } from '../event-bus.js';

export default {

  name: 'Vertragszustimmung',
  components: {
      CheckLogin,
      FotoUpload,
      InsertSvNummer,
      NavTabs,
      TableRowContract,
      AlertSuccessIsComplete
  },
  data () {
    return {
      messages: null,
      /** Liste mit Aufnahmeverfahren mit Contract Dokumenten */
      studentAufnahmeverfahrenDatums: null,
      /** 4-stellige Sozialversicherungsnummer */
      svnummer: null,
      /** true falls der Student keine österreichische SV Nummer hat */
      hasNoSvNumber: null,
      /** angestrebter Studiengang für AO und Incoming */
      angestrebterStudiengang: null
    }
  },
  mounted: function () {

    this.init();

  },
  methods: {

    setHasNoSvNumber: function (val) {
      this.hasNoSvNumber = val;
    },
    init: function () {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

      // hole alle nötigen Daten zur Vertragszustimmung
      var that   = this;
      var url    = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios({
          method:           'get',
          url:              url,
          withCredentials:  true,
          params:           params
      }).then(function (daten) {

          that.studentAufnahmeverfahrenDatums = daten.data.studentAufnahmeverfahrenDatum;
          that.angestrebterStudiengang        = daten.data.studiengang;

      });

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
          'fh.vertragszustimmung.h2',
          'fh.vertragszustimmung.isComplete'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.vertragszustimmung.h2'];
      });

    }

  }

}
</script>
