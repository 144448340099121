<template>

  <div id="studentenWrapper">

    <transition name="fade" mode="out-in">

      <div>

        <nav id="nav" class="mb-3">

          <div class="row">
            <div class="col-md-12 text-right">

              <button class="button secondary black btn-sm"
                      v-if="$store.getters.user && $store.getters.user.role == 'ROLE_ADMIN'"
                      @click="$router.push('FhUser')">
                FH Benutzer
              </button>
              <button class="button secondary black btn-sm" @click="logout(true)">logout</button>

            </div>
          </div>

        </nav>

        <check-login></check-login>

        <h1>Studenten</h1>
        <h2>Im Bewerbungsportal registrierte Benutzer</h2>

        <div>

          <!-- Ergebnis Tabelle Filtern -->
          <div id="filter" class="row border-top mb-3 pt-3">

            <div class="col-md-11">

              <b-input-group size="sm">
                <b-form-input
                  v-model="keyword"
                  type="search"
                  id="filterInput"
                  placeholder="Filtern ...">
                </b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
            <div class="col-md-1 text-right">

              <b-form-group horizontal label="" class="">
                <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
              </b-form-group>

            </div>

          </div>

          <form class="mb-3">
            <strong>Tabellenspalten ein/ausblenden</strong><br>
            <div class="form-check form-check-inline mt-1"
                 style="margin-bottom: 0; padding-left: 0"
                 v-for="field in fields"
                 :key="field.key">
              <b-form-checkbox
                :id="'checkbox' + field.key"
                v-model="field.show"
                name="checkbox"
                :value="true"
                :unchecked-value="false">
                {{ field.label }}
              </b-form-checkbox>
            </div>
          </form>

          <b-overlay :show="showOverlay" rounded="sm">

            <b-table striped
                     id="studenten-table"
                     small
                     :fields="myFields"
                     :items="myProvider"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :responsive="true">

              <template #cell(enabled)="row">
                <b-form-checkbox v-model="row.item.enabled" name="check-button" switch @change="switchEnabled(row.item)">
                  <span v-if="row.item.enabled">aktiv</span><span v-else style="color: #dc3545">gesperrt</span>
                </b-form-checkbox>
              </template>

              <!-- UHStat1 erfolgreich registriert? -->
              <template #cell(uhstat)="row">
                <has-uhstat1 :student="row.item" />
              </template>

              <!-- wurde die Kaution bezahlt? -->
              <template #cell(AZ_SBG)="row">
                <is-kaution-bezahlt v-model="row.item" />
              </template>

              <!-- Personen von der Kaution befreien -->
              <template #cell(kaution)="row">
                <kaution-befreien v-model="row.item" v-on:kaution-befreit="reloadTable"/>
              </template>

              <template #cell(geburtsdatum)="row">
                  <span v-if="row.item.geburtsdatum">
                    {{ javaDateToHtml5Date(row.item.geburtsdatum) }}
                  </span>
              </template>

              <template #cell(einreichungsdaten)="row">
                  <span v-if="row.item.bewerbungen && row.item.bewerbungen.length > 0">
                    <ul class="list-unstyled">
                      <li v-for="item in row.item.bewerbungen" :key="item.id">
                        <span v-if="item.abgeschlossen">{{ javaDateToHtml5Date(item.abgeschlossen) }}</span>
                        <span v-else>---</span>
                      </li>
                    </ul>
                  </span>
              </template>

              <template #cell(dateCreated)="row">
                  <span v-if="row.item.bewerbungen && row.item.bewerbungen.length > 0">
                    <ul class="list-unstyled">
                      <li v-for="item in row.item.bewerbungen" :key="item.id">
                       {{ javaDateToHtml5Date(item.dateCreated) }}
                      </li>
                    </ul>
                  </span>
              </template>

              <template #cell(bewerbergruppen)="row">
                  <span v-if="row.item.bewerbungen && row.item.bewerbungen.length > 0">
                    <ul class="list-unstyled">
                      <li v-for="item in row.item.bewerbungen" :key="item.id">
                        <span v-if="item.bewerbergruppe == 'STUDENT'">Student</span>
                        <span v-else-if="item.bewerbergruppe == 'AUSSERORDENTLICHER_HOERER'">AO Hörer</span>
                        <span v-else-if="item.bewerbergruppe == 'INCOMING'">Incoming</span>
                        <span v-else>--</span>
                      </li>
                    </ul>
                  </span>
              </template>

              <template #cell(hobexTransactionId)="row">
                  <span v-if="row.item.bewerbungen && row.item.bewerbungen.length > 0">
                    <ul class="list-unstyled">
                      <li v-for="item in row.item.bewerbungen" :key="item.id">
                        <span v-if="item.kaution && item.kaution.hobexTransactionId">{{ item.kaution.hobexTransactionId }}</span>
                        <span v-else>&nbsp;</span>
                      </li>
                    </ul>
                  </span>
              </template>

              <template #cell(actions)="row">
                <b-button variant="outline-primary"
                          class="mb-1"
                          size="sm"
                          @click="loginAs(row.item)">
                  <b-icon icon="file-person"></b-icon> einloggen
                </b-button>
                <b-button variant="outline-danger"
                          size="sm"
                          v-b-tooltip.hover
                          title="Studierende*n löschen"
                          @click="confirmDeleteStudent(row.item)"
                          class="ml-1 mb-1">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>

            </b-table>

            <b-row>
              <b-col md="12" class="my-1">
                <b-pagination :total-rows="totalRows"
                              :per-page="perPage"
                              v-model="currentPage"
                              class="my-0"
                />
              </b-col>
            </b-row>

          </b-overlay>

        </div>


      </div>

    </transition>

  </div>

</template>
<script>

  import HasUhstat1             from '@/components/uhstat/HasUhstat1.vue'
  import KautionBefreien        from '@/components/studenten/kautionBefreien.vue'
  import CheckLogin             from '@/components/login/CheckLogin.vue'
  import IsKautionBezahlt       from '@/components/studenten/isKautionBezahlt.vue'
  import bootbox                from 'bootbox'
  import axios                  from 'axios'
  axios.defaults.withCredentials = true;

  export default {

    name: 'Studenten',
    components: {
      HasUhstat1,
      CheckLogin,
      KautionBefreien,
      IsKautionBezahlt,
    },
    data () {
      return {
        showOverlay: false,
        totalRows: 0,
        perPage: 50,
        currentPage: 1,
        fields: [
          {
            key: 'id',
            label: '#',
            sortable: true,
            show: false,
          },
          {
            key: 'username',
            label: 'Benutzername',
            sortable: true,
            show: true,
          },
          {
            key: 'vorname',
            label: 'Vorname',
            sortable: true,
            show: true,
          },
          {
            key: 'nachname',
            label: 'Nachname',
            sortable: true,
            show: true,
          },
          {
            key: 'geburtsdatum',
            label: 'Geburtsdatum',
            sortable: true,
            show: true,
          },
          {
            key: 'bewerbergruppen',
            label: 'Bewerbergruppen',
            sortable: true,
            show: true,
          },
          {
            key: 'dateCreated',
            label: 'Bewerbungs-Datum',
            sortable: true,
            show: true,
          },
          {
            key: 'einreichungsdaten',
            label: 'Einreichungs-Datum',
            sortable: true,
            show: true,
          },
          {
            key: 'enabled',
            label: 'aktiv',
            sortable: true,
            show: false,
          },
          {
            key: 'uhstat',
            label: 'UHStat1',
            sortable: false,
            show: false,
          },
          {
            key: 'AZ_SBG',
            label: 'AZ SGB',
            sortable: false,
            show: false,
          },
          {
            key: 'kaution',
            label: 'Kaution befreien',
            sortable: false,
            show: false,
          },
          {
            key: 'hobexTransactionId',
            label: 'Hobex Referenz',
            sortable: true,
            show: false,
          },
          {
            key: 'actions',
            label: 'Aktionen',
            sortable: false,
            class: 'text-right',
            show: true,
          },
        ],
        sortBy: 'username',
        sortDesc: false,
        keyword: null,
        /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
        pageOptions: [ 5, 10, 15, 20, 50, 100 ],
      }
    },
    computed: {

      myFields: function () {

        return this.fields.filter( item =>

          item.show === true

        )

      }

    },
    mounted: function () {

      this.init()

    },
    watch: {

      keyword: function () {
        this.reloadTable();
        this.countUser();
      }

    },
    methods: {

      /**
       * User sperren oder entsperren
       */
      switchEnabled: function (obj) {

        console.log('User sperren oder entsperren', obj)
        const url = process.env.VUE_APP_SERVER_URL + '/student/setEnabled'
        const params = {
          user_id: obj.id,
          enabled: obj.enabled,
        };
        axios({
          method: 'POST',
          url: url,
          params: params,
          withCredentials: true,
        }).then(response => {

          console.log('enabled gespeichert ' + response.data)

          let text  = 'Der Benutzer ' + obj.username + ' wurde erfolgreich gesperrt.'
          let titel = 'Benutzer sperren'
          if (obj.enabled) {
            text = 'Der Benutzer ' + obj.username + ' wurde erfolgreich aktiviert.'
            titel = 'Benutzer aktivieren'
          }

          this.$bvToast.toast(text, {
            title: titel,
            autoHideDelay: 5000
          })

        })

      },
      confirmDeleteStudent: function (obj) {

        const name = obj.nachname ? obj.nachname : ''
        const that = this
        const msg = '<div class="alert alert-danger" role="alert">Sind Sie sicher, dass Sie die Student*in <strong>' +
                    name +
                    '</strong> löschen wollen?<br/><small>Bitte beachten Sie, dass die Student*in nur aus dem ' +
                    'Bewerbungsportal gelöscht wird,<br/>in FHsys muss die Löschung ebenfalls durchgeführt werden.</small></div>'

        bootbox.confirm ({
            message: msg,
            buttons: {
              confirm: {
                label: 'Löschung durchführen',
                className: 'btn-danger'
              },
              cancel: {
                label: 'Abbrechen',
                className: 'btn-primary'
              }
            },
            callback: async result =>
            {

              if (result) {

                that.showSpinner()
                console.log('Lösche Student', obj)
                const url = process.env.VUE_APP_SERVER_URL + '/student/deleteUser?user_id=' + obj.id
                axios.get(url, {
                  withCredentials: true
                }).then(response => {

                  if (response.data.id) {

                    that.hideSpinner()
                    that.reloadTable()
                    bootbox.alert('Die Student*in wurde erfolgreich gelöscht. Bitte nicht vergessen die StudentIn aus FHsys zu entfernen.')

                  }

                }).catch(error => {
                  console.log(error)
                  that.hideSpinner()
                  alert('Es gab einen Fehler beim Löschen des Studenten. Bitte wenden Sie sich an einen System Administrator.')
                })

              } // end if result

            } // callback

        }) // bootbox.confirm

      },
      /**
       * Login durchführen - vorher nochmals Warnung abgeben
       */
      loginAs: function (obj) {

        const that = this;
        const msg = 'Achtung, Sie loggen sich als der ausgewählte Benutzer ein. Alle Aktionen die Sie durchführen, führen sie als dieser Benutzer aus und werden gespeichert. Sind Sie sicher, dass der Login durchgeführt werden soll?';
        bootbox.confirm(msg, async result => {

          if (result) {

            console.log('loginAs', obj);
            that.$store.commit('setLoggedInAs', obj);

            const url = process.env.VUE_APP_SERVER_URL + '/login/loginAs';
            const response = await axios({
              method: 'POST',
              url: url,
              params: {
                username: obj.username
              },
              withCredentials: true
            });
            if (response.data.id) {
              that.$router.push('Bewerbung');
            }

          }

        })

      },
      init: function () {

        this.countUser()

      },
      countUser: function () {

        const that = this
        // hole die Gesamtzahl der Studenten
        let url = process.env.VUE_APP_SERVER_URL + '/student/count'
        if (this.keyword) {
          url = url + '?keyword=' + this.keyword
        }
        axios.get(url, {
          withCredentials: true
        }).then(response => {

          that.totalRows = response.data.anzahl

        })

      },
      reloadTable: function () {
        this.$root.$emit('bv::refresh::table', 'studenten-table')
      },
      /** hole Benutzer */
      myProvider: function (ctx, callback) {

        this.showOverlay = true;
        let url      = process.env.VUE_APP_SERVER_URL + '/student/list';
        const offset = (this.currentPage - 1) * this.perPage;
        const sort   = this.sortDesc ? 'DESC' : 'ASC';
        url = url + '?offset=' + offset + '&sorter=' + this.sortBy + '&order=' + sort + '&max=' + this.perPage;
        if (this.keyword && this.keyword.length > 0) {
          url = url + '&keyword=' + this.keyword;
        }
        axios.get(url, {
          withCredentials: true
        }).then(response => {

          console.log('response ', response.data);
          // Pluck the array of items off our axios response
          const items = response.data.users;
          // Provide the array of items to the callback
          callback(items)
          this.showOverlay = false;

        });

      }

    }

  }
</script>
