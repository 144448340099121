<template>

  <span class="kautionWrapper" v-if="messages">

    <!-- Hinweis, dass Kaution gezahlt werden muss -->
    <span class="kautionWrapper" v-if="status == 'OFFEN' || status == 'IN_PRUEFUNG' || status == 'ABGELEHNT'">

      <hr class="mt-5 mb-5">
      <h3>{{ messages['fh.kautionszahlung.hl'] }}</h3>
      <b-alert variant="info" show class="mt-3">
        <span v-html="messages['fh.kautionszahlung.desc.2']"></span>
        <!--        <br/><br/>-->
        <!--        <small v-html="messages['fh.kautionszahlung.rueckerstattung']"></small>-->
      </b-alert>

      <span class="kautionWrapper" v-if="status == 'IN_PRUEFUNG' || status == 'ABGELEHNT'">

        <b-alert variant="warning" show v-if="hobexResponseMessage">
          <b-icon icon="exclamation-triangle"></b-icon>&nbsp;{{ hobexResponseMessage }}
        </b-alert>

      </span>

      <button type="button"
                    @click="start()"
                    class="button primary">
        <b-icon icon="credit-card"></b-icon>&nbsp;{{ messages['fh.kautionszahlung.start'] }}
      </button>

    </span>

    <span v-else-if="status == 'BEZAHLT'">

      <hr class="mt-5 mb-5">
      <h3 class="mb-3">{{ messages['fh.kautionszahlung.hl'] }}</h3>
      <b-alert variant="success" show>
        <b-icon icon="check-circle"></b-icon>&nbsp;{{ messages['fh.kautionszahlung.hobexResponseCode.SUCCESS'] }}
      </b-alert>

    </span>

    <span v-else-if="status == 'KAUTION_BEFREIT'">

      <hr class="mt-5 mb-5">
      <h3 class="mb-3">{{ messages['fh.kautionszahlung.hl'] }}</h3>
      <b-alert variant="success" show>
        {{ messages['fh.kautionszahlung.befreit'] }}
      </b-alert>

    </span>

    <!-- Hobex Payment Form -->
    <b-modal id="modal-payment-form" :title="messages['fh.kautionszahlung.hl']" hide-footer>
      <div class="my-4">
        <form :action="shopperResultUrl" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
      </div>
    </b-modal>

  </span>

</template>

<script>

  import axios from 'axios';
  import { EventBus } from '@/event-bus'
  axios.defaults.withCredentials = true;

  export default {

    name: 'kaution',
    props: ['value'], // value =
    data () {
      return {
        messages: null,
        /** Kaution Objekt zur Bewerbung */
        kaution: null,
        /** Payment Widget Form anzeigen */
        showPaymentWidget: false,
        /** redirect URI von Hobex nach erfolgreicher Zahlung */
        shopperResultUrl: null,
        /**
         * Status der Hobex Zahlung
         * 'OFFEN', 'KEINE_ZAHLUNG_NOETIG', 'BEZAHLT', 'IN_PRUEFUNG', 'KAUTION_BEFREIT'
         */
        status: null,
        showOverlay: true,
        /** Nachricht die angezeigt wird, falls es ein Hobex Problem gab */
        hobexResponseMessage: null,
        /** Headline bei Prüfung mit Hobex */
        cardTitle: null,
      }
    },
    mounted: function () {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    methods: {

      showPaymentForm: function () {

        if (this.kaution.endpoint) {

          const that = this;
          // Javascript anfügen:
          var head = document.getElementsByTagName('head')[0];
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = this.kaution.endpoint;
          head.appendChild(script);

          window.wpwlOptions = {
            locale: localStorage.language
          }

          script.onload = function () {
            console.log('show payment form')
            that.$bvModal.show('modal-payment-form')
          }

        } else {
          console.error('Kein Endpoint vorhanden um Hobex Form anzuzeigen', this.kaution)
        }

      },
      /**
       * los geht's, Kautionszahlung starten
       */
      start: function () {

        this.showSpinner()
        // hole die ID von Hobex:
        const that = this;
        const url = process.env.VUE_APP_SERVER_URL + '/kaution/hobexPrepareCheckout';
        const params = {
          bewerbung_id: this.getBewerbung(),
          lang: localStorage.language ? localStorage.language : 'de'
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then( response => {

          console.log('Kaution Objekt', response.data)
          if (response.data && response.data.hobexId) {
            that.kaution = response.data
            that.shopperResultUrl = response.data.shopperResultUrl
            that.showPaymentForm()
          } else {
            // Fehlermeldung an User:
          }
          that.hideSpinner()

        })

      },
      init: function () {

        const that = this;
        const url = process.env.VUE_APP_SERVER_URL + '/kaution/isKautionBezahlt';
        const params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then( response => {

          that.status = response.data.isKautionBezahlt
          if (
            response.data &&
            (
              response.data.isKautionBezahlt == 'KEINE_ZAHLUNG_NOETIG' ||
              response.data.isKautionBezahlt == 'BEZAHLT' ||
              response.data.isKautionBezahlt == 'KAUTION_BEFREIT'
            )
          ) {

            that.emitMe(true)

          } else {

            // nicht in Ordnung, zeige Kautionsfenster
            that.emitMe(false)

            // soll die Zahlung überprüft werden bei Hobex?
            if (response.data && response.data.isKautionBezahlt == 'IN_PRUEFUNG') {

              that.showOverlay = true
              that.getPaymentStatus()

            } else if (response.data && response.data.isKautionBezahlt == 'ABGELEHNT') {

              // die Prüfung wurde bereits durchgeführt und abgelehnt von Hobex
              that.cardTitle = that.messages['fh.kautionszahlung.wirdGeprueft.nichtErfolgreich']
              if (response.data.kaution && response.data.kaution.hobexResponseGroup) {
                that.hobexResponseMessage = that.messages['fh.kautionszahlung.hobexResponseCode.' + response.data.kaution.hobexResponseGroup.name]
              }
              that.showOverlay = false

            }

          }
          // auch im Menü den Text ändern
          if (
            response.data &&
            (
              response.data.isKautionBezahlt == 'KEINE_ZAHLUNG_NOETIG'
            )
          ) {

            that.$store.commit('setHasKaution', false)

          }

        })

      },
      /**
       * holt den Zahlungsstatus von Hobex
       */
      getPaymentStatus: function () {

        const that = this;
        const url = process.env.VUE_APP_SERVER_URL + '/kaution/getPaymentStatus';
        const params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then( response => {

          console.log('payment status', response.data)
          if (response.data && response.data.hobexResponseGroup && response.data.hobexResponseGroup.name == 'SUCCESS') {

            // alles OK, Hobex Fenster kann geschlossen werden:
            that.emitMe(true)

          } else if (response.data && response.data.hobexResponseGroup) {

            // Fehler von Hobex, zeige Fehlermeldung und nochmals den Hobex Start Button
            that.hobexResponseMessage = that.messages['fh.kautionszahlung.hobexResponseCode.' + response.data.hobexResponseGroup.name]
            that.emitMe(false)
            that.cardTitle = that.messages['fh.kautionszahlung.wirdGeprueft.nichtErfolgreich']
            that.status = 'ABGELEHNT'

          }
          that.showOverlay = false

        }).catch( error => {

          console.log('Fehler bei der Payment Prüfung', error)
          that.showOverlay = false
          that.hobexResponseMessage = that.messages['fh.kautionszahlung.hobexError']
          that.cardTitle = that.messages['fh.kautionszahlung.wirdGeprueft.nichtErfolgreich']
          that.status = 'ABGELEHNT'

        })

      },
      emitMe: function (val) {
        this.$emit('input', val);
      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        const messages = [
            'fh.kautionszahlung.desc.2',
            'fh.kautionszahlung.rueckerstattung',
            'fh.kautionszahlung.hl',
            'fh.kautionszahlung.start',
            'fh.kautionszahlung.hobexError',
            'fh.kautionszahlung.wirdGeprueft',
            'default.pleaseWait',
            'fh.kautionszahlung.wirdGeprueft.nichtErfolgreich',
            'fh.kautionszahlung.hobexResponseCode.SUCCESS',
            'fh.kautionszahlung.hobexResponseCode.REVIEW_MANUALLY',
            'fh.kautionszahlung.hobexResponseCode.PENDING',
            'fh.kautionszahlung.hobexResponseCode.SECURE_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.BANK_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.COMMUNICATION_ERROR',
            'fh.kautionszahlung.hobexResponseCode.SYSTEM_ERROR',
            'fh.kautionszahlung.hobexResponseCode.WORKFLOW_ERROR',
            'fh.kautionszahlung.hobexResponseCode.SOFT_DECLINES',
            'fh.kautionszahlung.hobexResponseCode.RISK_REJECTION_EXTERNAL',
            'fh.kautionszahlung.hobexResponseCode.ADDRESS_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.DREI_D_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.RISK_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.CONFIGURATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.REGISTRATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.JOB_VALIDATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.REFERENCE_VALIDATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.FORMAT_VALIDATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.ADDRESS_VALIDATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.CONTACT_VALIDATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.ACCOUNT_VALIDATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.AMOUNT_VALIDATION_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.RISK_MANAGEMENT_REJECTION',
            'fh.kautionszahlung.hobexResponseCode.CHARGEBACK',
            'fh.kautionszahlung.hobexResponseCode.BLACKLIST_REJECTION',
            'fh.kautionszahlung.befreit'
        ];
        const that = this;
        this.getMessages(language, messages, resp => {
          that.messages = resp;
          that.cardTitle = that.messages['fh.kautionszahlung.wirdGeprueft'];
          this.init();
        })

      }

    }

  }

</script>
