<template>

  <transition name="fade" mode="out-in">
    <form v-if="messages">

      <h1>{{ messages['fh.forgotPassword.h1'] }}</h1>
      <p>{{ messages['fh.forgotPassword.desc'] }}</p>

      <error-message :errormessage="errormessage" />

      <div class="form-group row">
        <div class="col-md-2">
          <label for="username">{{ messages['fh.login.username'] }}</label>
        </div>
        <div class="col-md-10">
          <input type="email"
                 maxlength="100"
                 class="form-control"
                 v-bind:class="{ 'is-invalid': !isUsernameValid }"
                 id="username"
                 name="username"
                 placeholder="Enter email"
                 v-model="username">
          <div class="invalid-feedback">
            {{ messages['fh.login.insertUsername'] }}
          </div>
        </div>
      </div>

      <captcha-form-row v-on:set-code="setCode" v-if="!successmessage" />

      <div class="form-group row" v-if="!successmessage">
        <div class="col-md-2"></div>
        <div class="col-md-10">
          <button type="button"
                  class="button secondary"
                  v-on:click="abbrechen">{{ messages['default.button.break'] }}</button>
          <button type="button"
                  class="button primary"
                  v-on:click="submitForm"
                  :disabled="!showSubmitButton">{{ messages['default.button.next.label'] }}</button>
        </div>
      </div>
      <info-message :message="successmessage" v-else />

    </form>
  </transition>

</template>

<script>

import axios from 'axios'
import ErrorMessage from '../layout/ErrorMessage.vue'
import InfoMessage from '../layout/InfoMessage.vue'
import CaptchaFormRow from './CaptchaFormRow.vue'
// Import the EventBus.
import { EventBus } from '../../event-bus.js';

export default {

  name: 'ForgotPassword',
  data () {
    return {
      username: null,
      messages: null,
      captcha: null,
      /** der vom Benutzer eingegebene Sicherheitscode des Captcha */
      code: null,
      /** Fehlermeldung nach dem Absenden der Passwort Anforderung */
      errormessage: null,
      /** Erfolgsmeldung wenn E-Mail erfolgreich gesendet wurde */
      successmessage: null
    }
  },
  components: {

    ErrorMessage,
    CaptchaFormRow,
    InfoMessage

  },
  computed: {

    /** prüft ob der Benutzername eingegeben wurde */
    isUsernameValid: function () {

      if (this.username && this.username !== '') {
        return this.validEmail(this.username)
      }

      return true;

    },
    /** kann der Weiter Button angezeigt werden? */
    showSubmitButton: function () {

      if (!this.code || this.code.length < 6) return false
      if (!this.isUsernameValid) return false
      return true

    }

  },
  mounted: function () {

    this.fetchMessages();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** Passwort vergessen abbrechen - zurück zum Login */
    abbrechen: function () {

      this.$router.push('/');

    },
    /** Captcha Code wurde eingegeben */
    setCode: function(val) {

      this.code = val;

    },
    submitForm: function () {

      this.showSpinner();
      var url    = process.env.VUE_APP_SERVER_URL + '/login/sendForgotPass';
      var params = {
        'username': this.username,
        'captcha':  this.code,
        'lang':     localStorage.language
      };

      var that = this;
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          that.hideSpinner();
          if (response.data.success) {
            that.successmessage = response.data.message;
          } else {
            that.errormessage = response.data.message;
          }

        })
        .catch(function (error) {
          that.hideSpinner();
          console.log(error);
        });

    },
    /** Passwort vergessen Formular öffnen */
    forgotPass: function () {

      this.$router.push('ForgotPassword');

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.forgotPassword.h1',
        'fh.forgotPassword.desc',
        'fh.login.insertUsername',
        'fh.login.username',
        'default.button.next.label',
        'fh.forgotPassword.title',
        'default.button.break'
      ];

      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.forgotPassword.title'];
      });

    }

  }

}
</script>
