<template>

  <div v-if="messages && studiengang && studiengang.kuerzel.startsWith('MMA')">

    <h3 class="mb-3" v-if="lang == 'de'">{{ studiengang.labelDe }}</h3>
    <h3 class="mb-3" v-else>{{ studiengang.labelEn }}</h3>

    <!-- Beschreibung des Uploads -->
    <div class="card mt-3 mb-3">

      <div class="card-header">
        {{ messages['fh.fileUpload.medienUpload.beschreibung'] }}
      </div>
      <!-- Text für Bachelor -->
      <div class="card-body"
           v-html="messages['fh.fileUpload.medienUpload.beschreibung.body']"
           v-if="studiengang.kuerzel == 'MMA-B'">
      </div>
      <!-- Text für Master -->
      <div class="card-body"
           v-html="messages['fh.fileUpload.medienUpload.beschreibung.body.master']"
           v-if="studiengang.kuerzel == 'MMA-M'">
      </div>

    </div>

    <!-- Studierende sollen zusätzlich einen Fachbereich auswählen -->
    <div class="form-group row">
      <div class="col-md-2">
        <label for="dokumenttyp" class="col-form-label">{{ messages['fh.fileUpload.medienUpload.fachbereich'] }}*</label>
      </div>
      <div class="col-md-10">
        <!-- Für MMA Bachelor: Audio (AU), Computeranimation (CA), Film (FI), Kommunikationsdesign (KD) -->
        <span v-if="studiengang.kuerzel == 'MMA-B'">
          <b-form-select v-model="fachbereich" :options="optionsBachelor" />
        </span>
        <!-- Für MMA Master: Audio (AU), Computeranimation (CA), Film (FI), Kommunikationsdesign (KD), Management (MG) -->
        <span v-else-if="studiengang.kuerzel == 'MMA-M'">
          <b-form-select v-model="fachbereich" :options="optionsMaster" />
        </span>
      </div>
    </div>

    <div class="form-group row" v-if="fachbereich">
      <div class="col">
        <file-upload v-on:set-file="setFile"
                     :is-large-file="true"
        />
      </div>
    </div>
    <div class="form-group row" v-if="fachbereich">
      <div class="col" v-if="isShowProgress && !isUploadSuccess">
        <h4 class="mb-1">{{ messages['fh.fileUpload.medienUpload.progress'] }}</h4>
        <b-alert show variant="warning">{{ messages['fh.fileUpload.medienUpload.uploadWarning'] }}</b-alert>
        <b-progress :max="100" animated>
          <b-progress-bar :value="progress" :label="`${progress} %`" />
        </b-progress>
      </div>
      <div class="col" v-if="uploadError">
        <b-alert show variant="danger">{{ uploadError }}</b-alert>
      </div>
      <div class="col" v-if="isUploadSuccess">
        <b-alert variant="success" show>{{ messages['fh.fileUpload.medienUpload.success'] }}</b-alert>
      </div>
    </div>

    <hr/>

  </div>

</template>
<style scoped>

  .progress {
    height: 2rem;
  }

</style>
<script>

  import axios from 'axios';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  axios.defaults.withCredentials = true;
  import HugeUploader from 'huge-uploader';
  import FileUpload from '@/components/upload/FileUpload';

  export default {

    name: 'medien-upload',
    components: {
      FileUpload
    },
    props: {
      /** Studiengang für den der Upload durchgeführt werden soll */
      studiengang: null,
      /** Aufnahmeverfahren um das Jahr für den File zu ermitteln */
      aufnahmeverfahren: null
    },
    data () {
      return {
        messages: null,
        optionsBachelor: null,
        optionsMaster: null,
        fachbereich: null,
        endPoint: null,
        /** Fortschritt beim Upload */
        progress: 0,
        /** Progress bar anzeigen oder nicht */
        isShowProgress: false,
        /** Upload war erfolgreich */
        isUploadSuccess: false,
        /** Upload hatte Fehler */
        uploadError: null,
        lang: null
      }
    },
    computed: {
    },
    mounted: function () {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      // Listen for the set-language event on EventBus
      this.lang = localStorage.language;
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.lang = lang;
        this.fetchMessages(lang);
      });
      this.endPoint = process.env.VUE_APP_SERVER_URL + '/file/uploadLargeFile';

    },
    watch: {

      fachbereich: function () {
        this.isUploadSuccess = false;
        this.isShowProgress  = false;
        this.progress        = 0;
      }

    },
    methods: {

      /** File über FileUpload ausgewählt */
      setFile: function (file) {

        console.log('File über FileUpload erhalten, zerlegen ...' + file.type);

        // prüfe ob zip:
        if (!file.type || (file.type != 'application/x-zip-compressed' && file.type != 'application/zip')) {
          this.uploadError = this.messages['fh.fileUpload.medienUpload.noZipError'];
          return;
        }

        this.uploadError = '';

        // passt die Filegröße?
        // Achtung Änderungen auch in application.yml setzen!
        if (file.size > 1073741824) {
          this.uploadError = this.messages['fh.fileUpload.medienUpload.maxSizeError'];
          return;
        }

        // zuerst mal alles zurücksetzen:
        this.progress = 0;
        this.isShowProgress = true;
        this.isUploadSuccess = false;

        let that = this;
        // hole aus Sicherheitsgründen den angemeldeten User und
        // übergebe ihn beim Upload
        this.findUser(function (user) {

          const bewerbung_id = that.getBewerbung();
          const uploadurl = that.endPoint + '?bewerbung_id=' + bewerbung_id + '&username=' +  user.username;
          let fileType = file.name.split('.').pop();

          // gleich mal mit 1% starten, damit Balken sichtbar wird:
          that.progress = 1.0;

          // post parameters that will be sent with the last chunk
          const params = {
            format:         fileType,
            type:           file.type,
            fachbereich:    that.fachbereich,
            jahr:           that.aufnahmeverfahren ? that.aufnahmeverfahren.jahr : '',
            filename:       file.name,
            studiengang_id: that.studiengang.id
          }

          const uploader = new HugeUploader({
            endpoint:
            uploadurl,
            chunkSize: 1,
            delayBeforeRetry: 1,
            file: file,
            postParams: params
          });

          uploader.on('progress', (progress) => {

            console.log('progressing: ', progress.detail, '%');
            that.progress = progress.detail;
            that.isShowProgress = true;
            that.uploadError = '';

          });

          uploader.on('finish', (body) => {

            console.log('yeahhh - last response body!', body);
            that.isShowProgress = false;
            that.progress = 0;
            that.isUploadSuccess = true;
            that.uploadError = '';
            that.$emit('set-file', true);

          });

          uploader.on('error', (err) => {

            console.error('Something bad happened', err.detail);
            that.isShowProgress = false;
            if (err.detail.indexOf('413')) {
              that.uploadError = that.messages['fh.fileUpload.medienUpload.maxSizeError'];
            } else {
              that.uploadError = that.messages['fh.fileUpload.medienUpload.error'];
            }

          });

        });

      },
      /** init aufrufen wenn messages verfügbar */
      init: function () {

        this.optionsBachelor = [
          { value: null, text: this.messages['default.pleaseSelect'], disabled: true },
          { value: 'AU', text: this.messages['fh.fileUpload.medienUpload.fachbereich.AU'] },
          { value: 'CA', text: this.messages['fh.fileUpload.medienUpload.fachbereich.CA'] },
          { value: 'FI', text: this.messages['fh.fileUpload.medienUpload.fachbereich.FI'] },
          { value: 'KD', text: this.messages['fh.fileUpload.medienUpload.fachbereich.KD'] },
        ]
        this.optionsMaster = [
          { value: null, text: this.messages['default.pleaseSelect'], disabled: true },
          { value: 'AU', text: this.messages['fh.fileUpload.medienUpload.fachbereich.AU'] },
          { value: 'CD', text: this.messages['fh.fileUpload.medienUpload.fachbereich.CD'] },
          { value: 'FI', text: this.messages['fh.fileUpload.medienUpload.fachbereich.FI'] },
          { value: 'PD', text: this.messages['fh.fileUpload.medienUpload.fachbereich.PD'] },
        ]

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        const messages = [
          'fh.fileUpload.medienUpload.fachbereich',
          'fh.fileUpload.medienUpload.fachbereich.AU',
          'fh.fileUpload.medienUpload.fachbereich.FI',
          'fh.fileUpload.medienUpload.fachbereich.KD',
          'fh.fileUpload.medienUpload.fachbereich.CA',
          'fh.fileUpload.medienUpload.fachbereich.CD',
          'fh.fileUpload.medienUpload.fachbereich.PD',
          'default.pleaseSelect',
          'fh.fileUpload.medienUpload.maxSizeError',
          'fh.fileUpload.medienUpload.error',
          'fh.fileUpload.medienUpload.progress',
          'fh.fileUpload.medienUpload.success',
          'fh.fileUpload.medienUpload.uploadWarning',
          'fh.fileUpload.medienUpload.beschreibung',
          'fh.fileUpload.medienUpload.beschreibung.body',
          'fh.fileUpload.medienUpload.beschreibung.body.master',
          'fh.fileUpload.medienUpload.noZipError'
        ];
        let that = this;
        this.getMessages(language, messages, resp => {
          that.messages = resp;
          that.init();
        });

      }

    }

  }

</script>
