<template>

  <div class="form-group row" v-if="messages">
    <div class="col-md-2">
      <label for="captcha">{{messages['fh.forgotPassword.captcha.label']}}</label>
    </div>
    <div class="col-md-2">
      <img v-if="captcha" :src="captcha" :alt="messages['fh.forgotPassword.captcha.alt']" />
    </div>
    <div class="col-md-8">
      <input type="text"
             class="form-control"
             id="captcha"
             name="captcha"
             :placeholder="messages['fh.forgotPassword.captcha.label']"
             v-model="code"
      />
    </div>
  </div>

</template>

<script>

// Import the EventBus.
import { EventBus } from '../../event-bus.js';
import axios from 'axios';
axios.defaults.withCredentials = true;

export default {

  name: 'captcha-form-row',
  data () {
    return {
      messages: null,
      captcha: null,
      /** der vom Benutzer eingegebene Sicherheitscode des Captcha */
      code: null
    }
  },
  watch: {
    /** eingegebenen Code an Parent zurückgeben */
    code: function (val) {

      this.$emit('set-code', val);

    }
  },
  mounted: function () {

    this.fetchMessages();
    this.getCaptcha();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** holt das Captcha Image */
    getCaptcha: function () {

      var url    = process.env.VUE_APP_SERVER_URL + '/mySimpleCaptcha/captcha';
      var that   = this;
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {
          that.captcha = 'data:image/gif;base64,' + response.data.base64;
        })
        .catch(function (error) {
          console.log(error);
        });

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.forgotPassword.captcha.label',
        'fh.forgotPassword.captcha.alt'
      ];

      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
      });

    }

  }

}

</script>
