<template>

  <form name="datenschutzForm" id="datenschutzForm">
    <transition name="fade" mode="out-in">
      <div v-if="messages && bewerbergruppe">

        <check-login></check-login>
        <nav-tabs :active="'Datenschutz'" :trigger-check="triggerCheck" />

        <h2 id="headline" v-html="messages['fh.nav.datenschutz']"></h2>

        <div v-if="!studiengaenge || !studiengaenge.hauptStudiengang">
          <hr class="mt-5 mb-5">
          <!-- Fehlermeldung ausgeben falls noch keine Studiengänge gewählt wurden -->
          <error-message :errormessage="messages['fh.studiengang.select']"></error-message>
        </div>
        <div v-else>

          <!-- ist Kaution nötig? -->
          <div class="kautionNoetigWrapper">

            <!--
            Kautionszahlung durchführen, falls es sich um einen nicht EU Bürger handelt,
            gilt nicht für AO oder Incoming
            -->
            <kaution v-if="bewerbergruppe == 'STUDENT'" v-model="isKautionBezahlt" />

          </div>

          <!--
          <hr class="mt-5 mb-5">
          -->

          <!-- UHSTAT 1 für reguläre Studenten und AO Höhrer
          <span v-if="bewerbergruppe === 'STUDENT' || bewerbergruppe === 'AUSSERORDENTLICHER_HOERER'">
            <uhstat v-model="isUhstatAusgefuellt" />
          </span>
          -->

          <hr class="mt-5 mb-5">

          <div v-if="anzahlDoppelteHauptbewerbungen && anzahlDoppelteHauptbewerbungen > 1" class="alert alert-danger mt-3" role="alert">
            {{ messages['fh.datenschutz.mehrereHauptbewerbungen'].replace('{0}', anzahlDoppelteHauptbewerbungen) }}
          </div>
          <div v-else-if="anzahlDoppelteHauptbewerbungen < 2 && (isBewerbungsfristOk || (bewerbungsfristenAbgelaufen && bewerbungsfristenAbgelaufen.isHauptAufnahmeverfahrenEnded === false))">

              <!-- Datenschutzerklärung anzeigen -->
              <p>
                <button class="button primary"
                        type="button"
                        v-on:click="toggleDatenschutz">
                  <span v-if="showDatenschutz">{{ messages['fh.datenschutz.ausblenden'] }}</span>
                  <span v-else>{{ messages['fh.datenschutz.anzeigen'] }}</span>
                </button>
              </p>
              <transition name="fade" mode="out-in">
                <div id="collapseDatenschutz" v-if="showDatenschutz">
                  <div class="card card-body mt-3">
                    <datenschutz-text />
                  </div>
                </div>
              </transition>

              <hr class="mt-5 mb-5">

              <p v-html="messages['fh.datenschutz.bestaetigung.absatz_1']"></p>
              <p v-html="messages['fh.datenschutz.bestaetigung.absatz_2']" class="mt-2"></p>

              <hr class="mt-5 mb-5">

              <!-- Checkbox ob Angaben korrekt sind -->
              <div class="form-group row">
                <div class="col-md-12">
                  <div class="custom-control custom-checkbox" style="margin:0">
                    <input type="checkbox"
                           class="custom-control-input"
                           id="angabenChecked"
                           :disabled="abgeschlossen && angabenChecked"
                           v-model="angabenChecked"
                           v-bind:class="{ 'is-invalid': !angabenChecked }">
                    <label class="custom-control-label" for="angabenChecked">{{ messages['fh.datenschutz.bestaetigung'] }}</label>
                  </div>
                </div>
              </div>

              <hr class="mt-5 mb-5">

              <!-- Bewerbung wurde erfolgreich versendet -->
              <div class="alert alert-success mt-3" role="alert" v-if="successMessage">
                {{ successMessage }}
              </div>

              <!-- Fehler beim Senden der Bewerbung -->
              <div class="alert alert-danger mt-3" role="alert" v-if="isError">
                {{ errorMessage }}
              </div>

              <div v-if="bewerbungsfristenAbgelaufen && bewerbungsfristenAbgelaufen.endedNebenstudiengaenge">
                <!-- Text falls Bewerbungsfrist abgelaufen ist -->
                <!-- Nebenbewerbungen sind abgelaufen -->
                <div class="alert alert-danger mt-3" role="alert" v-if="bewerbungsfristenAbgelaufen.endedNebenstudiengaenge">
                  {{ bewerbungsfristenAbgelaufenText }}
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-10 offset-col-md-2">
                  <button type="button"
                          v-if="!abgeschlossen && clicked < 1"
                          v-on:dblclick="clicked += 1, checkForm()"
                          v-on:click="clicked += 1, checkForm()"
                          class="button primary"
                          :disabled="!formValid">
                    {{ messages['fh.datenschutz.abschliessen'] }}
                  </button>
                </div>
              </div>

          </div>
          <div v-else-if="bewerbungsfristenAbgelaufen && bewerbungsfristenAbgelaufen.isHauptAufnahmeverfahrenEnded">
            <!-- Text falls Bewerbungsfrist abgelaufen ist -->
            <!-- Hauptbewerbung ist abgelaufen -->
            <div class="alert alert-danger mt-3" role="alert">
              {{ hauptbewerbungAbgelaufenText }}
            </div>
          </div>

        </div>

      </div>
    </transition>
  </form>

</template>

<script>

import axios from 'axios';
import NavTabs from './layout/NavTabs.vue';
import CheckLogin from './login/CheckLogin.vue';
import DatenschutzText from './datenschutz/DatenschutzText';
import { EventBus } from '../event-bus.js';
import bootbox from 'bootbox'
import Kaution from '@/components/bewerbung/kaution.vue';
import ErrorMessage from "@/components/layout/ErrorMessage.vue";
// import uhstat from '@/components/uhstat/uhstat.vue';
axios.defaults.withCredentials = true;

export default {

  name: 'Datenschutz',
  components: {
    ErrorMessage,
    DatenschutzText,
    NavTabs,
    CheckLogin,
    Kaution,
    //uhstat
  },
  data () {
    return {
      messages: null,
      showDatenschutz: false,
      angabenChecked: false,
      /** Bewerbung wurde erfolgreich versendet */
      successMessage: null,
      /** Fehler beim Versenden der Bewerbung */
      isError: false,
      /** Fehlermeldung nach Versenden der Bewerbung */
      errorMessage: null,
      /** das Datenschutz Objekt */
      datenschutz: null,
      /** prüft ob alle Tabs valide sind */
      isAllTabsValid: false,
      abgeschlossen: null,
      /** Überprüfung der Button Stati triggern */
      triggerCheck: 1,
      /** Datum wann abgeschlossen */
      abgeschlossenDatum: null,
      /** true wenn alle Bewerbungen sich innerhalb der Bewerbungsfrist befinden */
      isBewerbungsfristOk: false,
      /** Objekt mit Daten zur Abgelaufenen Bewerbungsfristen */
      bewerbungsfristenAbgelaufen: null,
      /** Anzahl der doppelten Hauptbewerbungen wenn doppelte Hauptbewerbungen existieren */
      anzahlDoppelteHauptbewerbungen: null,
      /** um Doppelklicks zu verhinder zählen wie oft geklickt wurde */
      clicked: 0,
      /** wird auf true gesetzt, wenn die Kaution bezahlt wurde oder keine Kaution nötig ist */
      isKautionBezahlt: false,
      bewerbergruppe: null,
      /** wird auf true gesetzt von uhstat Komponente, wenn UHSTAT ausgefüllt wurde */
      // isUhstatAusgefuellt: null
      /** Nationalität des Studenten zur Prüfung auf Kaution */
      nationalitaet: null,
      student: null,
      /** ausgewählte Studiengänge der Bewerbung */
      studiengaenge: null,
    }
  },
  mounted: function () {

    this.init();

  },
  watch: {

    /** wenn die Checkbox aktiviert/deaktiviert wird, gleich beim User speichern */
    angabenChecked: function () {

      const url = process.env.VUE_APP_SERVER_URL + '/datenschutz/datenBestaetigt';
      const params = {
        bestaetigt: this.angabenChecked,
        bewerbung_id: this.getBewerbung()
      };
      axios({
        method: 'post',
        url: url,
        params: params,
        withCredentials: true
      }).then(function (daten) {
          console.log('Datenbestätigung gespeichert ' + daten);
      })

    }

  },
  computed: {

    /** Text wenn Nebenbewerbung abgelaufen ist */
    bewerbungsfristenAbgelaufenText: function () {

      if (this.messages && this.bewerbungsfristenAbgelaufen && this.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge) {

        var text = this.messages['fh.datenschutz.nebenbewerbungAbgelaufen'];
        text     = text.replace('{1}', this.bewerbungsfristenAbgelaufen.hauptAufnahmeverfahren.label);

        var nebenbewerbungen = '';
        for (var i = 0; i < this.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge.length; i++) {
          if (i > 0) nebenbewerbungen += ', '
          nebenbewerbungen += this.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge[i].label;
        }

        return text.replace('{0}', nebenbewerbungen);

      }

      return ''

    },
    /** Text wenn die Hauptbewerbung abgelaufen ist */
    hauptbewerbungAbgelaufenText: function () {

      if (this.messages && this.messages['fh.datenschutz.hauptbewerbungAbgelaufen'] && this.bewerbungsfristenAbgelaufen && this.bewerbungsfristenAbgelaufen.hauptAufnahmeverfahrenEnded) {

        var text = this.messages['fh.datenschutz.hauptbewerbungAbgelaufen'];
        return text.replace('{0}', this.bewerbungsfristenAbgelaufen.hauptAufnahmeverfahrenEnded.label);

      }

      return ''

    },
    /**
     * prüfe ob die Formulardaten korrekt erfasst wurden
     */
    formValid: function () {

      // Kaution muss bezahlt sein oder nicht nötig sein
      if (this.bewerbergruppe == 'STUDENT' && !this.isKautionBezahlt) {
        return false
      }

      // Checkbox muss angehackelt sein
      if (!this.angabenChecked) {
        return false;
      }

      // alle Tabs müssen ausgefüllt worden sein
      if (!this.isAllTabsValid) return false;

      /*
      if (this.bewerbergruppe === 'STUDENT' || this.bewerbergruppe === 'AUSSERORDENTLICHER_HOERER') {
        if (!this.isUhstatAusgefuellt) return false;
      }
      */

      return true;

    }

  },
  methods: {

    /** Datenschutzerklärung toggeln */
    toggleDatenschutz: function () {

      this.showDatenschutz = !this.showDatenschutz;

    },
    init: function () {

      const that = this;
      this.getBewerbergruppe(function (result) {

        that.bewerbergruppe = result;
        that.fetchMessages();

      });

      this.checkSubmit();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });
      // Listen for the set-language event on EventBus
      EventBus.$on('set-all-tabs-valid', valid => {
        this.isAllTabsValid = valid;
      });

      // prüfe ob Bewerbung bereits abgeschickt wurde
      this.isAbgeschlossen(res => {
        that.abgeschlossen = res;
      });

      // Prüfe ob sich der Student an mehreren Hauptbewerbungen beworben hat
      this.anzahlDoppelteHauptbewerbungen = null;
      var url  = process.env.VUE_APP_SERVER_URL + '/datenschutz/getHauptbewerbungen';
      var params = {
        bewerbung_id: this.getBewerbung(),
        isNew: true
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

        if (response.data) {
          that.anzahlDoppelteHauptbewerbungen = response.data.length;
        }

      })

      // Prüfe ob alle Bewerbungen sich innerhalb der Bewerbungsfrist befinden
      this.isBewerbungsfristOk = false;
      that.bewerbungsfristenAbgelaufen = null;
      url  = process.env.VUE_APP_SERVER_URL + '/datenschutz/isBewerbungsfristenOk';
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          if (response.data && response.data.success) {
            that.isBewerbungsfristOk = true;
          } else {

            console.log('Problem mit Bewerbungsfristen ' + response.data);
            that.bewerbungsfristenAbgelaufen = response.data;

            // lösche komplette Bewerbung wenn Hauptbewerbung abgelaufen
            if (that.bewerbungsfristenAbgelaufen.isHauptAufnahmeverfahrenEnded) {
              that.deleteBewerbung();
            }

            // lösche Nebenbewerbungen wenn abgelaufen
            if (that.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge && that.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge.length > 0) {
              for (var i = 0; i < that.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge.length; i++) {
                that.deleteNebenbewerbung(that.bewerbungsfristenAbgelaufen.endedNebenstudiengaenge[i].id);
              }
            }

          }

      })

      // hole Studiengänge des Bewerbers:
      url    = process.env.VUE_APP_SERVER_URL + '/studiengang/findStudiengaengeOfStudent'
      params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(response => {

        that.studiengaenge = response.data

      })

      url = process.env.VUE_APP_SERVER_URL + '/student'
      axios.get(url, {
        withCredentials: true,
        params: params
      }).then(response => {

        console.log('Studentendaten für Kautionsprüfung setzen', response.data)
        if (response.data.student) {

          that.nationalitaet = response.data.nationalitaet
          that.student = response.data.student

        }

      })

    },
    /** eine Nebenbewerbung löschen */
    deleteNebenbewerbung: function (id) {

      var url  = process.env.VUE_APP_SERVER_URL + '/student/deleteNebenBewerbung';
      var params = {
        id: id,
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

        if (response.data && response.data.id) {
          console.log('Neben-Bewerbung erfolgreich gelöscht.');
        }

      })

    },
    /** lösche die Bewerbung */
    deleteBewerbung: function () {

      var url  = process.env.VUE_APP_SERVER_URL + '/student/deleteBewerbung';
      var params = {
        id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

        if (response.data && response.data.id) {
          console.log('Bewerbung erfolgreich gelöscht.');
        }

      })

    },
    /** prüft ob die Bewerbung bereits versendet worden ist */
    checkSubmit: function () {

      var url  = process.env.VUE_APP_SERVER_URL + '/datenschutz/check';
      var that = this;
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          if (response.data && response.data.bewerbung && response.data.error) {

            // zumindest die Checkbox richtig anzeigen, auch wenn Fehler
            if (response.data.datenschutz.datenBestaetigt) {
              that.angabenChecked = true;
            }

          } else if (response.data && response.data.bewerbung) {

            console.log('bereits abgeschlossen am ', response.data.bewerbung);
            that.datenschutz = response.data.datenschutz;
            that.abgeschlossenDatum = response.data.bewerbung.abgeschlossen;
            if (that.abgeschlossenDatum) {
              that.showSuccess()
            }
            if (that.datenschutz.datenBestaetigt) {
              that.angabenChecked = true;
            }

          } else {
            console.log('Noch nicht übermittelt ' + response.data);
          }

        })

    },
    /** Formulardaten prüfen und Form absenden */
    checkForm: function () {

      console.log('Bewerbung abschicken ...');

      if (!this.formValid) {
        console.log('Form Validierung scheitert')
        return false
      }

      // e.preventDefault();
      // sicherstellen, dass Formular nur 1x abgesendet wird
      if (this.clicked > 1) {
        console.log('Formular bereits unterwegs ...');
        return;
      }

      var bewerbungId = this.getBewerbung();
      var that = this;
      this.showSpinner();
      let url = process.env.VUE_APP_SERVER_URL + '/datenschutz/submitBewerbung';
      const params = {
        bewerbung_id: bewerbungId
      }
      axios({
        method: 'post',
        url: url,
        params: params,
        withCredentials: true
      }).then(function (daten) {

          console.log('bewerbung absenden response ', daten);

          that.hideSpinner();
          if (daten && daten.data && daten.data.messageDe && daten.data.error) {

            that.clicked = that.clicked - 1;
            if (localStorage.language == 'de') {
              bootbox.alert(daten.data.messageDe);
            } else {
              bootbox.alert(daten.data.messageEn);
            }

          } else if (daten && daten.data && daten.data.id) {

            console.log('Bewerbung erfolgreich versendet ' + daten.data.id);
            that.datenschutz = daten.data;

            // ok, nun dürfen aber keine Änderungen mehr durchgeführt werden
            var params = {
              bewerbung_id: bewerbungId
            }
            url  = process.env.VUE_APP_SERVER_URL + '/datenschutz/isAbgeschlossen';
            axios.get(url, {
                params: params,
                withCredentials: true
            }).then(function (response) {

                  console.log('Bewerbung ist abgeschlossen: ' + response.data.isAbgeschlossen);
                  if (response.data && response.data.isAbgeschlossen) {
                      sessionStorage.isAbgeschlossen = JSON.stringify(response.data);
                      that.abgeschlossenDatum = response.data.isAbgeschlossen;
                      that.showSuccess();
                  }

            });

          } else {

            that.clicked = that.clicked - 1;
            that.showError(daten.data);

          }

      }).catch(error => {
        console.log(error);
        that.hideSpinner();
        that.showError();
        that.clicked = that.clicked - 1;
      });

    },
    /** Fehlermeldung anzeigen, es gab ein Problem bei der Datenübermittlung */
    showError: function (data) {

      if (data && data.studiengang) {

        // Studiengang existiert nicht mehr:
        this.errorMessage = this.messages['fh.datenschutz.abschliessen.error.studiengang'];
        var email = data.studiengang.officeEmail ? data.studiengang.officeEmail : process.env.VUE_APP_OFFICE_MAIL;
        if (localStorage.language === 'de') {
          this.errorMessage = this.errorMessage.replace('{0}', email);
        } else {
          this.errorMessage = this.errorMessage.replace('{0}', email);
        }

        // prüfe die Studiengangswahl erneut, damit diese "rot" angezeigt wird:
        this.triggerCheck = this.triggerCheck + 1;

      } else {

        // allgemeine Fehlermeldung
        this.errorMessage = this.messages['fh.datenschutz.abschliessen.error'];

      }

      this.isError        = true;
      this.successMessage = null;

    },
    /** Erfolgsmeldung der Datenübermittlung anzeigen */
    showSuccess: function () {

      console.log('showSuccess', this.datenschutz.abgeschlossen)
      // 2019-06-11T10:07:49Z
      var msg = this.messages['fh.datenschutz.abschliessen.success']
      var datetime = this.javaDateToTime(this.abgeschlossenDatum)
      this.successMessage = msg.replace('{0}', datetime)
      // Button entfernen damit nicht nochmals abgeschickt wird:
      this.abgeschlossen = this.abgeschlossenDatum
      this.isError = false

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      const messages = [
        'fh.nav.datenschutz',
        'fh.datenschutz.abschliessen',
        'fh.datenschutz.anzeigen',
        'fh.datenschutz.bestaetigung.absatz_1',
        'fh.datenschutz.bestaetigung.absatz_2',
        'fh.datenschutz.bestaetigung',
        'fh.datenschutz.bestaetigung.insert',
        'fh.datenschutz.abschliessen.error',
        'fh.datenschutz.abschliessen.success',
        'fh.nav.datenschutz.title',
        'fh.datenschutz.abschliessen.error.studiengang',
        'fh.datenschutz.ausblenden',
        'fh.datenschutz.hauptbewerbungAbgelaufen',
        'fh.datenschutz.nebenbewerbungAbgelaufen',
        'fh.datenschutz.mehrereHauptbewerbungen',
        'fh.studiengang.select'
      ]
      const that = this
      this.getMessages(language, messages, function(resp) {
        that.messages = resp
        // Seiten Titel setzen:
        document.title = that.messages['fh.nav.datenschutz.title']
      })

    }

  }

}
</script>
