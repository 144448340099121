<template>

  <transition name="fade" mode="out-in">

    <section class="content-module loginpage" v-if="messages">

      <div class="content-module-wrapper content-area">

        <div class="content-header">
          <section class="page-title narrow ">
            <div class="row">
              <div class="col-lg-12">
                <div class="page-title-wrapper">
                  <h1>
                    {{ messages['fh.h1'] }}
                  </h1>
                </div>
              </div>
            </div>
          </section>
        </div>

        <h2>{{ messages['fh.login.h1'] }}</h2>
        <p>{{ messages['fh.login.description'] }}</p>

        <form @submit="checkLogin" name="loginform" id="loginform">

          <error-message :errormessage="errormessage" />
          <info-message :message="message" />

          <username-form-row v-on:set-username="setUsername"></username-form-row>

          <div class="form-group row">
            <div class="col-md-2">
              <label for="password">{{messages['fh.login.password']}}*</label>
            </div>
            <div class="col-md-10">
              <input type="password"
                     maxlength="100"
                     class="form-control"
                     v-bind:class="{ 'is-invalid': !isPasswordValid }"
                     id="password"
                     name="password"
                     placeholder="Password"
                     required
                     v-model="password">
              <div class="invalid-feedback">
                {{ messages['fh.login.insertPassword'] }}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-2"></div>
            <div class="col-md-10">
              <button type="submit"
                      class="button primary"
                      v-on:click="checkLogin"
                      :disabled="!showLoginButton">{{ messages['fh.login.login'] }}</button>&nbsp;
              <button type="button"
                      class="button secondary"
                      v-on:click="forgotPass">{{ messages['fh.login.forgotPass'] }}</button>
            </div>
          </div>

        </form>

        <hr class="mt-5 mb-6"/>

        <h2>{{ messages['fh.register.h1'] }}</h2>
        <p>{{ messages['fh.register.notRegistered'] }}</p>
        <button type="button"
                class="button secondary"
                v-on:click="toRegister">{{ messages['default.button.next.label'] }}</button>

      </div>

    </section>
  </transition>

</template>
<script>

import axios from 'axios';
import ErrorMessage from '../layout/ErrorMessage.vue'
import InfoMessage from '../layout/InfoMessage.vue'
import UsernameFormRow from './UsernameFormRow.vue'
// Import the EventBus.
import { EventBus } from '../../event-bus.js';
// import Language from "@/components/layout/Language.vue";
axios.defaults.withCredentials = true;

export default {

  name: 'Login',
  props: {
    /** eine Erfolgsmeldung */
    message: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      password: null,
      username: null,
      messages: null,
      errormessage:null
    }
  },
  components: {
    // Language,
    ErrorMessage,
    InfoMessage,
    UsernameFormRow
  },
  computed: {

    /** prüft ob das Passwort eingegeben wurde */
    isPasswordValid: function () {

      if (this.password && this.password !== '') {
        return this.checkPasswordRules(this.password);
      }

      return true;

    },
    /** prüft ob der Login Button angezeigt werden kann */
    showLoginButton: function () {

      if (!this.isPasswordValid) return false;
      if (!this.password || !this.username || this.password === '' || this.username === '') return false;
      return true;

    }

  },
  mounted: function () {

    this.fetchMessages();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** Registrierungs Seite öffnen */
    toRegister: function () {
      this.$router.push('Register');
    },
    /** Username von Parent gesetzt */
    setUsername: function (val) {
      this.username = val;
    },
    /** Passwort vergessen Formular öffnen */
    forgotPass: function () {

      this.$router.push('ForgotPassword');

    },
    /** Passwort per AJAX prüfen */
    checkLogin: function (e) {

      e.preventDefault();
      console.log('check login ...');

      // lösche die Bewerbung aus dem session storage
      this.setBewerbung(null);

      this.showSpinner();
      var url    = process.env.VUE_APP_SERVER_URL + '/login/checkPass';
      var params = {
        'username': this.username,
        'password': this.password
      };

      var that = this;
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

        sessionStorage.isAbgeschlossen = null;
        sessionStorage.bewerbergruppe = null;

        that.hideSpinner();
        console.log('Login erfolgreich', response.data);
        if (response.data && response.data.success) {

          // speichere den eingeloggten User in Vuex
          that.$store.commit('setUser', response.data);

          // einen Admin auf die Liste der FH User routen
          if (response.data.role == 'ROLE_ADMIN') {

            that.$router.push('FhUser');

          } else if (response.data.role == 'ROLE_FH') {

            // einen FH User auf die Liste der Studenten routen
            that.$router.push('Studenten');

          } else {

            if (!response.data.bewerbungen || response.data.bewerbungen.length < 1) {

              // Bewerbung anlegen und weiterleiten:
              that.showSpinner();
              // neue Bewerbung in der Datenbank anlegen
              url = process.env.VUE_APP_SERVER_URL + '/student/createBewerbung';
              axios.get(url, {
                withCredentials: true
              }).then(function (response) {

                if (response.data && response.data.id) {
                  console.log('Bewerbung erfolgreich angelegt.');
                  that.setBewerbung(response.data.id);
                  // weiterleiten zu den Userdaten:
                  that.$router.push('UserData');
                }
                that.hideSpinner();

              });

            } else {
              that.$router.push('Bewerbung');
            }

          }

        } else {
          that.showLoginError(response.data ? response.data.message : null);
        }

      })
        .catch(function (error) {

          that.hideSpinner();
          console.log(error);
          that.showLoginError();

        });

    },
    /** Login Fehlermeldung anzeigen */
    showLoginError: function (message) {

      this.errormessage = message ? message : this.messages['fh.login.error']

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.login.password',
        'fh.login.insertPassword',
        'fh.login.login',
        'fh.login.forgotPass',
        'fh.login.error',
        'fh.register.h1',
        'default.button.next.label',
        'fh.register.notRegistered',
        'fh.login.title',
        'fh.login.h1',
        'fh.login.description',
        'fh.h1'
      ];
      var that = this;
      this.getMessages(language, messages, function (resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.login.title'];
      });

    }

  }

}
</script>
