<template>

  <span class="navAndAbgeschlossenWrapper">

    <transition name="fade" mode="out-in">
      <nav id="nav" v-if="messages">

        <div class="row">
          <div class="col-md-6" v-if="user">

             <b-alert variant="warning" show v-if="$store.getters.loggedInAs">
               {{ $store.getters.user ? $store.getters.user.username : '' }} {{ messages['fh.register.eingeloggtAls'] }} <strong>{{ user.username }}</strong>
             </b-alert>
             <span v-else>
              {{ messages['fh.register.eingeloggtAls'] }}: {{ user.username }}
             </span>

          </div>
          <div class="col-md-6 text-right">

            <a href="#" class="button secondary black btn-sm" v-on:click="dologout">{{ messages['fh.logout'] }}</a>

          </div>
        </div>
        <!-- Navigation für Messages, Vertragszustimmung und Home -->
        <div class="row mb-3 mt-3" v-if="active === 'Messages' || active === 'Bewerbung' || active === 'Vertragszustimmung'">

          <div class="col-sm-8 col-md-4">

            <ul class="nav nav-pills flex-column flex-sm-row horizontal-style">
              <li :class="'nav-item ' + (active === 'Bewerbung' ? 'active ' : '')"
                  :title="messages['fh.nav.bewerbung']">
                <router-link to="/bewerbung"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-2 d-none d-lg-block"><font-awesome-icon icon="home" /></div>
                      <div class="col-md-10 col-sm-12" v-html="messages['fh.nav.bewerbung']"></div>
                    </div>
                </router-link>
              </li>
              <li :class="'nav-item ' + (active === 'Messages' ? 'active ' : '') + messageAvailable"
                  :title="messages['fh.messages.h1']">
                <router-link to="/messages"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-2 d-none d-lg-block"><font-awesome-icon icon="mail-bulk" /></div>
                      <div class="col-md-9 col-sm-12" v-html="messages['fh.messages.h1']"></div>
                    </div>
                </router-link>
              </li>
            </ul>

          </div>

        </div>
        <!-- Navigation innerhalb des Bewerbungsvorganges -->
        <div class="row mb-3" v-if="bewerbergruppe && active !== 'Bewerbung' && active !== 'Messages' && active !== 'Vertragszustimmung'">

          <div class="col-md-12">

            <ul class="nav nav-pills flex-column flex-sm-row horizontal-style">
              <li class="home-icon"
                  :class="'nav-item ' + (active === 'Bewerbung' ? 'active ' : '')"
                  :title="messages['fh.nav.bewerbung'].replace('&shy;', '')"
                  v-b-tooltip.hover>
                <router-link to="/bewerbung"
                             :class="'nav-link '"
                             href="#">
                    <span>
                      <font-awesome-icon icon="home" />
                    </span>
                </router-link>
              </li>
              <li class="home-icon-text"
                  :class="'nav-item ' + (active === 'Bewerbung' ? 'active ' : '')">
                <router-link to="/bewerbung"
                             :class="'nav-link '"
                             href="#">
                    <span :title="messages['fh.nav.bewerbung'].replace('&shy;', '')"
                          v-b-tooltip.hover>
                      <font-awesome-icon icon="home" />&nbsp;&nbsp;<span v-html="messages['fh.nav.bewerbung']"></span>
                    </span>
                </router-link>
              </li>
              <li :class="'nav-item ' + (active === 'UserData' ? 'active ' : '') + studentValid"
                  :title="studentValid == 'red' ? messages['fh.nav.datenUnvollstaendig'] : messages['fh.nav.datenVollstaendig']">
                <router-link to="/userData"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-10" v-html="messages['fh.nav.persoenlicheDaten']"></div>
                      <div class="col-md-2 d-none d-lg-block text-right"><font-awesome-icon icon="arrow-right" /></div>
                    </div>
                </router-link>
              </li>
              <li :class="'nav-item ' + (active === 'Studiengang' ? 'active ' : '') + studiengangValid" v-if="studentExists && bewerbergruppe && bewerbergruppe === 'STUDENT'"
                  :title="studiengangValid == 'red' ? messages['fh.nav.datenUnvollstaendig'] : messages['fh.nav.datenVollstaendig']">
                <router-link to="/studiengang"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-10" v-html="messages['fh.nav.studiengangswahl']"></div>
                      <div class="col-md-2 d-none d-lg-block text-right"><font-awesome-icon icon="arrow-right" /></div>
                    </div>
                </router-link>
              </li>
              <!-- Vorbildung, nicht für Incoming -->
              <li :class="'nav-item ' + (active === 'Reife' ? 'active ' : '') + reifeValid"
                  :title="reifeValid == 'red' ? messages['fh.nav.datenUnvollstaendig'] : messages['fh.nav.datenVollstaendig']"
                  v-if="studentExists && bewerbergruppe && bewerbergruppe !== 'INCOMING'">
                <router-link to="/reife"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-10" v-html="messages['fh.nav.universitaetsreife']"></div>
                      <div class="col-md-2 d-none d-lg-block text-right"><font-awesome-icon icon="arrow-right" /></div>
                    </div>
                </router-link>
              </li>
              <li :class="'nav-item ' + (active === 'Upload' ? 'active ' : '') + uploadValid" v-if="studentExists"
                  :title="uploadValid == 'red' ? messages['fh.nav.datenUnvollstaendig'] : messages['fh.nav.datenVollstaendig']">
                <router-link to="/upload"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-10" v-html="messages['fh.nav.dateiupload']"></div>
                      <div class="col-md-2 d-none d-lg-block text-right"><font-awesome-icon icon="arrow-right" /></div>
                    </div>
                </router-link>
              </li>
              <!-- Medien Upload für MMA -->
              <li :class="'nav-item ' + (active === 'UploadMedien' ? 'active ' : '') + mmaValid" v-if="isMMA"
                  :title="mmaValid == 'red' ? messages['fh.nav.datenUnvollstaendig'] : messages['fh.nav.datenVollstaendig']">
                <router-link to="/uploadMedien"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-10" v-html="messages['fh.fileUpload.medienUpload.headline']"></div>
                      <div class="col-md-2 d-none d-lg-block text-right"><font-awesome-icon icon="arrow-right" /></div>
                    </div>
                </router-link>
              </li>
              <!-- Umfrage nur für Studenten
              <li :class="'nav-item ' + (active === 'Umfrage' ? 'active ' : '') + umfrageValid"
                  v-if="studentExists  && bewerbergruppe && bewerbergruppe === 'STUDENT'"
                  :title="umfrageValid == 'red' ? messages['fh.nav.datenUnvollstaendig'] : messages['fh.nav.datenVollstaendig']">
                <router-link to="/umfrage"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-9" v-html="messages['fh.nav.umfrage']"></div>
                      <div class="col-md-3 d-none d-lg-block text-right"><font-awesome-icon icon="arrow-right" /></div>
                    </div>
                </router-link>
              </li>
              -->
              <li :class="'nav-item ' + (active === 'Datenschutz' ? 'active ' : '') + datenschutzValid"
                  v-if="studentExists"
                  :title="datenschutzValid == 'red' ? messages['fh.nav.datenUnvollstaendig'] : messages['fh.nav.datenVollstaendig']">
                <router-link to="/datenschutz"
                             :class="'nav-link '"
                             href="#">
                    <div class="row">
                      <div class="col-md-10"
                           v-html="$store.getters.hasKaution ? messages['fh.nav.datenschutzUndKaution'] : messages['fh.nav.datenschutz']">
                      </div>
                      <div class="col-md-2 d-none d-lg-block text-right"><font-awesome-icon icon="share-square" /></div>
                    </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>

      </nav>
    </transition>

    <span v-if="bewerbergruppe && active !== 'Bewerbung' && active !== 'Messages' && active !== 'Vertragszustimmung'">
      <is-abgeschlossen-warning />
    </span>

  </span>

</template>
<style scoped>

  @media (min-width: 1024px) {
    #li-bewerbung {
      width: 1em
    }
  }

</style>
<script>

import axios from 'axios';
import IsAbgeschlossenWarning from './IsAbgeschlossenWarning.vue';
// Import the EventBus.
import { EventBus } from '../../event-bus.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
library.add(faArrowRight, faShareSquare, faHome, faMailBulk);
axios.defaults.withCredentials = true;

export default {

  name: 'NavTabs',
  props: {
    active: {
      type: String,
      required: false
    },
    student: {
      type: Object,
      required: false
    },
    /** Überprüfung der Buttons triggern */
    triggerCheck: {
      type: Number,
      required: false
    }
  },
  components: {
    IsAbgeschlossenWarning
  },
  data () {
    return {
      messages: null,
      /** alle Nav Tabs erst anzeigen wenn sich ein Benutzer vollständig registeriet hat */
      studentExists: null,
      /** eingeloggter Benutzer */
      user: null,
      /** style des Datenschutz Tabs */
      datenschutzValid: '',
      /** style des Upload Tabs */
      uploadValid: '',
      /** style des Umfrage Tabs */
      umfrageValid: '',
      /** style des UserData Tabs */
      studentValid: '',
      /** style des Universitätsreife Tabs */
      reifeValid: '',
      /** style des Studiengang Tabs */
      studiengangValid: '',
      /** style wenn noch nicht vollständig ausgefüllt */
      red:   'red', // 'color: #000000; background-color: #ffffff;',
      /** style wenn vollständig ausgefüllt */
      green: 'green', // 'color: #ffffff; background-color: #323232;',
      /** der Reife Menüpunkt wird ausgeblendet bei Incoming ohne Masterstudium */
      showReife: false,
      /** Tabs erst anzeigen wenn eine Bewerbergruppe gewählt wurde */
      bewerbergruppe: null,
      /** offene Nachrichten verfügbar */
      messageAvailable: '',
      /** Bewerber hat MMA als Studiengang */
      isMMA: null,
      /** MMA Files wurden hochgeladen */
      mmaValid: ''
    }
  },
  watch: {

    /** prüfe ob alle Tabs valide sind, wenn ja dann sende dies über den EventBus */
    datenschutzValid: function () {
      this.isAllValid();
    },
    uploadValid: function () {
      this.isAllValid();
    },
    umfrageValid: function () {
      this.isAllValid();
    },
    studentValid: function () {
      this.isAllValid();
    },
    reifeValid: function () {
      this.isAllValid();
    },
    studiengangValid: function () {
      this.isAllValid();
    },
    triggerCheck: function () {
      this.checkTabs();
    }

  },
  mounted: function () {

    this.fetchMessages();
    if (this.student) {
      this.studentExists = this.student;
    } else {
      this.checkStudent();
    }
    this.getUser();
    this.checkTabs();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });
    EventBus.$on('set-bewerbergruppe', val => {
        if (val && val !== 'null') {
            this.setBewerbergruppe(val);
        }
    });
    var that = this;
    this.getBewerbergruppe(function(result) {
        if (result && result !== 'null') {
          that.bewerbergruppe = result;
        }
    });

  },
  methods: {

    /** Bewerbergruppe wurde gesetzt, zeige Tabs */
    setBewerbergruppe: function (obj) {

        this.bewerbergruppe = obj;

        // nochmals prüfen, da neue Bewerbergruppe:
        this.checkTabs();

    },
    /** abmelden und zurück zur Login Seite */
    dologout: function () {

      this.logout()

    },
    /** wenn alle Tabs valid sind, dann emit auf den Event Bus dass alles ok ist */
    isAllValid: function () {

      if (
        // Upload kann auch später erfolgen
        // this.uploadValid === this.green &&
        // Umfrage derzeit deaktiviert bis was neues kommt, 4.3.24
        // this.umfrageValid === this.green &&
        this.studentValid === this.green &&
        this.reifeValid === this.green &&
        this.studiengangValid === this.green
      ) {
        EventBus.$emit('set-all-tabs-valid', true);
      } else {
        EventBus.$emit('set-all-tabs-valid', false);
      }

    },
    /** prüft welcher Tab rot angezeigt werden soll */
    checkTabs: function () {

      var that = this;
      // sind Nachrichten verfügbar?
      if (this.active === 'Bewerbung') {

        var url  = process.env.VUE_APP_SERVER_URL + '/messages/hasUserUnreadMessages';
        var params = {}
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then(function (response) {

          if (response.data && response.data.anzahl) {
            that.messageAvailable = that.red;
          } else {
            console.log('Keine Nachrichten vorhanden ' + response.data.anzahl);
            that.messageAvailable = '';
          }

        });

      }

      // prüfe Datenschutz:
      var url    = process.env.VUE_APP_SERVER_URL + '/datenschutz/check';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          console.log('datenschutz: ', response);

          if (response.data && response.data.error) {
            that.datenschutzValid = that.active === 'Datenschutz' ? '' : that.red;
          } else {
            that.datenschutzValid = that.active === 'Datenschutz' ? '' : that.green;
          }

      })

      // prüfe Dateiupload:
      url  = process.env.VUE_APP_SERVER_URL + '/file/check';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          if (response.data && response.data.error === true) {
            that.uploadValid = that.active === 'Upload' ? '' : that.red;
          } else {
            that.uploadValid = that.active === 'Upload' ? '' : that.green;
          }

      });

      // prüfe Umfrage:
      url  = process.env.VUE_APP_SERVER_URL + '/umfrage/check';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          if (response.data && response.data.error === true) {
            that.umfrageValid = that.active === 'Umfrage' ? '' : that.red;
          } else {
            that.umfrageValid = that.active === 'Umfrage' ? '' : that.green;
          }
      });

      // prüfe Universitaetsreife:
      url  = process.env.VUE_APP_SERVER_URL + '/universitaetsreife/check';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          if (response.data && response.data.error === true) {
            that.reifeValid = that.active === 'Reife' ? '' : that.red;
          } else {
            that.reifeValid = that.active === 'Reife' ? '' : that.green;
          }

      });

      // prüfe Student:
      url  = process.env.VUE_APP_SERVER_URL + '/student/check';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          if (response.data && response.data.error === true) {
            that.studentValid = that.active === 'UserData' ? '' : that.red;
          } else {
            that.studentValid = that.active === 'UserData' ? '' : that.green;
          }

      });

      // prüfe Studiengangswahl:
      url        = process.env.VUE_APP_SERVER_URL + '/studiengangswahl/check';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          if (response.data && response.data.error === true) {
            that.studiengangValid = that.active === 'Studiengang' ? '' : that.red;
          } else {
            that.studiengangValid = that.active === 'Studiengang' ? '' : that.green;
          }

      });

      // prüfe Medien Upload:
      url        = process.env.VUE_APP_SERVER_URL + '/studiengang/checkMMA';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

        if (response.data && (response.data.isHauptStudiengangMMA || response.data.isNebenStudiengangMMA)) {
          that.isMMA    = true;
          that.mmaValid = that.active === 'UploadMedien' ? '' : that.red;
          if (response.data.isMMAUploaded) {
            that.mmaValid = that.active === 'UploadMedien' ? '' : that.green;
          }
        } else {
          that.isMMA = false;
        }

      });

    },
    /** holt den angemeldeten User */
    getUser: function () {

      var that = this;
      this.findUser(function (user) {
        that.user = user;
        EventBus.$emit('set-user', user);
      })

    },
    /** prüft ob es zum User schon einen Studenten gibt */
    checkStudent: function () {

      var url    = process.env.VUE_APP_SERVER_URL + '/main/checkStudent';
      var params = {
        bewerbung_id: this.getBewerbung()
      }

      var that = this;
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          if (response.data && !response.data.student) {
            that.studentExists = null;
          } else {
            that.studentExists = response.data.student;
          }

      });

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.nav.studiengangswahl',
        'fh.nav.persoenlicheDaten',
        'fh.nav.universitaetsreife',
        'fh.nav.umfrage',
        'fh.nav.dateiupload',
        'fh.nav.datenschutz',
        'fh.register.eingeloggtAls',
        'fh.login',
        'fh.logout',
        'fh.nav.datenVollstaendig',
        'fh.nav.datenUnvollstaendig',
        'fh.nav.bewerbung',
        'fh.messages.h1',
        'fh.fileUpload.medienUpload.headline',
        'fh.nav.datenschutzUndKaution'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
      });
    }

  }

}
</script>
<style scoped>

  .home-icon {
    width: 1%;
  }
  @media (max-width: 934px) {
    .home-icon {
      width: 7%;
    }
  }
  .home-icon-text {
    display: none;
  }
  @media (max-width: 767px) {
    .home-icon {
      display: none;
    }
    .home-icon-text {
      display: block;
      width: auto;
    }
  }

</style>
