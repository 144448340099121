// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuex from 'vuex';
import { store } from './store/store';
import App from './App.vue';
import router from './router';
import moment from 'moment';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// scss/custom.scss SASS Übersetzung:
import './assets/css/fh-style.css';
import './assets/css/custom.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'bootstrap';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

Vue.use(Vuex);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
axios.defaults.withCredentials = true;

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

// Jquery global verwenden:
window.$      = require('jquery');
window.JQuery = require('jquery');

Vue.config.productionTip = false;

/** Globale Variablen und Funktionen */
Vue.mixin({
  methods: {

    /**
     * falls die Bildschirmhöhe klein ist, wird der Footer
     * fixiert am Bottom angezeigt, sonst fließt er mit dem Text
     */
    fixedFooter: function () {

      $('#footerDatenschutzWrapper').show('slow');

    },
    hasScrollbar: function () {

      // The Modern solution
      if (typeof window.innerWidth === 'number') {
        return window.innerWidth > document.documentElement.clientWidth;
      }

      // rootElem for quirksmode
      var rootElem = document.documentElement || document.body;

      // Check overflow style property on body for fauxscrollbars
      var overflowStyle;

      if (typeof rootElem.currentStyle !== 'undefined') {
        overflowStyle = rootElem.currentStyle.overflow;
      }

      overflowStyle = overflowStyle || window.getComputedStyle(rootElem, '').overflow;

      // Also need to check the Y axis overflow
      var overflowYStyle;

      if (typeof rootElem.currentStyle !== 'undefined') {
        overflowYStyle = rootElem.currentStyle.overflowY;
      }

      overflowYStyle = overflowYStyle || window.getComputedStyle(rootElem, '').overflowY;

      var contentOverflows = rootElem.scrollHeight > rootElem.clientHeight;
      var overflowShown    = /^(visible|auto)$/.test(overflowStyle) || /^(visible|auto)$/.test(overflowYStyle);
      var alwaysShowScroll = overflowStyle === 'scroll' || overflowYStyle === 'scroll';

      return (contentOverflows && overflowShown) || (alwaysShowScroll);

    },
    /** holt den angemeldeten User */
    findUser: function (callback) {

      var url    = process.env.VUE_APP_SERVER_URL + '/main/getUser';
      var params = {};

      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          // console.log('res ' + response.data);
          if (response && response.data.error) {
            callback(null);
          } else {
            callback(response.data);
          }

        });

    },
    /** abmelden und zurück zur Login Seite */
    logout: async function (doLogoutAdmin) {

      const url = process.env.VUE_APP_SERVER_URL + '/main/logout';
      const that = this;
      const response = await axios.get (
          url,
          {
            withCredentials: true,
            params: {
              doLogoutAdmin: doLogoutAdmin
            }
          }
      )

      console.log('Abmeldung erfolgreich.');

      // lösche aus Vuex
      that.$store.commit('setUser', null);
      that.$store.commit('setLoggedInAs', null);
      sessionStorage.isAbgeschlossen = null;
      sessionStorage.bewerbergruppe = null;

      // wenn vorher ein Admin eingeloggt war,
      // dann kommt hier wieder die User id zurück,
      // dann route auf die Benutzerliste
      if (response.data && response.data.id) {

        that.$router.push('Studenten');

      } else {

        // zurück zur Login Seite
        that.$router.push('/');

      }

    },
    /** Prüft ob ein Masterstudium ausgewählt wurde */
    checkIfMasterstudium: function (callback) {

      // var that = this;
      var url    = process.env.VUE_APP_SERVER_URL + '/studiengangswahl/isMasterstudium';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          if (response.data && response.data.isMasterstudium) {
            callback(true);
          } else {
            callback(false);
          }

        });

    },
    /**
     * prüft ob die Bewerbung bereits versendet wurde
     * @param callback
     */
    isAbgeschlossen: function (callback) {

      if (sessionStorage.isAbgeschlossen && sessionStorage.isAbgeschlossen !== 'null') {
        var json = JSON.parse(sessionStorage.isAbgeschlossen);
        callback(json);
        return;
      }

      var url  = process.env.VUE_APP_SERVER_URL + '/datenschutz/isAbgeschlossen';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          console.log('Bewerbung ist abgeschlossen seit: ' + response.data.isAbgeschlossen);
          if (response.data && response.data.isAbgeschlossen) {
            sessionStorage.isAbgeschlossen = JSON.stringify(response.data);
            callback(response.data);
          } else {
            callback(null);
          }

        });

    },
    /** Bewerbung-ID speichern im Session Storage */
    setBewerbung: function (bewerbungId) {

      sessionStorage.bewerbung = bewerbungId;

    },
    /** holt die aktuelle Bewerbungs-ID */
    getBewerbung: function () {

      if (sessionStorage.bewerbung) {
        return sessionStorage.bewerbung;
      }
      return null;

    },
    /**
     * holt die Bewerbergruppe aus dem sessionStorage oder vom Server des Studenten
     */
    getBewerbergruppe: function (callback) {

      if (sessionStorage.bewerbergruppe) {
        callback(sessionStorage.bewerbergruppe);
        return;
      }

      // var that   = this;
      var url    = process.env.VUE_APP_SERVER_URL + '/student';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          if (response.data && response.data.bewerbergruppe) {
            sessionStorage.bewerbergruppe = response.data.bewerbergruppe;
            callback(response.data.bewerbergruppe);
          } else {
            callback(null);
          }

        });

    },
    getEnvironment: function (callback) {

      if (sessionStorage.env) {
        callback(sessionStorage.env);
        return;
      }

      var url    = process.env.VUE_APP_SERVER_URL + '/main/fetchVersion';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          sessionStorage.version = response.data.version;
          sessionStorage.env     = response.data.env;
          callback(response.data.env);

        });

    },
    /** holt die App Versionsnummer */
    getVersion: function (callback) {

      if (sessionStorage.version) {
        callback(sessionStorage.version);
        return;
      }

      var url    = process.env.VUE_APP_SERVER_URL + '/main/fetchVersion';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          sessionStorage.version = response.data.version;
          sessionStorage.env     = response.data.env;
          callback(response.data.version);

        });

    },
    /** holt die i18n Messages vom Server */
    getMessages: function (language, messages, callback) {

      var url    = process.env.VUE_APP_SERVER_URL + '/main/fetchMessages';
      var params = { messages: messages.toString() };
      var lang   = language && language != null ? language : localStorage.language;
      if (lang) {
        params.lang = lang;
      }
      params.bewerbung_id = this.getBewerbung()

      var that = this;
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          callback(response.data);
          that.fixedFooter();

        });

    },
    /**
     * aus einem Java Datum einen Zeit String
     * @param javadate
     * @returns {string}
     */
    javaDateToTime: function (javadate) {

      return this.javaDateToMoment(javadate).format('YYYY-MM-DD HH:mm');

    },
    /**
     * aus einem Java Datum ein HTML5 Datums String machen
     * @param javadate
     * @returns {string}
     */
    javaDateToHtml5Date: function (javadate) {

      return this.javaDateToMoment(javadate).format('DD.MM.YYYY');

    },
    /**
     * aus einem Java Date String ein Moment machen
     * @param javadate
     */
    javaDateToMoment: function (javadate) {

      return moment(javadate, 'YYYY-MM-DDTHH:mm:ssZ');

    },
    /** überprüft eine E-Mail-Adresse */
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email)
    },
    /** Passwortrichtlinie prüfen */
    checkPasswordRules: function (password) {

      if (!password) return false;

      if (password.length < 8) {
        return false
      }

      /** Regular Expressions mit enthaltenen Zeichen in einem Passwort */
      var regex = [ '[A-Z]', '[a-z]', '[0-9]', '[$@$!%*#?&]' ];

      // prüfe Passwort Richtlinien:
      var passed = 0;
      // Validate for each Regular Expression.
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          console.log(regex[i] + ' ist ok')
          passed++
        }
      }

      if (passed < 4) {
        return false
      }

      return true

    },
    /** oeffnet den Loading Spinner */
    showSpinner: function () {

      showSpinner();

    },
    /** schließt den Loading Spinner */
    hideSpinner: function () {

      hideSpinner();

    },
    isInt: function (value) {
      return !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10));
    }

  }

});

function hideSpinner () {

  $('#spinner').modal('hide');

  if (($('#spinner').data('bs.modal') || {isShown: false})._isShown) {
    setTimeout(function () {
      // Do Something Here
      // Then recall the parent function to
      // create a recursive loop.
      hideSpinner();
    }, 1000);
  }

}
function showSpinner () {
  $('#spinner').modal('show');
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
});

router.beforeEach((to, from, next) => {
  $('#footerDatenschutzWrapper').hide();
  next();
});
