<template>

  <span id="loginPromptWrapper">

    <div class="checkloginPrompt">
    </div>

    <!-- Modal -->
    <div class="modal fade" id="loginPromptModal" tabindex="-1" role="dialog" aria-labelledby="loginPromptModalLabel" aria-hidden="true" v-if="messages">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="loginPromptModalLabel">{{ messages['fh.login.auto'] }}</h5>
            <button type="button" class="closeme" data-dismiss="modal" :aria-label="messages['default.button.close.label']">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit="checkLogin">
            <div class="modal-body">

              <input class="form-control"
                     maxlength="100"
                     autocomplete="off"
                     type="password"
                     v-model="password"
              />

            </div>

            <div class="modal-footer">
              <button type="button" class="button primary" v-on:click="checkLogin">{{ messages['default.button.submit.label'] }}</button>
            </div>

          </form>
        </div>
      </div>
    </div>

  </span>

</template>

<script>

import axios from 'axios';
// Import the EventBus.
import { EventBus } from '../../event-bus.js';
axios.defaults.withCredentials = true;

export default {

  name: 'CheckLogin',
  components: {
  },
  props: {
    /** eine Erfolgsmeldung */
    message: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      username: null,
      interval: null,
      messages: null,
      password: null
    }
  },
  mounted: function() {

    this.fetchMessages();
    console.log(this.$route.path);
    this.getUser(true);
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** Passwort eingeben */
    showLoginPrompt: function () {

      var routePath = this.$route.path;
      if (routePath === '/' || routePath === '\\' || routePath === '/Register') {

        // schließe das Modal falls offen
        $('#loginPromptModal').modal('hide');

        this.clearInterval();
        return false;

      }
      console.log('Show Login Prompt on ' + routePath);
      if (!($('#loginPromptModal').data('bs.modal') || {})._isShown) {
          $('#loginPromptModal').modal('show');
      }

    },
    /** Prüft alle 5 Sekunden ob der Benutzer noch eingeloggt ist */
    startInterval: function () {

      if (!this.interval) {
        console.log('Interval starten ...');
        var that = this;
        this.interval = setInterval(function() {
          that.getUser(false);
        }, 5000);
      }

    },
    clearInterval: function () {

      clearInterval(this.interval);
      this.inverval = null;

    },
    /** Passwort per AJAX prüfen */
    checkLogin: function (e) {

      e.preventDefault();
      this.showSpinner();
      var url    = process.env.VUE_APP_SERVER_URL + '/login/checkPass';
      var params = {
        'username': this.username,
        'password': this.password
      };

      var that = this;
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          that.hideSpinner();
          if (response.data.success) {

            // schließe das Modal Fensterl
            $('#loginPromptModal').modal('hide');
            that.startInterval();

          } else {
            // zurück zur Login Seite:
            $('#loginPromptModal').modal('hide');
            that.$router.push('/');
          }

        })
        .catch(function (error) {

          that.hideSpinner();
          console.log('error: ' + error);
          // zurück zur Login Seite:
          $('#loginPromptModal').modal('hide');
          that.$router.push('/');

        });

    },
    /** holt den angemeldeten User oder zeigt den Login Prompt */
    getUser: function (redirectToLogin) {

      var url    = process.env.VUE_APP_SERVER_URL + '/main/getUser';
      var params = {};

      var that = this;
      axios.get(url, {
        params:params,
        withCredentials: true
      })
        .then(function (response) {

          // console.log("res "+ response.data);
          if (response && response.data.error) {

            if (redirectToLogin) {

              // zurück zur Login Seite:
              $('#loginPromptModal').modal('hide');
              that.$router.push('/');
              that.clearInterval();

            } else {

              that.clearInterval();
              that.showLoginPrompt();

            }
          } else {

            that.username = response.data.username;
            if (!that.interval) {
              that.startInterval();
            }

          }

        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.clearInterval();
          that.showLoginPrompt();
        });

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.login.auto',
        'default.button.close.label',
        'default.button.submit.label'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
      });

    }

  }

}
</script>
