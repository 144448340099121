<template>

  <transition name="fade" mode="out-in">
    <form v-if="messages">

      <h1>{{messages['fh.newPassword.h1']}}</h1>
      <p>{{messages['fh.newPassword.desc']}}</p>

      <error-message :errormessage="errormessage"></error-message>
      <error-message :errormessage="messages['fh.newPassword.tokenError']" v-if="username === null"></error-message>

      <div v-if="username !== true">

        <new-password-form-row v-on:set-password="setPassword"></new-password-form-row>

        <captcha-form-row v-on:set-code="setCode"></captcha-form-row>

        <div class="form-group row">
          <div class="col-md-2"></div>
          <div class="col-md-10">
            <button type="button"
                    class="button primary"
                    v-on:click="submitForm"
                    :disabled="!showSubmitButton">{{messages['default.button.submit.label']}}</button>
          </div>
        </div>

      </div>

    </form>
  </transition>

</template>

<script>

import axios from 'axios'
import ErrorMessage from '../layout/ErrorMessage.vue'
import CaptchaFormRow from './CaptchaFormRow.vue'
import NewPasswordFormRow from './NewPasswordFormRow.vue'
// Import the EventBus.
import { EventBus } from '../../event-bus.js';

export default {

  name: 'ForgotPassword',
  data () {
    return {
      username: null,
      messages: null,
      captcha: null,
      /** der vom Benutzer eingegebene Sicherheitscode des Captcha */
      code: null,
      /** Fehlermeldung nach dem Absenden der Passwort Anforderung */
      errormessage: null,
      /** Token über GET */
      token: null,
      password: null
    }
  },
  components: {

    ErrorMessage,
    CaptchaFormRow,
    NewPasswordFormRow

  },
  computed: {

    /** kann der Weiter Button angezeigt werden? */
    showSubmitButton: function() {

      if (!this.code || this.code.length < 6) return false;
      if (!this.isPasswordValid) return false;
      return true;

    },
    /** prüft ob das Passwort eingegeben wurde */
    isPasswordValid: function() {

      if (this.password && this.password !== '') {
        return true;
      }

      return false;

    }

  },
  mounted: function() {

    this.fetchMessages();
    /** Token aus GET holen und prüfen */
    this.checkToken();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** Passwort wurde verifiziert und zurückgegeben */
    setPassword: function(val) {

      this.password = val;

    },
    /** Captcha Code wurde eingegeben */
    setCode: function(val) {

      this.code = val;

    },
    /** Token aus GET holen und prüfen */
    checkToken: function() {

      this.token = this.$route.params.token;
      if (this.token && this.token !== '') {

        var url  = process.env.VUE_APP_SERVER_URL + '/login/checkToken';
        var that = this;
        axios.get(url, {
          params: {token: this.token},
          withCredentials: true
        })
          .then(function (response) {
            if (response.data.success) {
              that.username = response.data.username;
            } else {
              that.errormessage = response.data.message;
              that.username = null;
            }
          })
          .catch(function (error) {
            console.log(error);
            that.username = null;
          });

      } else {
        this.username = null;
      }

    },
    submitForm: function() {

      this.showSpinner();
      var url    = process.env.VUE_APP_SERVER_URL + '/login/changePass';
      var params = {
        'username': this.username,
        'captcha':  this.code,
        'password': this.password,
        'token': this.token
      };

      var that = this;
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          that.hideSpinner();
          if (response.data.success) {

            console.log('Passwort wurde geändert');
            that.errormessage = null;
            var message = response.data.message;
            that.$router.push({
              name: 'Login',
              params: {
                message: message
              }
            })

          } else {
            that.errormessage = response.data.message;
          }

        })
        .catch(function (error) {
          that.hideSpinner();
          console.log(error);
        });

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      var messages = [
        'fh.newPassword.h1',
        'fh.newPassword.desc',
        'default.button.submit.label',
        'fh.login.password.repeat',
        'fh.newPassword.tokenError',
        'fh.newPassword.title'
      ];

      var params = {messages: messages.toString()};
      var lang   = language || localStorage.language;
      if (lang) {
        params.lang = lang;
      }

      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.newPassword.title'];
      });

    }

  }

}
</script>
