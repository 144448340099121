<template>

  <span class="kautionsStatusWrapper">

      <span v-if="status == 'BEZAHLT'" v-b-tooltip.hover :title="title">
        <b-icon icon="cash-coin" style="color: green"></b-icon>
      </span>
      <span v-else-if="status == 'KEINE_ZAHLUNG_NOETIG'" v-b-tooltip.hover :title="title">
        <b-icon icon="check" style="color: green"></b-icon>
      </span>
      <span v-else-if="status == 'OFFEN'" v-b-tooltip.hover :title="title" style="color: red">
        <b-icon icon="exclamation-circle" style="color: red"></b-icon>
      </span>
      <span v-else-if="status == 'ABGELEHNT'" v-b-tooltip.hover :title="title" style="color: red">
        <b-icon icon="exclamation-circle" style="color: red"></b-icon>
      </span>
      <span v-else>
        {{ status }}
      </span>

  </span>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true

  export default {

    name: 'kautions-status',
    props: [ 'bewerbung', 'bewerbungen' ],
    data () {
      return {
        /**
         * Status der Hobex Zahlung
         * 'OFFEN', 'KEINE_ZAHLUNG_NOETIG', 'BEZAHLT', 'IN_PRUEFUNG'
         */
        status: null,
        title: null,
      }
    },
    mounted: function () {
      this.init()
    },
    methods: {

      init: function () {

        const that = this;
        const url = process.env.VUE_APP_SERVER_URL + '/kaution/isKautionBezahlt';
        const params = {
          bewerbung_id: this.bewerbung.id
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        }).then( response => {

          that.status = response.data.isKautionBezahlt
          const datum = that.bewerbung.abgeschlossen ? that.javaDateToHtml5Date(that.bewerbung.abgeschlossen) : null
          // tooltip setzen
          if (that.bewerbungen.length > 1 && datum) {

            if (that.status == 'BEZAHLT') {
              that.title = 'AZ SGB der Bewerbung vom ' + datum + ' bezahlt.'
            } else if (that.status == 'KEINE_ZAHLUNG_NOETIG') {
              that.title = 'AZ SGB der Bewerbung vom ' + datum + ' nicht erforderlich.'
            } else if (that.status == 'OFFEN') {
              that.title = 'AZ SGB der Bewerbung vom ' + datum + ' nicht bezahlt.'
            } else if (that.status == 'ABGELEHNT') {
              that.title = 'AZ SGB der Bewerbung vom ' + datum + ' abgelehnt.'
            }

          } else {

            if (that.status == 'BEZAHLT') {
              that.title = 'AZ SGB bezahlt.'
            } else if (that.status == 'KEINE_ZAHLUNG_NOETIG') {
              that.title = 'AZ SGB nicht erforderlich.'
            } else if (that.status == 'OFFEN') {
              that.title = 'AZ SGB nicht bezahlt.'
            } else if (that.status == 'ABGELEHNT') {
              that.title = 'AZ SGB abgelehnt.'
            }

          }

        })

      }

    }

  }

</script>
