<template>

  <div class="selectAbschlussWrapper" v-show="options">

    <select v-model="selected"
            class="custom-select"
            v-bind:class="{ 'is-invalid': !isValid }"
            :disabled="readonly">
      <option
        v-for="option in options"
        v-bind:value="option"
        v-bind:key="option.id">
        {{ option.name }}
      </option>
    </select>
    <div class="invalid-feedback" v-if="invalidMessage">
      {{invalidMessage}}
    </div>

  </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;

  export default {

    name: 'select-abschluss',
    props: {
      abschluss: {
        type: Object,
        required: false
      },
      invalidMessage: {
        type: String,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        /** mögliche Abschlüsse */
        options: null,
        /** vom User ausgewählter Abschluss */
        selected: null
      }
    },
    mounted: function () {

      this.init();
      if (this.abschluss && this.abschluss.id) {
        this.selected = this.abschluss;
      }

    },
    watch: {

      /** Abschluss wurde von aussen übergeben */
      abschluss: function (val) {
        this.selected = val;
      },
      /** Abschluss wurde vom Benutzer ausgewählt */
      selected: function (val) {
        this.emit(val);
      }

    },
    computed: {

      /** prüft ob der Abschluss ausgewählt wurde */
      isValid: function () {

        if (!this.selected || !this.selected.id) {
          return false;
        }
        return true;

      }

    },
    methods: {

      init: function() {

        var that = this;

        // hole alle Länder:
        var url  = process.env.VUE_APP_SERVER_URL + '/abschluss';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        })
        .then(function (response) {

          that.options = response.data;

          // ist was vorbelegt?
          that.checkExisting();

        });

      },
      checkExisting: function() {

        console.log('prüfe existierende ' + this.abschluss);
        if (this.abschluss && this.options) {

          for (var i = 0; i < this.options.length; i++) {
            if (this.options[i].id === this.abschluss.id) {
              this.selected = this.options[i];
              break;
            }
          }

        }

      },
      /** emit Uni */
      emit: function(val) {

        if (val) this.selected = val;
        this.$emit('set-abschluss', this.selected);

      }

    }

  }

</script>
