<template>

  <transition name="fade" mode="out-in">
    <div class="alert alert-danger" role="alert" v-if="errormessage">
      {{ errormessage }}
    </div>
  </transition>

</template>

<script>

export default {

  name: 'error-message',
  props: {
    errormessage: {
      type: String,
      required: false
    }
  }

}

</script>
