<!-- checkt ob ein Student Uhstat1 ausgefüllt hat -->
<template>

  <span class="hasUhstat1Wrapper" v-if="hasUhstat != null">
      <span v-if="hasUhstat === true"
            v-b-tooltip.hover.right
            title="Studierende*r ist bei UHStat1 registriert.">
        <b-icon icon="check" style="color: green"></b-icon>
      </span>
      <span v-else-if="hasUhstat === false"
            v-b-tooltip.hover.right
            title="Studierende*r ist noch nicht bei UHStat1 registriert.">
        <b-icon icon="x" style="color: red"></b-icon>
      </span>
  </span>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true

  export default {

    name: 'has-uhstat1',
    props: {
      student: null
    },
    data: function () {
      return {
        hasUhstat: null
      }
    },
    mounted: function () {
      this.init()
    },
    watch: {
      student: function () {
        this.init()
      }
    },
    methods: {

      init: function () {

        if (this.student && this.student.uhstat) {
          this.hasUhstat = true
        } else if (this.student && !this.student.uhstat) {

          const params = {
            student_id: this.student.id
          }
          const that = this;
          // hole Studiengänge und Nebenstudiengänge der Bewerbung
          const url = process.env.VUE_APP_SERVER_URL + '/student/isUhstatAusgefuellt';
          axios({
            method: 'GET',
            url: url,
            withCredentials: true,
            params: params
          }).then(response => {

            if (response.data.isUhstatAusgefuellt) {
              that.hasUhstat = true
            } else {
              that.hasUhstat = false
            }

          })

        }

      },

    }

  }
</script>
