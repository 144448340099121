<template>

  <header class="top line" v-if="messages">

    <div class="top-header d-flex flex-wrap container">
      <nav class="main-navigation">
        <ul class="nav">
          <li class="nav-item color-variable">
            <router-link to="/bewerbung"
                         id="h1-link"
                         href="#">
              <h1>{{ messages['fh.header.onlineBewerbung'] }}</h1>
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="language-switch">
        <ul>
          <li class="nav-item">
            <Language v-on:set-language="fetchMessages"></Language>
          </li>
        </ul>
      </div>

    </div>
    <div class="logo-wrapper">
      <a href="https://www.fh-salzburg.ac.at/" title="Homepage FH Salzburg">
        <img class="logo" src="../../assets/images/logo.svg" alt="Logo FH Salzburg">
      </a>
    </div>
    <div class="env-warning" v-if="env && (env === 'TEST' || env === 'DEV')">
      TESTSYSTEM
    </div>

  </header>

</template>
<style scoped>
  #h1-link {
    background: none;
    padding: 3px 0px;
  }
</style>
<script>

  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  import axios from 'axios';
  import Language from './Language.vue';
  axios.defaults.withCredentials = true;

  export default {

    name: 'FhHeader',
    components: {
      Language
    },
    data () {
      return {
        messages: null,
        // version: null,
        user: null,
        /** PROD, TEST oder DEV */
        env: null
      }
    },
    mounted: function () {

      this.fetchMessages();
      // this.fetchVersion();
      this.fetchEnv();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    methods: {

      /** App Version holen */
      fetchEnv: function () {
          var that = this;
          this.getEnvironment(function(resp) {
              that.env = resp;
          });
      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        var messages = [
          'fh.header.onlineBewerbung',
          'fh.logout'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      }

    }

  }

</script>
