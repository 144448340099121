<!--
Individualisierter Datepicker von vue-date:
@see https://github.com/showonne/vue-date
-->
<template>
  <div class="date-picker">
    <div class="input-wrapper" @mouseenter="showCancel = true" @mouseleave="showCancel = false">
      <input type="text"
             v-if="format == 'YYYY-MM'"
             @click="togglePanel"
             v-model="valueShort"
             class="form-control"
             :placeholder="placeholder"
             id="dateInputShort"
      />
      <input type="text"
             v-else
             @click="togglePanel"
             v-model="value"
             class="form-control"
             :placeholder="placeholder"
             id="dateInput"
      />
    </div>
    <transition name="toggle">
      <div class="date-panel" v-show="panelState" :style="coordinates">
        <div class="panel-header" v-show="panelType !== 'year'">
          <div class="arrow-left" @click="prevMonthPreview()">&lt;</div>
          <div class="year-month-box">
            <div class="year-box" @click="chType('year')" v-text="tmpYear"></div>
            <div class="month-box" @click="chType('month')">{{tmpMonth + 1 | month(language)}}</div>
          </div>
          <div class="arrow-right" @click="nextMonthPreview()">&gt;</div>
        </div>
        <div class="panel-header" v-show="panelType === 'year'">
          <div class="arrow-left" @click="chYearRange(0)">&lt;</div>
          <div class="year-range">
            <span v-text="yearList[0]"></span> - <span v-text="yearList[yearList.length - 1]"></span>
          </div>
          <div class="arrow-right" @click="chYearRange(1)">&gt;</div>
        </div>
        <div class="type-year" v-show="panelType === 'year'">
          <ul class="year-list">
            <li v-for="item in yearList"
                v-text="item"
                :key="item"
                :class="{selected: isSelected('year', item), invalid: validateYear(item)}"
                @click="selectYear(item)">
            </li>
          </ul>
        </div>
        <div class="type-month" v-show="panelType === 'month'">
          <ul class="month-list">
            <li v-for="(item, index) in monthList"
                :key="item"
                :class="{selected: isSelected('month', index), invalid: validateMonth(index)}"
                @click="selectMonth(index)">
              {{item | month(language)}}
            </li>
          </ul>
        </div>
        <div class="type-date" v-show="panelType === 'date'">
          <ul class="weeks">
            <li v-for="item in weekList" :key="item">{{item | week(language)}}</li>
          </ul>
          <ul class="date-list">
            <li v-for="(item, index) in dateList"
                :key="index"
                :class="{preMonth: item.previousMonth, nextMonth: item.nextMonth,
                                invalid: validateDate(item), firstItem: (index % 7) === 0}"
                @click="selectDate(item)">
              <div class="message" :class="{selected: isSelected('date', item)}">
                <div class="bg"></div><span v-text="item.value"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

    import moment from 'moment';

    export default {
        name: 'utilo-date-picker',
        data () {
            let now = new Date()
            let startDate, endDate,
                year,
                month,
                date,
                tmpYear,
                tmpMonth,
                tmpStartYear,
                tmpStartMonth,
                tmpStartDate,
                tmpEndYear,
                tmpEndMonth,
                tmpEndDate

            if (this.range) {

                startDate = this.value[0] ? new Date(this.value[0]) : now
                endDate = this.value[1] ? new Date(this.value[1]) : now

                year = endDate.getUTCFullYear()
                month = endDate.getUTCMonth()
                date = endDate.getUTCDate()

                tmpYear = endDate.getUTCFullYear()
                tmpMonth = endDate.getUTCMonth()

                tmpStartYear = startDate.getUTCFullYear()
                tmpStartMonth = startDate.getUTCMonth()
                tmpStartDate = startDate.getUTCDate()

                tmpEndYear = endDate.getUTCFullYear()
                tmpEndMonth = endDate.getUTCMonth()
                tmpEndDate = endDate.getUTCDate()

            } else {

                startDate = endDate = this.value ? new Date(this.value) : now

                year = endDate.getUTCFullYear()
                month = endDate.getUTCMonth()
                date = endDate.getUTCDate()
                console.log('default date ist = ' + date);

                tmpYear = this.startjahr || endDate.getUTCFullYear();
                tmpMonth = endDate.getUTCMonth()

                tmpStartYear = startDate.getUTCFullYear()
                tmpStartMonth = startDate.getUTCMonth()
                tmpStartDate = startDate.getUTCDate()

                tmpEndYear = endDate.getUTCFullYear()
                tmpEndMonth = endDate.getUTCMonth()
                tmpEndDate = endDate.getUTCDate()

            }

            return {
                showCancel: false,
                panelState: false,
                panelType: 'date',
                coordinates: {},
                year: year,
                month: month,
                date: date,
                tmpYear: tmpYear,
                tmpMonth: tmpMonth,
                tmpStartYear: tmpStartYear,
                tmpStartMonth: tmpStartMonth,
                tmpStartDate: tmpStartDate,
                tmpEndYear: tmpEndYear,
                tmpEndMonth: tmpEndMonth,
                tmpEndDate: tmpEndDate,
                minYear: Number,
                minMonth: Number,
                minDate: Number,
                maxYear: Number,
                maxMonth: Number,
                maxDate: Number,
                yearList: Array.from({length: 12}, (value, index) => new Date().getFullYear() + index),
                monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                weekList: [0, 1, 2, 3, 4, 5, 6],
                rangeStart: false,
                /** das ausgewählte Datum */
                value: null,
                placeholder: 'yyyy-mm-dd',
                /** value wenn Date Format = 'yyyy-mm' */
                valueShort: null
            }
        },
        props: {
            language: {default: 'en'},
            min: {default: '1950-01-01'},
            max: {default: '3016-01-01'},
            datum: {
                type: [String, Array],
                default: ''
            },
            range: {
                type: Boolean,
                default: false
            },
            startjahr: {
                type: Number
            },
            format: {
              type: String,
              default: 'YYYY-MM-DD'
            }
        },
        methods: {

            setPlaceholder: function () {

              console.log('Datepicker Sprache: ' + this.language);
              if (this.language === 'de' && this.format === 'YYYY-MM-DD') {
                this.placeholder = 'JJJJ-MM-TT (z. B. 1972-02-25)'
              } else if (this.language === 'en' && this.format === 'YYYY-MM-DD') {
                this.placeholder = 'yyyy-mm-dd (e.g. 1972-02-25)'
              } else if (this.language === 'de' && this.format === 'YYYY-MM') {
                this.placeholder = 'yyyy-mm (e.g. 1972-02)'
              } else if (this.language === 'en' && this.format === 'YYYY-MM') {
                this.placeholder = 'yyyy-mm (e.g. 1972-02)'
              }

            },
            togglePanel: function () {
                this.panelState = !this.panelState
                this.rangeStart = false
            },
            isSelected: function (type, item) {
                // console.log('is selected ' +  item);
                switch (type) {
                    case 'year':
                        if (!this.range) {
                          return item === this.tmpYear
                        }
                        return (
                          new Date(item, 0).getTime() >= new Date(this.tmpStartYear, 0).getTime() &&
                          new Date(item, 0).getTime() <= new Date(this.tmpEndYear, 0).getTime()
                        )
                    case 'month':
                        if (!this.range) {
                          return item === this.tmpMonth && this.year === this.tmpYear
                        }
                        return (
                          new Date(this.tmpYear, item).getTime() >= new Date(this.tmpStartYear, this.tmpStartMonth).getTime() &&
                          new Date(this.tmpYear, item).getTime() <= new Date(this.tmpEndYear, this.tmpEndMonth).getTime()
                        )
                    case 'date':
                        if (!this.range) return this.date === item.value && this.month === this.tmpMonth && item.currentMonth;
                        var month = this.tmpMonth;
                        item.previousMonth && month--
                        item.nextMonth && month++
                        console.log('item.value = ' +  item.value);
                        return (
                          new Date(this.tmpYear, month, item.value).getTime() >= new Date(this.tmpStartYear, this.tmpStartMonth, this.tmpStartDate).getTime() &&
                          new Date(this.tmpYear, month, item.value).getTime() <= new Date(this.tmpEndYear, this.tmpEndMonth, this.tmpEndDate).getTime()
                        )
                }
            },
            chType: function (type) {
                this.panelType = type
            },
            chYearRange: function (next) {
                if (next) {
                    this.yearList = this.yearList.map((i) => i + 12)
                } else {
                    this.yearList = this.yearList.map((i) => i - 12)
                }
            },
            prevMonthPreview: function () {
                this.tmpMonth = this.tmpMonth === 0 ? 0 : this.tmpMonth - 1
            },
            nextMonthPreview: function () {
                this.tmpMonth = this.tmpMonth === 11 ? 11 : this.tmpMonth + 1
            },
            selectYear: function (year) {
                if (this.validateYear(year)) return
                this.tmpYear = year
                this.panelType = 'month'
            },
            selectMonth: function (month) {
                if (this.validateMonth(month)) return
                this.tmpMonth = month
                this.panelType = 'date'
            },
            selectDate: function (date) {

                console.log('Datum setzen: ' + date.value);
                setTimeout(() => {
                    if (this.validateDate(date)) return
                    if (date.previousMonth) {
                        if (this.tmpMonth === 0) {
                            this.year -= 1
                            this.tmpYear -= 1
                            this.month = this.tmpMonth = 11
                        } else {
                            this.month = this.tmpMonth - 1
                            this.tmpMonth -= 1
                        }
                    } else if (date.nextMonth) {
                        if (this.tmpMonth === 11) {
                            this.year += 1
                            this.tmpYear += 1
                            this.month = this.tmpMonth = 0
                        } else {
                            this.month = this.tmpMonth + 1
                            this.tmpMonth += 1
                        }
                    }
                    if (!this.range) {

                        this.year = this.tmpYear
                        this.month = this.tmpMonth
                        this.date = date.value;
                        console.log('Date = ' +  this.date);
                        let value = `${this.tmpYear}-${('0' + (this.month + 1)).slice(-2)}-${('0' + this.date).slice(-2)}`;
                        this.value = value;
                        this.$emit('input', value)
                        this.panelState = false

                    } else if (this.range && !this.rangeStart) {

                        this.tmpEndYear = this.tmpStartYear = this.tmpYear
                        this.tmpEndMonth = this.tmpStartMonth = this.tmpMonth
                        this.tmpEndDate = this.tmpStartDate = date.value

                        this.rangeStart = true

                    } else if (this.range && this.rangeStart) {

                        this.tmpEndYear = this.tmpYear
                        this.tmpEndMonth = this.tmpMonth
                        this.tmpEndDate = date.value

                        var da = new Date(this.tmpStartYear, this.tmpStartMonth, this.tmpStartDate);
                        var d1 = da.getTime();
                            da = new Date(this.tmpEndYear, this.tmpEndMonth, this.tmpEndDate);
                        var d2 = da.getTime();
                        if (d1 > d2) {
                            let tmpY, tmpM, tmpD
                            tmpY = this.tmpEndYear
                            tmpM = this.tmpEndMonth
                            tmpD = this.tmpEndDate

                            this.tmpEndYear = this.tmpStartYear
                            this.tmpEndMonth = this.tmpStartMonth
                            this.tmpEndDate = this.tmpStartDate

                            this.tmpStartYear = tmpY
                            this.tmpStartMonth = tmpM
                            this.tmpStartDate = tmpD
                        }
                        let RangeStart = `${this.tmpStartYear}-${('0' + (this.tmpStartMonth + 1)).slice(-2)}-${('0' + this.tmpStartDate).slice(-2)}`
                        let RangeEnd = `${this.tmpEndYear}-${('0' + (this.tmpEndMonth + 1)).slice(-2)}-${('0' + this.tmpEndDate).slice(-2)}`

                        let value = [RangeStart, RangeEnd]
                        this.$emit('input', value)

                        this.rangeStart = false
                        this.panelState = false
                    }
                }, 0)
            },
            validateYear: function (year) {
                return (year > this.maxYear || year < this.minYear)
            },
            validateMonth: function (month) {
                if (new Date(this.tmpYear, month).getTime() >= new Date(this.minYear, this.minMonth - 1).getTime() &&
                    new Date(this.tmpYear, month).getTime() <= new Date(this.maxYear, this.maxMonth - 1).getTime()) {
                    return false
                }
                return true
            },
            validateDate: function (date) {
                let mon = this.tmpMonth
                if (date.previousMonth) {
                    mon -= 1
                } else if (date.nextMonth) {
                    mon += 1
                }
                if (new Date(this.tmpYear, mon, date.value).getTime() >= new Date(this.minYear, this.minMonth - 1, this.minDate).getTime() &&
                    new Date(this.tmpYear, mon, date.value).getTime() <= new Date(this.maxYear, this.maxMonth - 1, this.maxDate).getTime()) {
                    return false
                }
                return true
            },
            close: function (e) {
                if (!this.$el.contains(e.target)) {
                    this.panelState = false
                    this.rangeStart = false
                }
            },
            clear: function () {
                this.$emit('input', this.range ? ['', ''] : '')
            }
        },
        watch: {

            /** sprache wurde von aussen verändert */
            language: function () {

              this.setPlaceholder();

            },
            /** Datum wurde von aussen verändert */
            datum: function (obj) {
              console.log('Datum wurde geändert auf ' + obj);
              this.value = obj;
            },
            min: function (v) {
                let minArr = v.split('-')
                this.minYear = Number(minArr[0])
                this.minMonth = Number(minArr[1])
                this.minDate = Number(minArr[2])
            },
            max: function (v) {
                let maxArr = v.split('-')
                this.maxYear = Number(maxArr[0])
                this.maxMonth = Number(maxArr[1])
                this.maxDate = Number(maxArr[2])
            },
            range: function (newVal, oldVal) {
                if (newVal === oldVal) return
                if (newVal && Object.prototype.toString.call(this.value).slice(8, -1) === 'String') {
                    this.$emit('input', ['', ''])
                }
                if (!newVal && Object.prototype.toString.call(this.value).slice(8, -1) === 'Array') {
                    this.$emit('input', '')
                }
            },
            /** Datumsformat prüfen und emit durchführen */
            value: function (newVal) {

              console.log('value geändert=' + newVal);

              this.panelState = false; // Panel schließen falls offen
              var formats = ['YYYY-MM-DD', 'YYYY-MM'];
              if (moment(this.value, formats).isValid()) {
                this.$emit('input', this.value);
              } else {
                console.log('Datum nicht valide ' +  this.value);
                // this.$emit('input', '');
              }

            },
            valueShort: function (newVal) {

                console.log('value geändert=' + newVal);
                this.panelState = false; // Panel schließen falls offen
                var formats = ['YYYY-MM-DD', 'YYYY-MM'];
                if (moment(this.valueShort, formats).isValid()) {
                    this.$emit('input-short-date', this.valueShort);
                } else {
                    console.log('Datum nicht valide ' +  this.valueShort);
                    // this.$emit('input', '');
                }

            }
        },
        computed: {

            formattedValue: function() {
              return moment(this.value).format(this.format);
            },
            dateList () {
                let currentMonthLength = new Date(this.tmpYear, this.tmpMonth + 1, 0).getDate()
                let dateList = Array.from({length: currentMonthLength}, (val, index) => {
                    return {
                        currentMonth: true,
                        value: index + 1
                    }
                });
                let startDay = new Date(this.tmpYear, this.tmpMonth, 1).getDay()
                let previousMongthLength = new Date(this.tmpYear, this.tmpMonth, 0).getDate()

                for (let i = 0, len = startDay; i < len; i++) {
                    dateList = [{previousMonth: true, value: previousMongthLength - i}].concat(dateList)
                }
                for (let i = dateList.length, item = 1; i < 42; i++, item++) {
                    dateList[dateList.length] = {nextMonth: true, value: item}
                }
                return dateList
            }
        },
        filters: {
            week: (item, lang) => {
                switch (lang) {
                    case 'en':
                        return {0: 'Su', 1: 'Mo', 2: 'Tu', 3: 'We', 4: 'Th', 5: 'Fr', 6: 'Sa'}[item]
                    case 'de':
                        return {0: 'So', 1: 'Mo', 2: 'Di', 3: 'Mi', 4: 'Do', 5: 'Fr', 6: 'Sa'}[item]
                    case 'zh-cn':
                        return {0: '日', 1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六'}[item]
                    case 'uk':
                        return {0: 'Пн', 1: 'Вт', 2: 'Ср', 3: 'Чт', 4: 'Пт', 5: 'Сб', 6: 'Нд'}[item]
                    case 'es':
                        return {0: 'Do', 1: 'Lu', 2: 'Ma', 3: 'Mi', 4: 'Ju', 5: 'Vi', 6: 'Sa'}[item]
                    case 'fr':
                        return {0: 'Dim', 1:'Lun', 2: 'Mar', 3: 'Mer', 4: 'Jeu', 5: 'Ven', 6: 'Sam'}[item]
                    default:
                        return item
                }
            },
            month: (item, lang) => {
                switch (lang) {
                    case 'en':
                        return {1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'}[item]
                    case 'de':
                        return {1: 'Jän', 2: 'Feb', 3: 'Mär', 4: 'Apr', 5: 'Mai', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Okt', 11: 'Nov', 12: 'Dez'}[item]
                    case 'zh-cn':
                        return {1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六', 7: '七', 8: '八', 9: '九', 10: '十', 11: '十一', 12: '十二'}[item]
                    case 'uk':
                        return {1: 'Січень', 2: 'Лютий', 3: 'Березень', 4: 'Квітень', 5: 'Травень', 6: 'Червень', 7: 'Липень', 8: 'Серпень', 9: 'Вересень', 10: 'Жовтень', 11: 'Листопад', 12: 'Грудень'}[item]
                    case 'es':
                        return {1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic'}[item]
                    case 'fr':
                        return {1: 'Jan', 2: 'Fév', 3: 'Mar', 4: 'Avr', 5: 'Mai', 6: 'Juin', 7: 'Juil', 8: 'Août', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Déc'}[item]
                    default:
                        return item
                }
            }
        },
        mounted () {

            /* übergebenes Datum setzen */
            if (this.datum) {
              this.value = this.datum;
            }

            this.setPlaceholder();

            this.$nextTick(() => {
                if (this.$el.parentNode.offsetWidth + this.$el.parentNode.offsetLeft - this.$el.offsetLeft <= 300) {
                    this.coordinates = {right: '0', top: `${window.getComputedStyle(this.$el.children[0]).offsetHeight + 4}px`}
                } else {
                    this.coordinates = {left: '0', top: `${window.getComputedStyle(this.$el.children[0]).offsetHeight + 4}px`}
                }
                let minArr = this.min.split('-')
                this.minYear = Number(minArr[0])
                this.minMonth = Number(minArr[1])
                this.minDate = Number(minArr[2])

                let maxArr = this.max.split('-')
                this.maxYear = Number(maxArr[0])
                this.maxMonth = Number(maxArr[1])
                this.maxDate = Number(maxArr[2])

                if (this.range) {
                    if (Object.prototype.toString.call(this.value).slice(8, -1) !== 'Array') {
                        throw new Error('Binding value must be an array in range mode.')
                    }
                    if (this.value.length) {
                        let rangeStart = this.value[0].split('-')
                        let rangeEnd = this.value[1].split('-')
                        this.tmpStartYear = Number(rangeStart[0])
                        this.tmpStartMonth = Number(rangeStart[1]) - 1
                        this.tmpStartDate = Number(rangeStart[2])

                        this.tmpEndYear = Number(rangeEnd[0])
                        this.tmpEndMonth = Number(rangeEnd[1]) - 1
                        this.tmpEndDate = Number(rangeEnd[2])
                    } else {
                        this.$emit('input', ['', ''])
                    }

                }
                if (!this.value) {
                    this.$emit('input', '')
                }
                window.addEventListener('click', this.close)
            })
        },
        beforeDestroy () {
            window.removeEventListener('click', this.close)
        }
    }
</script>

<style scoped>
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .date-picker {
    position: relative;
    height: 32px;
    border: 0;
    margin-bottom: 16px;
  }
  .input-wrapper {
    border: 1px solid #ccc;
    border-radius: 2px;
    vertical-align: middle;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    align-items: center;
    padding: 6px 10px 6px 4px;
    height: 47px;
    box-sizing: border-box;
  }
  .input {
    height: 100%;
    width: 100%;
    font-size: inherit;
    padding-left: 4px;
    box-sizing: border-box;
    outline: none;
  }
  .cancel-btn {
    height: 14px;
    width: 14px;
  }
  .date-panel {
    position: absolute;
    z-index: 5000;
    border: 1px solid #eee;
    width: 320px;
    padding: 5px 10px 10px;
    box-sizing: border-box;
    background-color: #fff;
    transform: translateY(4px);
  }
  .panel-header {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }
  .arrow-left,
  .arrow-right {
    flex: 1;
    font-size: 20px;
    line-height: 2;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
  }
  .year-range {
    font-size: 20px;
    line-height: 2;
    flex: 3;
    display: flex;
    justify-content: center;
  }
  .year-month-box {
    flex: 3;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }
  .type-year,
  .type-month,
  .date-list {
    background-color: #fff;
  }
  .year-box,
  .month-box {
    transition: all ease .1s;
    font-family: 'TheinhardtRegular';
    flex: 1;
    text-align: center;
    font-size: 20px;
    line-height: 2;
    cursor: pointer;
    background-color: #fff;
  }
  .year-list,
  .month-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .year-list li,
  .month-list li {
    font-family: 'TheinhardtRegular';
    transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    width: 33%;
    padding: 10px 0;
  }
  .year-list li:hover,
  .month-list li:hover {
    background-color: #000000;
    color: #fff;
  }
  .year-list li.selected,
  .month-list li.selected {
    background-color: #000000;
    color: #fff;
  }
  .year-list li.invalid,
  .month-list li.invalid {
    cursor: not-allowed;
    color: #ccc;
  }
  .date-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .date-list .valid:hover {
    background-color: #eee;
  }
  .date-list li {
    transition: all ease .1s;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 1px solid #fff;
    position: relative;
    margin: 2px;
  }
  .date-list li:not(.firstItem) {
    margin-left: 10px;
  }
  .date-list li .message {
    font-family: 'TheinhardtRegular';
    font-weight: 300;
    font-size: 14px;
    position: relative;
    height: 30px;
  }
  .date-list li .message.selected .bg {
    background-color: #000000;
  }
  .date-list li .message.selected span {
    color: #fff;
  }
  .date-list li .message:not(.selected) .bg {
    transform: scale(0);
    opacity: 0;
  }
  .date-list li .message:not(.selected):hover .bg {
    background-color: #000000;
    transform: scale(1);
    opacity: .6;
  }
  .date-list li .message:not(.selected):hover span {
    color: #fff;
  }
  .date-list li .message .bg {
    height: 30px;
    width: 100%;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-radius: 50%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }
  .date-list li .message span {
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .date-list li.invalid {
    cursor: not-allowed;
    color: #ccc;
  }
  .weeks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .weeks li {
    font-weight: 600;
  }
  .weeks,
  .date-list {
    width: 100%;
    text-align: center;
  }
  .weeks .preMonth,
  .date-list .preMonth,
  .weeks .nextMonth,
  .date-list .nextMonth {
    color: #ccc;
  }
  .weeks li,
  .date-list li {
    font-family: 'TheinhardtRegular';
    font-size: 0.9em;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
  }
  .toggle-enter,
  .toggle-leave-active {
    opacity: 0;
    transform: translateY(-10px);
  }
  .toggle-enter-active,
  .toggle-leave-active {
    transition: all ease .2s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all ease .1s;
  }
  #dateInput, #dateInput:focus{
    /*border: 0;*/
    outline: none;
    width: 100%;
  }

</style>
