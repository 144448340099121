<template>

  <div v-if="options && messages">

    <select v-model="selected"
            class="custom-select"
            v-bind:class="{ 'is-invalid': !isValid }"
            :disabled="readonly">
      <option
        v-for="option in options.null"
        v-bind:value="option"
        v-bind:key="option.id">
        {{ option.name }}
      </option>
      <optgroup :label="value" v-for="value in optionsnotnull" :key="value">
        <option
          v-for="option in options[value]"
          v-bind:value="option"
          v-bind:key="option.id">
          {{ option.name }}
        </option>
      </optgroup>
    </select>
    <div class="invalid-feedback" >
      {{invalidMessage}}
    </div>

  </div>

</template>

<script>

  import axios from 'axios';
  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  axios.defaults.withCredentials = true;

  export default {

    name: 'select-dokumenttyp',
    props: {
      invalidMessage: {
        type: String,
        required: false
      },
      readonly: null,
      /** von aussen übergebener Dokumenttyp */
      dokumenttyp: null
    },
    data () {
      return {
        messages: null,
        /** mögliche Dokumenttypen */
        options: null,
        /** vom User ausgewählter Dokumenttyp */
        selected: null
      }
    },
    mounted: function () {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    watch: {

      dokumenttyp: function(val) {
        this.selected = val;
      },
      /** Dokumenttyp wurde vom Benutzer ausgewählt */
      selected: function (val) {
        this.emit(val);
      }

    },
    computed: {

      /** alle Optionen die eine Kategorie haben */
      optionsnotnull: function () {

        if (this.options) {

          var keys = [];
          Object.keys(this.options).forEach(function(key) {

            if (key !== 'null') keys.push(key);

          });
          return keys;

        }

        return null;

      },
      /** prüft ob der Dokumenttyp eingegeben wurde */
      isValid: function () {

        if (!this.selected || this.selected.id == null) {
          return false;
        }
        return true;

      }

    },
    methods: {

      init: function() {

        var that = this;

        // hole alle Dokumenttypen:
        var url  = process.env.VUE_APP_SERVER_URL + '/file/getRequiredDocuments';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        })
          .then(function (response) {

            that.options = response.data;

          });

      },
      /** emit */
      emit: function(val) {

        if (val) this.selected = val;
        this.$emit('set-dokumenttyp', this.selected);

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        var messages = [
          'fh.fileUpload.erforderlicheDokumente.gruppe.A',
          'fh.fileUpload.erforderlicheDokumente.gruppe.B',
          'fh.fileUpload.erforderlicheDokumente.gruppe.C'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
          that.init();
        });

      }

    }

  }

</script>
