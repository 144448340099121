<template>

  <div class="selectLandAndOrtWrapper">

    <!-- Land auswählen -->
    <div class="form-group row">
      <div class="col-md-2">
        <label for="land" class="col-form-label">{{landLabel}}</label>
      </div>
      <div class="col-md-10">
        <select-land :invalid-message="landErrorMessage"
                     :land="selectedLand"
                     id="land"
                     v-on:set-land="setLand"
                     :readonly="readonly">
        </select-land>
      </div>
    </div>

    <!-- Ort auswählen wenn Land gewählt -->
    <div class="form-group row" v-if="selectedLand">

      <div class="col-md-2">
        <label for="ort" class="col-form-label">{{ortLabel}}</label>
      </div>
      <div class="col-md-10">

        <!-- Ort als Object für Österreicher -->
        <span v-if="selectedLand && selectedLand.iso3166 === 'A' && !readonly">
          <select-ort :invalid-message="ortErrorMessage"
                      :ort="selectedOrtobj"
                      id="ort"
                      v-on:set-ort="setOrt"
                      :readonly="readonly">
          </select-ort>
        </span>
        <!-- Ort als Freitext für Ausländer -->
        <span v-else>
            <input type="text"
                   maxlength="100"
                   class="form-control"
                   v-bind:class="{ 'is-invalid': !isOrtValid }"
                   name="ort"
                   id="ort"
                   :placeholder="ortLabel"
                   v-model.trim="selectedOrt"
                   required
                   :disabled="readonly">
            <div class="invalid-feedback">
              {{ortErrorMessage}}
            </div>
          </span>
      </div>

    </div>

  </div>

</template>

<script>

  import SelectLand from './SelectLand.vue';
  import SelectOrt from './SelectOrt';

  export default {

    name: 'select-land-and-ort',
    components: {

      SelectLand,
      SelectOrt

    },
    props: {
      land: {
        type: Object,
        required: false
      },
      ort: {
        type: String,
        required: false
      },
      /** Orts Object für Österreich */
      ortobj: {
        type: Object,
        required: false
      },
      landLabel: {
        type: String,
        required: false
      },
      ortLabel: {
        type: String,
        required: false
      },
      ortErrorMessage: {
        type: String,
        required: false
      },
      landErrorMessage: {
        type: String,
        required: false
      },
      readonly: null
    },
    data () {
      return {
        /** ausgewähltes Land */
        selectedLand: null,
        /** ausgewählter Ort String */
        selectedOrt: null,
        /** ausgewähltes Ort Object für Österreich */
        selectedOrtobj: null
      }
    },
    mounted: function () {

      if (this.land) {
        this.selectedLand = this.land;
      }
      if (this.ort) {
        this.selectedOrt = this.ort;
      }
      if (this.ortobj) {
        this.selectedOrtobj = this.ortobj;
        if (!this.selectedOrt && this.ortobj) {
          this.selectedOrt = this.ortobj.name;
        }
      }

    },
    watch: {

      /** Land wurde von aussen übergeben */
      land: function (val) {
        this.selectedLand = val;
      },
      /** Ort wurde von aussen übergeben */
      ort: function (val) {
        console.log('Ort von aussen verändert: ' + val);
        this.selectedOrt = val;
      },
      /** Ort Objekt wurde von aussen übergeben */
      ortobj: function (val) {
        this.selectedOrtobj = val;
        if (!this.selectedOrt && this.selectedOrtobj) {
          this.selectedOrt = this.selectedOrtobj.name;
        }
      },
      /** ein Ort wurde ausgewählt */
      selectedOrt: function () {
        this.emitLandAndOrt();
      },
      /** ein Ort Objekt wurde ausgewählt */
      selectedOrtobj: function () {
        this.emitLandAndOrt();
      }

    },
    computed: {

      isOrtValid: function () {
        if (!this.selectedOrt || this.selectedOrt === '' || this.selectedOrt.length < 2) return false;
        return true;
      },
      /** prüft ob Land und Ort gewählt wurde */
      isValid: function () {

        if (!this.selectedOrt || !this.selectedLand) {
          return false;
        }
        return true;

      }

    },
    methods: {

      setLand: function(obj) {
        // lösche den Ort wenn das Land gewechselt wird:
        console.log('Lösche den Ort wenn das Land gewechselt wird!');
        if (obj && this.selectedLand && this.selectedLand.id !== obj.id) {
          this.selectedOrt    = null;
          this.selectedOrtobj = null;
        }
        this.selectedLand = obj;
      },
      setOrt: function(obj) {
        this.selectedOrtobj = obj;
        this.selectedOrt    = obj ? obj.name : null;
      },
      /** emit Land und Ort */
      emitLandAndOrt: function() {

        var obj = {
          land:   this.selectedLand,
          ort:    this.selectedOrt,
          ortobj: this.selectedOrtobj
        };
        this.$emit('set-land-and-ort', obj);

      }

    }

  }

</script>
