<!-- zeigt einen Text an falls allen Verträgen zugestimmt wurde und der Foto Upload erledigt wurde -->
<template>

  <b-alert variant="success" show v-if="isComplete">
    <span v-html="message"></span>
  </b-alert>

</template>

<script>

import axios from 'axios';
import { EventBus } from '@/event-bus';
axios.defaults.withCredentials = true;

export default {

  name: 'alert-success-is-complete',
  props: ['message', 'bewerbung'],
  data () {
    return {
      messages: null,
      /** wurde die Vertragszustimmung vollständig abgeschlossen´? */
      isComplete: false
    }
  },
  mounted: function () {

    this.fetchMessages();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });
    EventBus.$on('is-vertragszustimmung-valid', valid => {
      this.isComplete = valid;
    });

  },
  methods: {

    /** prüft die Dokumente zur Vertragszustimmung nachdem die messages geladen wurden */
    init: function () {

      let bewerbung_id = null;
      if (this.bewerbung) {
        bewerbung_id = this.bewerbung.id;
      } else {
        bewerbung_id = this.getBewerbung();
        console.log('Bewerbung zu prüfen: ' + bewerbung_id);
      }

      // prüfe ob alles vom Studenten erfasst wurde, Foto, Verträge und SV Nummer:
      var that     = this;
      const url    = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/isVertragszustimmungValid';
      const params = {
        bewerbung_id: bewerbung_id
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
      .then(function (resp) {

          console.log('isVertragszustimmungValid response: ' + resp.data.valid);
          if (resp.data.valid) {
            that.isComplete = true;
          } else {
            that.isComplete = false;
          }

      });

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      var messages = [
        'fh.vertragszustimmung.isCompleteStartseite',
        'fh.vertragszustimmung.isComplete'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        that.init();
      });

    }

  }

}

</script>
