<!-- den Button zur Vertragszustimmung anzeigen oder nicht -->
<template>
  <button v-if="hasContractsForAgreement"
          type="button"
          class="button primary mt-1"
          :class="{ 'notValid': isNotValid }"
          @click="showVertragszustimmung(bewerbung)">
    <span v-html="messages['fh.vertragszustimmung.h2']"></span>
  </button>
</template>

<script>

import axios from 'axios';
import { EventBus } from '@/event-bus';
axios.defaults.withCredentials = true;

export default {

  name: 'button-vertragszustimmung',
  props: ['bewerbung'],
  data () {
    return {
      messages: null,
      /** Liste mit Verträgen vorhanden oder nicht */
      hasContractsForAgreement: false,
      /** wurde die Vertragszustimmung noch nicht vollständig abgeschlossen */
      isNotValid: false
    }
  },
  mounted: function () {

    this.fetchMessages();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** Vertragszustimmung und Foto Upload öffnen */
    showVertragszustimmung: function (bewerbung) {
      // Vertragszustimmung zu Bewerbung:
      this.setBewerbung(bewerbung.id);
      this.$router.push('Vertragszustimmung');
    },
    /** prüft die Dokumente zur Vertragszustimmung nachdem die messages geladen wurden */
    init: function() {

      var that = this;

      // hole die Verträge aus der Oracle:
      var url  = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/hasContractsForAgreement';
      var params = {
        bewerbung_id: this.bewerbung.id
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          if (!response.data.error) {

            that.hasContractsForAgreement = response.data.hasContractsForAgreement;
            // that.hasContractsForAgreement = true; // auf true stellen um Foto Upload zu testen

            if (that.hasContractsForAgreement) {

              // prüfe ob alles vom Studenten erfasst wurde
              url  = process.env.VUE_APP_SERVER_URL + '/vertragszustimmung/isVertragszustimmungValid';
              axios.get(url, {
                params: params,
                withCredentials: true
              })
                .then(function (resp) {

                  if (resp.data.valid) {
                    that.isNotValid = false;
                  } else {
                    that.isNotValid = true;
                  }

                });

            }

          }

        });

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      var messages = [
        'fh.vertragszustimmung.h2'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        that.init();
      });

    }

  }

}

</script>

<style scoped>

  .button.primary.notValid,
  .button.black.primary.notValid {
    background: white;
    color: black;
    border: 3px solid #ff2433;
  }
  .button.primary.notValid:hover,
  .button.black.primary.notValid:hover {
    background: #ff2433;
    color: black;
    border: 3px solid black;
  }

</style>
