<template>

  <div name="userform" id="userform">

    <transition name="fade" mode="out-in">

      <div v-if="messages">

          <check-login></check-login>
          <nav-tabs :active="'Bewerbung'"></nav-tabs>

          <div class="bewerbung mt-5">

            <h1 class="h2">{{ messages['fh.h1'] }}</h1>
            <h2 class="h4 mt-3">{{ messages['fh.bewerbung.bestehendeBewerbungen']}}</h2>
            <ul class="list-group mt-2">
              <li class="list-group-item" v-for="(bewerbung, index) in bewerbungen" :key="bewerbung.id">
                <div class="row">
                  <div class="col-md-10">
                    <div class="mb-2">
                      {{ createBewerbungsText(bewerbung) }}
                      <print-studiengaenge-of-bewerbung :bewerbung="bewerbung" />
                      <alert-success-is-complete
                        :message="getAlertSuccessIsCompleteMessage(bewerbung)"
                        :bewerbung="bewerbung"
                      />
                    </div>
                    <is-abgeschlossen-warning :bewerbung="bewerbung" />
                  </div>
                  <div class="col-md-2 text-right">
                    <span v-if="bewerbung.abgeschlossen">

                      <button type="button"
                              class="button primary mt-1"
                              @click="addFiles(bewerbung)">
                        <span v-html="messages['fh.nav.dateiupload'].replace('&shy;', '')"></span>
                      </button>

                      <!-- weitere Nebenbewerbung hinzufügen -->
                      <button-add-nebenbewerbung v-model="bewerbungen[index]" />

                    </span>
                    <span v-else>
                      <!-- Bewerbung bearbeiten -->
                      <button type="button"
                              class="button primary mt-1"
                              @click="editBewerbung(bewerbung)">
                        {{ messages['default.button.edit.label'] }}
                      </button>
                      <!-- Bewerbung löschen -->
                      <button type="button"
                              class="button secondary black mt-1"
                              @click="deleteBewerbung(bewerbung)">
                        {{ messages['default.button.delete.label'] }}
                      </button>
                    </span>
                    <!-- Zustimmung zum Ausbildungsvertrag -->
                    <button-vertragszustimmung :bewerbung="bewerbung" />
                  </div>
                </div>

              </li>
            </ul>

            <h2 class="h4 mt-3">{{ messages['fh.bewerbung.neueBewerbung'] }}</h2>
            <p class="mt-2" v-if="hasOpenBewerbungen">
              <b-alert variant="warning" show>
                {{ messages['fh.bewerbung.warn'] }}
              </b-alert>
            </p>
            <p class="mt-2" v-else>
              <button type="button" class="button primary" @click="createBewerbung()">{{ messages['fh.bewerbung.start'] }}</button>
            </p>

          </div>

      </div>

    </transition>

  </div>

</template>
<script>

  import moment                 from 'moment';
  import NavTabs                from '@/components/layout/NavTabs.vue';
  import CheckLogin             from '@/components/login/CheckLogin.vue';
  import axios                  from 'axios';
  import ButtonVertragszustimmung from '@/components/vertragszustimmung/ButtonVertragszustimmung';
  // Import the EventBus.
  import { EventBus }           from '@/event-bus.js';
  import IsAbgeschlossenWarning from '@/components/layout/IsAbgeschlossenWarning';
  import PrintStudiengaengeOfBewerbung from '@/components/studiengang/PrintStudiengaengeOfBewerbung';
  import AlertSuccessIsComplete from '@/components/vertragszustimmung/AlertSuccessIsComplete';
  import bootbox from 'bootbox';
  axios.defaults.withCredentials = true;
  import ButtonAddNebenbewerbung from '@/components/bewerbung/buttonAddNebenbewerbung';

  export default {

    name: 'Bewerbung',
    components: {
      ButtonVertragszustimmung,
      IsAbgeschlossenWarning,
      NavTabs,
      CheckLogin,
      PrintStudiengaengeOfBewerbung,
      AlertSuccessIsComplete,
      ButtonAddNebenbewerbung
    },
    data () {
      return {
        messages: null,
        /** bestehende Bewerbungen des Users */
        bewerbungen: null
      }
    },
    mounted: function () {

      this.init();

    },
    computed: {

      /**
       * prüft ob offene Bewerbungen vorhanden sind, die neuer als 5 Monate sind
       */
      hasOpenBewerbungen: function () {

        if (!this.bewerbungen || this.bewerbungen.length < 1) return false;

        const xMonthAgo = moment().subtract(5, 'months');
        for (let item in this.bewerbungen) {
          const bewerbung = this.bewerbungen[item];
          if (!bewerbung.abgeschlossen) {
            if (moment(bewerbung.dateCreated).isAfter(xMonthAgo)) {
              return true;
            }
          }
        }
        return false;

      }

    },
    methods: {

      /**
       * liefert den Text für das Alert, dass die Bewerbung erfolgreich abgeschlossen ist
       * @param bewerbung
       * @returns String
       */
      getAlertSuccessIsCompleteMessage: function (bewerbung) {

        console.log('getAlertSuccessIsCompleteMessage: ' +  bewerbung);
        let msg = this.messages['fh.vertragszustimmung.isCompleteStartseite'];
        if (bewerbung.finalAgreementDate) {
          msg = msg.replace('{0}', this.javaDateToHtml5Date(bewerbung.finalAgreementDate))
        } else {
          msg = msg.replace('am {0}', '');
          msg = msg.replace(' on {0}', '');
        }
        return msg;

      },
      /** neue Bewerbung starten */
      createBewerbung: function () {

        var that = this;
        if (this.hasOpenBewerbungen) {

          const msg = this.messages['fh.bewerbung.warn'];
          bootbox.alert(msg);

        } else {

          this.showSpinner();
          // neue Bewerbung in der Datenbank anlegen
          const url = process.env.VUE_APP_SERVER_URL + '/student/createBewerbung';
          axios.get(url, {
            withCredentials: true
          }).then(function (response) {

            if (response.data && response.data.id) {
              console.log('Bewerbung erfolgreich angelegt.');
              that.setBewerbung(response.data.id);
              // weiterleiten zu den Userdaten:
              that.$router.push('UserData');
            }
            that.hideSpinner();

          });

        }

      },
      /** direkt zum File Upload springen */
      addFiles: function (bewerbung) {

        this.setBewerbung(bewerbung.id);
        sessionStorage.bewerbergruppe = bewerbung.bewerbergruppe?.name;
        // weiterleiten zu den Files:
        this.$router.push('Upload');

      },
      /**
       * eine nicht abgeschlossene Bewerbung löschen
       * @param bewerbung
       */
      deleteBewerbung: function (bewerbung) {

        var that = this;
        var msg = this.messages['fh.bewerbung.delete.confirm'];
        bootbox.confirm(msg, function(result) {

          if (result) {

            var url = process.env.VUE_APP_SERVER_URL + '/student/deleteBewerbung';
            var params = {
              id: bewerbung.id
            }
            axios.get(url, {
              params: params,
              withCredentials: true
            }).then(function (response) {

              if (response.data && response.data.id) {
                console.log('Bewerbung erfolgreich gelöscht.');
                for (let i = 0; i < that.bewerbungen.length; i++) {
                  if (that.bewerbungen[i].id == bewerbung.id) {
                    that.bewerbungen.splice(i, 1);
                    break;
                  }
                }
              }

            });

          }

        });

      },
      /** bestehende Bewerbung ändern */
      editBewerbung: function (bewerbung) {

        this.setBewerbung(bewerbung.id);
        // weiterleiten zu den Userdaten:
        this.$router.push('UserData');

      },
      /** Text für eine bestehende Bewerbung zusammenbauen */
      createBewerbungsText: function (bewerbung) {

        //if (!bewerbung.bewerbergruppe) return '';

        var als = '';
        if (bewerbung.bewerbergruppe && bewerbung.bewerbergruppe.name === 'STUDENT') {
          als = this.messages['fh.studiengang.bewerbergruppe.STUDENT'];
        } else if (bewerbung.bewerbergruppe && bewerbung.bewerbergruppe.name === 'AUSSERORDENTLICHER_HOERER') {
          als = this.messages['fh.studiengang.bewerbergruppe.AUSSERORDENTLICHER_HOERER'];
        } else if (bewerbung.bewerbergruppe && bewerbung.bewerbergruppe.name === 'INCOMING') {
          als = this.messages['fh.studiengang.bewerbergruppe.INCOMING'];
        }

        var msg = this.messages['fh.bewerbung.bestehendeBewerbung'];
        msg = msg.replace('{1}', this.javaDateToHtml5Date(bewerbung.dateCreated));
        msg = msg.replace('{0}', als);

        // falls keine Bewerbergruppe ausgewählt wurde:
        if (!als) {
          msg = msg.replace(' als ', ' ').replace(' as ', ' ');
        }

        return msg;

      },
      init: function () {

        // hier ist noch keine Bewerbung ausgewählt, also auf null setzen:
        this.setBewerbung(null);
        sessionStorage.isAbgeschlossen = null;

        this.fetchMessages();
        // Listen for the set-language event on EventBus
        EventBus.$on('set-language', lang => {
          this.fetchMessages(lang);
        });

        var that = this;
        // hole bestehende Bewerbungen des Users
        var url = process.env.VUE_APP_SERVER_URL + '/student/getBewerbungen';
        axios({
          method: 'post',
          url: url,
          withCredentials: true
        }).then(function (response) {

          that.bewerbungen = response.data;

        });

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        this.sprache = language || localStorage.language;

        var messages = [
          'fh.h1',
          'fh.bewerbung.bestehendeBewerbungen',
          'fh.bewerbung.neueBewerbung',
          'fh.bewerbung.bestehendeBewerbung',
          'default.button.edit.label',
          'fh.bewerbung.start',
          'fh.studiengang.bewerbergruppe.STUDENT',
          'fh.studiengang.bewerbergruppe.AUSSERORDENTLICHER_HOERER',
          'fh.studiengang.bewerbergruppe.INCOMING',
          'fh.nav.dateiupload',
          'fh.bewerbung.warn',
          'fh.bewerbung.warn.confirm',
          'default.button.delete.label',
          'fh.bewerbung.delete.confirm',
          'fh.vertragszustimmung.isCompleteStartseite',
          'fh.bewerbung.nebenbewerbungHinzufuegen'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
          // Seiten Titel setzen:
          document.title = that.messages['fh.h1'];
        });

      }

    }

  }
</script>
