<!-- zu einer abgeschlossenen Bewerbung eine Nebenbewerbung hinzufügen -->
<template>

  <span class="buttonAddNebenbewerbungHinzufuegenWrapper" v-if="messages">

      <button type="button"
              v-if="showButton"
              class="button primary mt-1"
              @click="addNebenbewerbung()">
        {{ messages['fh.bewerbung.nebenbewerbungHinzufuegen'] }}
      </button>

  </span>

</template>

<script>

  import axios from 'axios';
  import { EventBus } from '@/event-bus'
  axios.defaults.withCredentials = true;

  export default {

    name: 'button-add-nebenbewerbung',
    props: ['value'],
    data () {
      return {
        /** wird auf true gesetzt wenn der Button angezeigt werden soll */
        showButton: false,
        messages: null
      }
    },
    mounted: function () {

      this.fetchMessages();
      this.init();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    methods: {

      /** weitere Nebenbewerbung hinzufügen, zur Studiengang Seite wechseln */
      addNebenbewerbung: function () {

        this.setBewerbung(this.value.id);
        sessionStorage.bewerbergruppe = this.value.bewerbergruppe ? this.value.bewerbergruppe.name : null;
        this.$router.push('Studiengang');

      },
      init: function () {

        this.showButton = false;
        if (this.value && this.value.abgeschlossen) { // Bewerbung muss abgeschlossen sein

          if (this.value.bewerbergruppe && this.value.bewerbergruppe.name === 'STUDENT') { // gilt nur für ordentliche Studenten

            // nun prüfe ob es Nebenwerbungen im gleichen Semester wie die Hauptbewerbung gibt:
            let that = this;
            const url = process.env.VUE_APP_SERVER_URL + '/studiengang/isWeitereBewerbungMoeglich';
            const params = {
              bewerbung_id: this.value.id
            }
            axios.get(url, {
              params: params,
              withCredentials: true
            }).then(function (response) {

              if (response.data && response.data.success) {
                console.log('weitere Bewerbung ist möglich.');
                that.showButton = true;
              }

            })

          }

        }

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        var messages = [
          'fh.bewerbung.nebenbewerbungHinzufuegen'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      }

    }

  }

</script>
