<template>

  <transition name="fade" mode="out-in">

    <section class="content-module" id="register" v-if="messages">

      <transition name="fade" mode="out-in">

        <div class="content-module-wrapper content-area" id="registerStepA" v-if="step == 0">

          <div class="content-header">
            <section class="page-title narrow ">
              <div class="row">
                <div class="col-lg-12">
                  <div class="page-title-wrapper">
                    <h1>{{ messages['fh.register.h1'] }}</h1>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <p>
            {{ messages['fh.register.desc'] }}
            <br/><br/>
            {{ messages['fh.register.desc3'] }}
            <br/><br/>
            {{ messages['fh.register.desc2'] }}
            <br/><br/>
            {{ messages['fh.register.desc4'] }}
          </p>

          <form
            id="usernameForm"
            @submit="submitEMail">

            <!-- Fehlermeldung unten und oben auf der Seite anzeigen -->
            <error-message :errormessage="errormessage" />

            <username-form-row v-on:set-username="setUsername" />

            <!-- bei einem Fehler auch den Abbrechen Button hier anzeigen -->
            <button type="button"
                    v-if="errormessage"
                    class="button secondary mt-3"
                    v-on:click="abbrechen">{{ messages['default.button.break'] }}</button>

            <hr class="mt-5 mb-5"/>

            <!-- Datenschutzerklärung anzeigen -->
            <p>
              <button class="button primary"
                      type="button"
                      v-on:click="toggleDatenschutz">
                <span v-if="showDatenschutz">{{ messages['fh.datenschutz.ausblenden'] }}</span>
                <span v-else>{{ messages['fh.datenschutz.anzeigen'] }}</span>
              </button>
            </p>
            <transition name="fade" mode="out-in">
              <div id="collapseDatenschutz" v-if="showDatenschutz">
                <div class="card card-body mt-3">
                  <datenschutz-text />
                </div>
              </div>
            </transition>

            <hr class="mt-5 mb-5" />

            <!-- Fehlermeldung unten und oben auf der Seite anzeigen -->
            <error-message :errormessage="errormessage" />

            <div class="form-group row">
              <div class="col-md-12">
                <button type="button"
                        class="button secondary"
                        v-on:click="abbrechen">{{ messages['default.button.break'] }}</button>&nbsp;
                <button type="button"
                        class="button primary"
                        v-on:click="submitEMail"
                        :disabled="!isUsernameAndDatenschutzValid">{{ messages['default.button.next.label'] }}</button>
              </div>
            </div>

          </form>

        </div>

      </transition>

      <transition name="fade" mode="out-in">

        <div class="content-module-wrapper content-area" id="registerStepB" v-if="step == 1">

          <info-message :message="message" v-if="lang == 'de'"></info-message>
          <info-message :message="message_en" v-else></info-message>
          <error-message :errormessage="codeerrormessage"></error-message>

        </div>
      </transition>

      <transition name="fade" mode="out-in">

        <div class="content-module-wrapper content-area" id="registerStepC" v-if="step == 2">

          <info-message :message="codemessage" />
          <error-message :errormessage="registererrormessage" />

          <form
            id="passForm"
            @submit="submitPassword">

            <new-password-form-row v-on:set-password="setPassword" />

            <div class="form-group row">
              <div class="col-md-2"></div>
              <div class="col-md-10">

                <button type="button"
                        class="button secondary"
                        v-on:click="abbrechen">{{ messages['default.button.break'] }}</button>&nbsp;
                <button type="button"
                        class="button primary"
                        v-on:click="submitPassword"
                        :disabled="!password">{{ messages['default.button.next.label'] }}</button>

              </div>
            </div>

          </form>

        </div>
      </transition>

    </section>
  </transition>

</template>

<script>

import axios from 'axios'
import UsernameFormRow from './login/UsernameFormRow.vue'
import ErrorMessage from './layout/ErrorMessage.vue'
import InfoMessage from './layout/InfoMessage.vue'
import NewPasswordFormRow from './login/NewPasswordFormRow.vue'
import DatenschutzText from './datenschutz/DatenschutzText';
// Import the EventBus.
import { EventBus } from '../event-bus.js';

export default {

  name: 'Register',
  data () {
    return {
      username: null,
      messages: null,
      /** Schritte der Registrierung zur anzeige der Formulare */
      step: 0,
      errormessage: null,
      message: null,
      message_en: null,
      /** Registrierungscode */
      code: null,
      /** Fehlermeldung nach Code Prüfung */
      codeerrormessage: null,
      /** Erfolgsmeldung nach Code Prüfung */
      codemessage: null,
      password: null,
      registererrormessage: null,
      /**
       * true|false ob Datenschutzbestimmung bestätigt wurde
       * wird seit Version 2 nicht mehr vom Benutzer bestätigt, also default auf true setzen
       * */
      datenschutzChecked: true,
      lang: null,
      /** Datenschutzerklärung toggeln */
      showDatenschutz: false
    }
  },
  computed: {

    /** prüft ob der Benutzername eingegeben wurde */
    isUsernameAndDatenschutzValid: function () {

      if (!this.datenschutzChecked || !this.username || this.username === '') {
        return false;
      }

      return true;

    }

  },
  mounted: function () {

    // lösche die Bewerbung aus dem session storage
    this.setBewerbung(null);
    this.lang = localStorage.language;
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.lang = lang;
      this.fetchMessages(lang);
    });
    /** Token aus GET holen und prüfen */
    this.checkToken();

  },
  components: {
    DatenschutzText,
    UsernameFormRow,
    ErrorMessage,
    InfoMessage,
    NewPasswordFormRow
  },
  methods: {

    /** Datenschutzerklärung toggeln */
    toggleDatenschutz: function () {

      this.showDatenschutz = !this.showDatenschutz;

    },
    /** Token aus GET holen und prüfen */
    checkToken: function () {

      this.code = this.$route.params.token;
      if (this.code && this.code !== '') {

        var url  = process.env.VUE_APP_SERVER_URL + '/login/registerCode';
        var that = this;
        axios.get(url, {
          params: {
            code: this.code
          },
          withCredentials: true
        })
          .then(function (response) {

            if (!response.data.success) {
              that.codeerrormessage = response.data.message;
              that.step = 1;
            } else {
              that.codemessage = response.data.message;
              that.step        = 2;
              that.username    = response.data.email;
              // entferne isAbgeschlossen aus Session falls gesetzt
              sessionStorage.isAbgeschlossen = null;
            }
            that.fetchMessages();

          })
          .catch(function (error) {
            console.log(error);
          });

      } else {
        this.fetchMessages();
      }

    },
    /** Passwort vergessen abbrechen - zurück zum Login */
    abbrechen: function () {

      this.$router.push('/');

    },
    /** Passwort speichern */
    submitPassword: function (e) {

      e.preventDefault();
      this.showSpinner();
      var url  = process.env.VUE_APP_SERVER_URL + '/login/register';
      var that = this;
      var params = {
        email:    this.username,
        code:     this.code,
        passwort: this.password
      };
      this.step = null;

      // Send a POST request
      axios({
        method: 'post',
        url: url,
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          that.hideSpinner();
          if (!response.data.success) {
            that.registererrormessage = response.data.message;
            that.step = 3;
          } else {
            console.log('Registrierung erfolgreich.');
            that.$router.push({ path: '/userData' })
          }

        });

    },
    /** Passwort wurde verifiziert und zurückgegeben */
    setPassword: function (val) {

      this.password = val;

    },
    /** E-Mail Adresse absenden zu Step B wechseln */
    submitEMail: function (e) {

      e.preventDefault();
      if (this.isUsernameAndDatenschutzValid) {
        this.showSpinner();
        var params = {
          email: this.username,
          bewerbung_id: this.getBewerbung()
        };
        var url  = process.env.VUE_APP_SERVER_URL + '/login/registerEmail';
        var that = this;
        // Seite ausblenden:
        this.step = null;

        // Send a POST request
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true
        })
          .then(function (response) {

            that.hideSpinner();
            if (!response.data.success) {
              that.errormessage = response.data.message;
              that.step = 0;
            } else {
              that.message    = response.data.message;
              that.message_en = response.data.message_en;
              that.step = 1;
            }

          });
      }

    },
    /** Username von Parent gesetzt */
    setUsername: function (val) {
      this.username = val;
    },
    /** holt die i18n Messages vom Server */
    fetchMessages:function (language) {

      var messages = [
        'fh.register.h1',
        'fh.register.desc',
        'fh.login.insertUsername',
        'fh.login.username',
        'default.button.next.label',
        'fh.register.title',
        'fh.register.code',
        'fh.datenschutz.akzeptieren',
        'fh.datenschutz.akzeptieren.insert',
        'default.button.break',
        'fh.register.desc2',
        'fh.register.desc3',
        'fh.register.desc4',
        'fh.datenschutz.anzeigen',
        'fh.datenschutz.ausblenden'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.register.title'];
      });

    }

  }

}
</script>
