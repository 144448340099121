<!-- Eingabe und Validierung einer Sozialversicherungsnummer -->
<template>

  <span class="insertSvNummerWrapper" v-if="messages">

      <h3 class="mb-2">{{ messages['fh.userData.versicherungsnummer'] }}</h3>
      <div class="form-group row">
        <div class="col-md-2">
          <label for="versicherungsnummer" class="col-form-label" v-html="messages['fh.userData.versicherungsnummer.label'] + '*'"></label>
        </div>
        <div class="col-md-2" v-if="hasNoSvNumber === 'off'">

            <input type="text"
                   pattern="[0-9.]+"
                   maxlength="4"
                   class="form-control"
                   v-bind:class="{ 'is-invalid': !isValid }"
                   name="versicherungsnummer"
                   id="versicherungsnummer"
                   v-model.trim="versicherungsnummer"
                   :readonly="readonly" />

            <div class="invalid-feedback">
              {{ messages['fh.userData.versicherungsnummer.invalid'] }}
            </div>

        </div>
        <div class="col-md-2" v-if="hasNoSvNumber === 'off'">
          <label for="versicherungsnummer" class="col-form-label" v-html="messages['fh.userData.geburtsdatum.format'] + '*'"></label>
        </div>
        <div class="col-md-2" v-if="hasNoSvNumber === 'off'">

            <input type="text"
                   pattern="[0-9X.]+"
                   maxlength="6"
                   class="form-control"
                   v-bind:class="{ 'is-invalid': !isValid }"
                   name="geburtsdatum"
                   id="geburtsdatum"
                   v-model.trim="geburtsdatum"
                   :placeholder="messages['fh.userData.geburtsdatum']"
                   :readonly="readonly" />

        </div>
        <div class="col-md-4">
          <b-form-checkbox
            id="no-sv-number"
            v-model="hasNoSvNumber"
            name="no-sv-number"
            value="on"
            class="mt-2"
            unchecked-value="off">
            {{ messages['fh.userData.versicherungsnummer.no'] }}
          </b-form-checkbox>
        </div>
      </div>

  </span>

</template>

<script>

  import axios from 'axios';
  import { EventBus } from '@/event-bus'
  axios.defaults.withCredentials = true;

  export default {

    name: 'insert-sv-nummer',
    props: ['readonly', 'value'],
    data () {
      return {
        /** 4-stellige Versicherungsnummer */
        versicherungsnummer: null,
        messages: null,
        /** der Geburtsdatums-Teil der SV Nummer */
        geburtsdatum: null,
        /** wenn der User keine SV Nummer hat auf 'off' setzten, sonst 'on' */
        hasNoSvNumber: 'off',
        /** wenn die Versicherungsnummer validiert wurde auf true setzen */
        valide: false
      }
    },
    mounted: function () {

      this.fetchMessages();
      this.init();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    watch: {

      hasNoSvNumber: function (newVal) {

        console.log('hasNoSvNumber: ' + newVal);

        var noSVNumber    = newVal == 'on' ? true : false;
        var isEmitAllowed = false;
        // wenn keine SV Nummer für den Studenten gegeben, dann darf emitted werden
        if (noSVNumber == true) {
          isEmitAllowed = true;
        }

        // Achtung wenn von keine VS Nummer auf VS Nummer umgeschalten wird,
        // dann muss die Versicherungsnummer nochmals validiert werden,
        // da sonst eine mögliche falsche in die Oracle kommt
        if (!isEmitAllowed) {
          isEmitAllowed = this.isValid;
        }

        if (isEmitAllowed) {

          this.emitMe();

        }

      },
      versicherungsnummer: function () {
        this.pruefeVersicherungsnummer(this.versicherungsnummer);
      },
      geburtsdatum: function () {
        this.pruefeVersicherungsnummer(this.versicherungsnummer);
      }

    },
    computed: {

      /** Validiere die eingegebene Versicherungsnummer */
      isValid: function () {

        if (!this.versicherungsnummer) return true;
        if (!this.geburtsdatum) return false;
        if (this.versicherungsnummer.length > 4 || (this.versicherungsnummer.length > 0 && this.versicherungsnummer.length < 4)) {
          return false;
        }

        if (this.valide) return true;
        return false;

      }

    },
    methods: {

      /**
       *     Die Prüfziffer kann wie folgt errechnet werden:
       *
       *     Jede einzelne Ziffer der Versicherungsnummer wird mit einer bestimmten Zahl multipliziert:
       *     Laufnummer mit 3, 7, 9
       *      Geburtsdatum mit 5, 8, 4, 2, 1, 6
       *     Die Prüfziffer errechnet sich aus dem Divisionsrest der Summe der einzelnen Produkte geteilt durch 11.
       *      Wenn der Divisionsrest 10 ergibt, so wird die Laufnummer um 1 erhöht und eine neue Berechnung durchgeführt.
       *
       *     Beispiel:
       *
       *     123X 010180
       *     X = (1×3 + 2×7 + 3×9 + 0×5 + 1×8 + 0×4 + 1×2 + 8×1 + 0×6) mod 11
       *      X = 7
       *     Die Sozialversicherungsnummer lautet somit 1237 010180.
       */
      pruefeVersicherungsnummer: function (nummer) {

        if (!nummer || !this.geburtsdatum) {
          this.valide = false;
          return false;
        }
        // bei z.B. Migranten die ihr Geburtsdatum nicht kennen, wird Pauschal bei der SV-Nr. der 01.13.xxxx angegeben.
        if (this.geburtsdatum == '0113XX') {
          return true;
        }

        // es muss hier auf jeden Fall ein String vorhanden sein
        nummer = nummer.toString()

        var laufnummer = [];
        for (var i = 0; i < nummer.length; i++) {
          var integ = parseInt(nummer.charAt(i));
          if (!Number.isInteger(integ)) {
            console.log('Versicherungsnummer ist keine Nummer' + nummer.charAt(i));
            this.valide = false;
            return false;
          } else {
            laufnummer[i] = integ;
          }
        }
        var geburtsdatum = [];
        for (var i = 0; i < this.geburtsdatum.length; i++) {
          var integ = parseInt(this.geburtsdatum.charAt(i));
          geburtsdatum[i] = integ;
        }

        var kontrollsumme = 0;
        kontrollsumme += laufnummer[0] * 3;
        kontrollsumme += laufnummer[1] * 7;
        kontrollsumme += laufnummer[2] * 9;

        kontrollsumme += geburtsdatum[0] * 5;
        kontrollsumme += geburtsdatum[1] * 8;
        kontrollsumme += geburtsdatum[2] * 4;
        kontrollsumme += geburtsdatum[3] * 2;
        kontrollsumme += geburtsdatum[4] * 1;
        kontrollsumme += geburtsdatum[5] * 6;

        var divisionsrest = kontrollsumme % 11;
        if (divisionsrest == 10) {
          var nextNummer = parseInt(nummer) + 1;
          return this.pruefeVersicherungsnummer(nextNummer);
        }

        if (divisionsrest != laufnummer[3]) {
          this.emitEmpty();
          this.valide = false;
          return false;
        }

        // emitten:
        this.emitMe();
        this.valide = true;
        return true;

      },
      init: function() {

        var that = this;

        // hole das Geburtsdatum
        var url  = process.env.VUE_APP_SERVER_URL + '/student/getSvNummer';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        axios.get(url, {
          params: params,
          withCredentials: true
        })
          .then(function (response) {

            console.log('Versicherungsnummer aus der Datenbank: ' + response.data.nummer);
            that.versicherungsnummer = response.data.nummer;
            that.geburtsdatum        = response.data.geburtsdatum;
            if (response.data.hasNoSvNumber) {
              that.hasNoSvNumber = 'on';
            } else {
              that.hasNoSvNumber = 'off';
            }
            if (that.versicherungsnummer && ('' + that.versicherungsnummer).length === 4) {
              // bereits validiert, sonst wäre sie nicht in der Datenbank:
              that.valide = true;
            }

          });

      },
      /** Versicherungsnummer zurückschreiben an Parent und in Oracle speichern */
      emitMe: function () {

          const that = this;
          // speichere in Oracle und MySQL
          var url  = process.env.VUE_APP_SERVER_URL + '/student/setSvNummer';
          var params = {
            hasNoSvNumber:       this.hasNoSvNumber == 'on' ? true : false,
            versicherungsnummer: this.versicherungsnummer,
            bewerbung_id:        this.getBewerbung(),
            geburtsdatum:        this.geburtsdatum
          }
          axios.get(url, {
            params: params,
            withCredentials: true
          }).then(function (response) {
            console.log('SV Nummer in Oracle gespeichert ' + response);
            that.$emit('input', that.versicherungsnummer);
          });

          this.emitHasNoSvNumber();

      },
      /** leere Versicherungsnummer zurückschreiben an Parent da nicht gültig */
      emitEmpty: function () {
        this.$emit('input', null);
        this.emitHasNoSvNumber();
      },
      emitHasNoSvNumber: function () {
        if (this.hasNoSvNumber === 'on') {
          this.$emit('has-no-sv-number', true);
        } else {
          this.$emit('has-no-sv-number', false);
        }
      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function(language) {

        var messages = [
          'fh.userData.versicherungsnummer',
          'fh.userData.versicherungsnummer.invalid',
          'fh.userData.versicherungsnummer.label',
          'fh.userData.versicherungsnummer.no',
          'fh.userData.geburtsdatum.format'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      }

    }

  }

</script>
