<template>

  <form @submit="checkForm" name="studiengangForm" id="studiengangForm">
    <transition name="fade" mode="out-in">
      <div v-if="messages">

        <check-login></check-login>
        <nav-tabs :active="'Studiengang'"></nav-tabs>

        <h2 v-html="messages['fh.nav.studiengangswahl']"></h2>

        <span>

          <!-- Tabelle mit Studiengängen, welche bereits ausgewählt wurden und dessen Bewerbung bereits abgeschlossen wurde -->
          <transition name="fade" mode="out-in">
            <div v-if="abgeschlossen" class="content-area">
              <h3>{{ messages['fh.studiengang.bestehendeBewerbungen'] }}</h3>
              <div class="card mb-3" v-for="item in studienartenAbgeschlossen" v-bind:key="'ABGESCHLOSSEN_' + item.studienart.enumName">
                <div class="card-header">{{ item.studienart.name }}</div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col"><!-- Name --></th>
                          <th scope="col">{{ messages['fh.studiengang.sprache'] }}</th>
                          <th scope="col">{{ messages['fh.studiengang.studiumStart'] }}</th>
                          <th scope="col">{{ messages['fh.studiengang.standort'] }}</th>
                          <th scope="col">{{ messages['fh.studiengang.bewerbungsende'] }}</th>
                          <th scope="col">{{ messages['fh.studiengang.hauptbewerbung'] }}</th>
                          <th scope="col">{{ messages['fh.studiengang.weitereBewerbung'] }}</th>
                        </tr>
                      </thead>
                      <tbody>
                      <template v-for="aufnahmeverfahren in item.aufnahmeverfahren">
                        <tr :key="'aufnahmeverfahren-row-abgeschlossen-' + aufnahmeverfahren.aufnahmeverfahrenId">
                          <td>{{ aufnahmeverfahren.name }}</td>
                          <td>{{ aufnahmeverfahren.sprache }}</td>
                          <td>{{ aufnahmeverfahren.studiumStart }}</td>
                          <td>{{ aufnahmeverfahren.standort }}</td>
                          <td>{{ aufnahmeverfahren.endDatum ? javaDateToHtml5Date(aufnahmeverfahren.endDatum) : '' }}</td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox"
                                     class="custom-control-input"
                                     :id="'hauptbewerbung_'+aufnahmeverfahren.aufnahmeverfahrenId"
                                     v-model="aufnahmeverfahren.isHauptbewerbung"
                                     true-value="true"
                                     false-value="false"
                                     @change="checkSingleHauptbewerbung($event, aufnahmeverfahren)"
                                     :disabled="abgeschlossen">
                              <label class="custom-control-label" :for="'hauptbewerbung_' + aufnahmeverfahren.aufnahmeverfahrenId"></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox" v-if="aufnahmeverfahren.isHauptbewerbung === 'false'">
                              <input type="checkbox"
                                     class="custom-control-input"
                                     :id="'weitereBewerbung_' + aufnahmeverfahren.aufnahmeverfahrenId"
                                     v-model="aufnahmeverfahren.isWeitereBewerbung"
                                     true-value="true"
                                     false-value="false"
                                     @change="checkWeitereBewerbung($event, aufnahmeverfahren)"
                                     :disabled="abgeschlossen">
                              <label class="custom-control-label" :for="'weitereBewerbung_' + aufnahmeverfahren.aufnahmeverfahrenId"></label>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="bewerbergruppe === 'STUDENT' && aufnahmeverfahren.isHauptbewerbung === 'true' && !aufnahmeverfahren.daten[0].isDummyTermin"
                            :key="'aufnahmeverfahren-is-haupt-bewerbung-abgeschlossen-' + aufnahmeverfahren.aufnahmeverfahrenId">
                          <td colspan="8" style="border-top:0" class="mb-3">

                            <div class="card">

                              <div class="card-header">

                                <div class="form-group row">
                                  <div class="col-md-2">
                                    <label :for="'hauptbewerbungDatum'+aufnahmeverfahren.aufnahmeverfahrenId">{{ messages['fh.studiengang.datumWaehlen.abgeschlossen'] }}</label>
                                  </div>
                                  <div class="col-md-4">

                                    {{ javaDateToHtml5Date( aufnahmeverfahren.daten[0].datum ) + ' ' + (aufnahmeverfahren.daten[0].zeit) + ' ' + messages['fh.studiengang.datumWaehlen.uhr'] }}

                                  </div>
                                  <div class="col-md-6">
                                    {{ aufnahmeverfahren.hauptbewerbungDatum ? aufnahmeverfahren.hauptbewerbungDatum.beschreibung : '' }}
                                  </div>
                                </div>

                              </div>

                            </div>

                          </td>
                        </tr>
                        <tr v-if="bewerbergruppe === 'STUDENT' && aufnahmeverfahren.isWeitereBewerbung === 'true'  && !aufnahmeverfahren.daten[0].isDummyTermin"
                            :key="'aufnahmeverfahren-is-weitere-bewerbung-abgeschlossen-' + aufnahmeverfahren.aufnahmeverfahrenId">
                          <td colspan="8" style="border-top:0" class="mb-3">

                            <div class="card">

                              <div class="card-header">

                                <div class="form-group row">
                                  <div class="col-md-2">
                                    <label :for="'weitereBewerbungDatum'+aufnahmeverfahren.aufnahmeverfahrenId">{{ messages['fh.studiengang.datumWaehlen.abgeschlossen'] }}</label>
                                  </div>
                                  <div class="col-md-4">

                                    {{ javaDateToHtml5Date( aufnahmeverfahren.daten[0].datum ) + ' ' + (aufnahmeverfahren.daten[0].zeit) + ' ' + messages['fh.studiengang.datumWaehlen.uhr'] }}

                                  </div>
                                  <div class="col-md-6">
                                    {{ aufnahmeverfahren.weitereBewerbungDatum ? aufnahmeverfahren.weitereBewerbungDatum.beschreibung : '' }}
                                  </div>
                                </div>

                              </div>

                            </div>

                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div><!-- // bestehende Bewerbungen nach Abschluß -->
          </transition>

          <!-- neue Bewerbungen -->
          <div id="neueBewerbungen" v-if="showNeueBewerbungen">
            <h3>{{ messages['fh.bewerbung.nebenbewerbungHinzufuegen'] }}</h3>
            <hr v-if="studienarten" class="mb-5 mt-5" />
            <transition name="fade" mode="out-in">
              <div v-if="studienarten" class="content-area">
                <span v-for="item in studienarten" v-bind:key="item.studienart.enumName">

                  <div class="card mb-3" v-if="item.aufnahmeverfahren && item.aufnahmeverfahren.length > 0">
                    <div class="card-header">{{ item.studienart.name }}</div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <th scope="col"><!-- Name --></th>
                              <th scope="col">{{ messages['fh.studiengang.sprache'] }}</th>
                              <th scope="col">{{ messages['fh.studiengang.studiumStart'] }}</th>
                              <th scope="col">{{ messages['fh.studiengang.standort'] }}</th>
                              <th scope="col">{{ messages['fh.studiengang.bewerbungsende'] }}</th>
                              <th scope="col" v-if="!abgeschlossen">{{ messages['fh.studiengang.hauptbewerbung'] }}</th>
                              <th scope="col">{{ messages['fh.studiengang.weitereBewerbung'] }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="aufnahmeverfahren in item.aufnahmeverfahren">
                              <tr :key="'aufnahmeverfahren-row-' + aufnahmeverfahren.aufnahmeverfahrenId">
                                <td>{{ aufnahmeverfahren.name }}</td>
                                <td>{{ aufnahmeverfahren.sprache }}</td>
                                <td>{{ aufnahmeverfahren.studiumStart }}</td>
                                <td>{{ aufnahmeverfahren.standort }}</td>
                                <td>{{ aufnahmeverfahren.endDatum ? javaDateToHtml5Date(aufnahmeverfahren.endDatum) : '' }}</td>
                                <td  v-if="!abgeschlossen">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           class="custom-control-input"
                                           :id="'hauptbewerbung_'+aufnahmeverfahren.aufnahmeverfahrenId"
                                           v-model="aufnahmeverfahren.isHauptbewerbung"
                                           true-value="true"
                                           false-value="false"
                                           @change="checkSingleHauptbewerbung($event, aufnahmeverfahren)">
                                    <label class="custom-control-label" :for="'hauptbewerbung_' + aufnahmeverfahren.aufnahmeverfahrenId"></label>
                                  </div>
                                </td>
                                <td>
                                  <div class="custom-control custom-checkbox" v-if="aufnahmeverfahren.isHauptbewerbung === 'false'">
                                    <input type="checkbox"
                                           class="custom-control-input"
                                           :id="'weitereBewerbung_' + aufnahmeverfahren.aufnahmeverfahrenId"
                                           v-model="aufnahmeverfahren.isWeitereBewerbung"
                                           true-value="true"
                                           false-value="false"
                                           @change="checkWeitereBewerbung($event, aufnahmeverfahren)">
                                    <label class="custom-control-label" :for="'weitereBewerbung_' + aufnahmeverfahren.aufnahmeverfahrenId"></label>
                                  </div>
                                </td>
                              </tr>
                              <tr v-if="showHauptbewerbungDatum(aufnahmeverfahren)" :key="'aufnahmeverfahren-is-haupt-bewerbung-' + aufnahmeverfahren.aufnahmeverfahrenId">
                                <td colspan="8" style="border-top:0" class="mb-3">

                                  <div class="card">

                                    <div class="card-header">

                                      <div class="form-group row">
                                        <div class="col-md-2">
                                          <label :for="'hauptbewerbungDatum' + aufnahmeverfahren.aufnahmeverfahrenId">{{ messages['fh.studiengang.datumWaehlen'] }}</label>
                                        </div>
                                        <div class="col-md-4">

                                          <select v-model="aufnahmeverfahren.hauptbewerbungDatum"
                                                  class="form-control"
                                                  v-bind:class="{ 'is-invalid': !aufnahmeverfahren.hauptbewerbungDatum }"
                                                  :id="'hauptbewerbungDatum' + aufnahmeverfahren.aufnahmeverfahrenId">
                                            <option selected="selected" disabled="disabled" :value="null">{{ messages['default.pleaseSelect'] }}</option>
                                            <option v-for="option in aufnahmeverfahren.daten"
                                                    v-bind:value="option"
                                                    v-bind:key="option.id">
                                              {{ javaDateToHtml5Date(option.datum) }} {{ (option.zeit) }} {{ messages['fh.studiengang.datumWaehlen.uhr'] }}
                                            </option>
                                          </select>
                                          <div class="invalid-feedback">
                                            {{ messages['fh.studiengang.datumWaehlen.insert'] }}
                                          </div>

                                        </div>
                                        <div class="col-md-6">
                                          {{ aufnahmeverfahren.hauptbewerbungDatum ? aufnahmeverfahren.hauptbewerbungDatum.beschreibung : '' }}
                                        </div>
                                      </div>

                                    </div>

                                  </div>

                                </td>
                              </tr>
                              <tr v-if="showWeitereBewerbungDatum(aufnahmeverfahren)" :key="'aufnahmeverfahren-is-weitere-bewerbung-' + aufnahmeverfahren.aufnahmeverfahrenId">
                                <td colspan="8" style="border-top:0" class="mb-3">

                                  <div class="card">

                                    <div class="card-header">

                                      <div class="form-group row">
                                        <div class="col-md-2">
                                          <label :for="'weitereBewerbungDatum' + aufnahmeverfahren.aufnahmeverfahrenId">{{messages['fh.studiengang.datumWaehlen']}}</label>
                                        </div>
                                        <div class="col-md-4">
                                          <select v-model="aufnahmeverfahren.weitereBewerbungDatum"
                                                  class="form-control"
                                                  @change="onChangeWeitereBewerbungDatum($event, aufnahmeverfahren.weitereBewerbungDatum)"
                                                  v-bind:class="{ 'is-invalid': !aufnahmeverfahren.weitereBewerbungDatum }"
                                                  :id="'weitereBewerbungDatum' + aufnahmeverfahren.aufnahmeverfahrenId" >
                                            <option selected="selected" disabled="disabled" :value="null">{{messages['default.pleaseSelect']}}</option>
                                            <option v-for="option in aufnahmeverfahren.daten"
                                                    v-bind:value="option"
                                                    v-bind:key="option.id">
                                              {{ javaDateToHtml5Date(option.datum) }}  {{ (option.zeit) }} {{ messages['fh.studiengang.datumWaehlen.uhr'] }}
                                            </option>
                                          </select>
                                          <div class="invalid-feedback">
                                            {{ messages['fh.studiengang.datumWaehlen.insert'] }}
                                          </div>

                                        </div>
                                        <div class="col-md-6">
                                          {{ aufnahmeverfahren.weitereBewerbungDatum ? aufnahmeverfahren.weitereBewerbungDatum.beschreibung : '' }}
                                        </div>
                                      </div>

                                    </div>

                                  </div>

                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </span>
              </div>
            </transition>
          </div>

          <transition name="fade" mode="out-in">
            <div v-if="!studienarten && showNeueBewerbungen">

              <div class="row mb-3">
                <div class="col text-center">{{ messages['fh.studiengang.loading'] }}</div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <div class="lds-ripple"><div></div><div></div></div>
                </div>
              </div>
              <div class="row">
                <div class="col text-center">{{ messages['fh.studiengang.bitteWarten'] }}</div>
              </div>

            </div>
          </transition>

          <!-- derzeit keine Studienarten vorhanden für nonEU Bürger, Nachricht anzeigen wann wieder möglich -->
          <transition name="fade" mode="out-in">
            <div v-if="!studienarten && student && !student.nationalitaet.isEu" class="mt-2">
              <b-alert variant="info" show>{{ messages['fh.studiengang.endeBewerbungsfrist.nonEu'] }}</b-alert>
            </div>
          </transition>

          <div class="form-group row">
            <div class="col-md-10 offset-col-md-2">
              <span v-if="!abgeschlossen && studienarten">
                <!-- Warnung anzeigen, falls noch keine Hauptbewerbung gewählt wurde -->
                <b-alert variant="warning" show v-if="!isHauptbewerbungValid" class="mb-2">
                  <span v-if="bereitsAndereBewerbungText">
                      {{ bereitsAndereBewerbungText }}
                  </span>
                  <span v-else>
                    {{ messages['fh.studiengang.keineHauptbewerbung'] }}
                  </span>
                </b-alert>
                <button type="submit"
                        class="button primary"
                        :disabled="!formValid">
                  {{ messages['default.button.forward.label'] }}
                </button>
              </span>
              <button type="button"
                      v-on:click="next()"
                      v-else-if="!aufnahmeverfahrenWeitereBewerbungenDatum || aufnahmeverfahrenWeitereBewerbungenDatum.length < 1"
                      class="button primary">
                {{ messages['default.button.next.label'] }}
              </button>
              <button type="button"
                      v-on:click="saveZusaetzlicheBewerbungen()"
                      v-else
                      class="button primary">
                {{ messages['fh.studiengang.neueBewerbungen.speichern'] }}
              </button>
            </div>
          </div>

        </span>

      </div>
    </transition>

  </form>

</template>
<style scoped>

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }

</style>
<script>

import NavTabs from './layout/NavTabs.vue'
import CheckLogin from './login/CheckLogin.vue'
// Import the EventBus.
import { EventBus } from '../event-bus.js'
import bootbox from 'bootbox'
import axios from 'axios'
axios.defaults.withCredentials = true

export default {

  name: 'Studiengang',
  data () {
    return {
      messages: null,
      bewerbergruppe: null,
      /** Liste mit Studienarten und Studiengängen */
      studienarten: null,
      /** Liste mit Studienarten und Studiengängen deren Bewerbung bereits gespeichert ist */
      studienartenAbgeschlossen: null,
      /** Hauptbewerbung wurde ausgewählt, nur für leichteres Prüfen der Form */
      hauptbewerbung: null,
      abgeschlossen: null,
      /** Aufnahmeverfahren IDs welche nach dem Abschluß noch hinzugefügt werden können */
      aufnahmeverfahrenWeitereBewerbungenDatum: [],
      /** Warnungs-Text wenn der Bewerber bereits eine andere Hauptbewerbung hat */
      bereitsAndereBewerbungText: null,
      /** weitere Bewerbungen können ausgewählt werden */
      showNeueBewerbungen: true,
      /** der Student um zu prüfen ob er EU ist */
      student: null,
    }
  },
  mounted: function () {

    this.init()

  },
  components: {

    NavTabs,
    CheckLogin

  },
  computed: {

    /**
     * prüfe ob eine Hauptbewerbung ausgewählt wurde
     * @returns {boolean}
     */
    isHauptbewerbungValid: function () {

      // es ein Hauptstudium gewählt worden sein
      if (!this.hauptbewerbung) {
        console.log('es ist noch keine Hauptbewerbung vorhanden.');
        return false;
      }

      // ein STUDENT muss ein Datum ausgewählt haben, INCOMING und AO HÖRER nicht:
      if (this.bewerbergruppe === 'STUDENT') {

        // Aufnahmeverfahren Datum für Hauptbewerbung gesetzt?
        for (var i = 0; i < this.studienarten.length; i++) {

          for (var j = 0; j < this.studienarten[i].aufnahmeverfahren.length; j++) {

            if (this.studienarten[i].aufnahmeverfahren[j].isHauptbewerbung === 'true' && !this.studienarten[i].aufnahmeverfahren[j].hauptbewerbungDatum) {
              console.log('noch kein Datum für Hauptbewerbung: Stundenarten ' + i + ' Aufnameverfahren ' + j);
              return false;
            }

          }

        }

      }

      return true;

    },
    /**
     * prüfe ob die Formulardaten korrekt erfasst wurden
     */
    formValid: function () {

      if (!this.bewerbergruppe) return false;

      // es ein Hauptstudium gewählt worden sein
      if (!this.isHauptbewerbungValid) {
        console.log('es ist noch keine Hauptbewerbung vorhanden.');
        return false;
      }

      // ein STUDENT muss ein Datum ausgewählt haben, INCOMING und AO HÖRER nicht:
      if (this.bewerbergruppe === 'STUDENT') {

        // alle weiteren Studien brauchen ein ausgewählten Datum
        for (var i = 0; i < this.studienarten.length; i++) {

          for (var j = 0; j < this.studienarten[i].aufnahmeverfahren.length; j++) {

            if (this.studienarten[i].aufnahmeverfahren[j].isWeitereBewerbung === 'true' && !this.studienarten[i].aufnahmeverfahren[j].weitereBewerbungDatum) {
              console.log('noch kein Datum: Stundenarten ' + i + ' Aufnameverfahren ' + j);
              return false;
            }

          }

        }

      }

      return true;

    }

  },
  methods: {

    /**
     * soll die Datumsauswahl für die Hauptbewerbung angezeigt werden?
     */
    showHauptbewerbungDatum: function (aufnahmeverfahren) {

      if (!aufnahmeverfahren || !aufnahmeverfahren.daten) return false;

      // wenn das Dummy Datum vorhanden ist, dann braucht keine Datumsauswahl angezeigt werden,
      // es wird gleich das Dummy Datum als hauptbewerbungDatum ausgewählt
      if (aufnahmeverfahren.hauptbewerbungDatum && aufnahmeverfahren.hauptbewerbungDatum.isDummyTermin) {
        return false;
      }

      if (this.bewerbergruppe === 'STUDENT' && aufnahmeverfahren.isHauptbewerbung === 'true') {
        return true;
      }

      return false;

    },
    /**
     * soll die Datumsauswahl für eine weitere Bewerbung angezeigt werden?
     */
    showWeitereBewerbungDatum: function (aufnahmeverfahren) {

      if (!aufnahmeverfahren || !aufnahmeverfahren.daten) return false;

      // wenn das Dummy Datum vorhanden ist, dann braucht keine Datumsauswahl angezeigt werden,
      // es wird gleich das Dummy Datum als Bewerbungsdatum ausgewählt
      for (var i = 0; i < aufnahmeverfahren.daten.length; i++) {
        if (aufnahmeverfahren.daten[i].isDummyTermin) return false;
      }

      if (this.bewerbergruppe === 'STUDENT' && aufnahmeverfahren.isWeitereBewerbung === 'true') {
        return true;
      }

      return false;

    },
    /** zusätzliche Bewerbungen die nach einem Abschluss noch hinzugefügt wurden speichern */
    saveZusaetzlicheBewerbungen: function () {

        console.log('saveZusaetzlicheBewerbungen' + this.aufnahmeverfahrenWeitereBewerbungenDatum.length);
        this.showSpinner();
        var url    = process.env.VUE_APP_SERVER_URL + '/studiengangswahl';
        var ids    = []; // zum Speichern nur die IDs übergeben
        for (var i = 0; i < this.aufnahmeverfahrenWeitereBewerbungenDatum.length; i++) {
            ids.push(this.aufnahmeverfahrenWeitereBewerbungenDatum[i].id);
        }
        var params = {
            aufnahmeverfahren: ids.join(','),
            bewerbung_id: this.getBewerbung()
        };
        var that = this;
        axios({
            method: 'post',
            url: url,
            params: params,
            withCredentials: true
        })
            .then(function (response) {

                that.hideSpinner();

                console.log('Redirect = ' + response.data.redirectTo);
                // Erfolgsmeldung ausgeben:
                if (response.data.redirectTo) {
                  that.aufnahmeverfahren = [];
                  bootbox.alert({
                        message: response.data.message,
                        backdrop: true,
                        /** weiterleiten auf File Upload oder wenn Master Studium auf Daten ergänzen */
                        callback: function () {

                          that.$router.push(response.data.redirectTo);

                        }
                  });
                } else {
                    bootbox.alert({
                        message: that.messages['fh.datenschutz.abschliessen.error'],
                        backdrop: true
                    });
                }

            });

    },
    /** wenn Bewerbung bereits abgeschlossen, dann eine weitere Nebenbewerbung zum Speichern vorbereiten */
    onChangeWeitereBewerbungDatum: function(event, datum) {

        if (this.abgeschlossen) {

          var target = datum;
          console.log('weitere Bewerbung ID: ' + target.id + ' aus Aufnahmeverfahren: ' + target.aufnahmeverfahren.id);

          // Achtung wenn für das gleiche Aufnahmeverfahren bereits ein Datum ausgewählt wurde, dann überschreiben
          var overwrite = false;
          if (this.aufnahmeverfahrenWeitereBewerbungenDatum && this.aufnahmeverfahrenWeitereBewerbungenDatum.length > 0) {
            for (var i = 0; i < this.aufnahmeverfahrenWeitereBewerbungenDatum.length; i++) {
              if (this.aufnahmeverfahrenWeitereBewerbungenDatum[i].aufnahmeverfahren.id === target.aufnahmeverfahren.id) {
                overwrite = true;
                this.aufnahmeverfahrenWeitereBewerbungenDatum[i] = target;
                break;
              }
            }
          }

          if (!overwrite) this.aufnahmeverfahrenWeitereBewerbungenDatum.push(target);

        }

    },
    /**
     * wenn eine Weitere Bewerbung gewählt wurde, darf beim gleichen
     * Aufnahmeverfahren nicht auch Hauptbewerbung gewählt sein
     */
    checkWeitereBewerbung: function (event, aufnahmeverfahren) {

        console.log('weitere Bewerbung angeklickt: ' + event.currentTarget.checked);

        var url    = process.env.VUE_APP_SERVER_URL + '/studiengangswahl/checkDuplicate';
        var that   = this;

        // wenn bereits abgeschlossen, dann in this.aufnahmeverfahrenWeitereBewerbungenDatum hinzufügen:
        if (this.abgeschlossen) {

          // aus Liste entfernen falls vorhanden
          // wenn die Bewerbung bereits abgeschlossen wurde und die weitere Bewerbung
          // weggeklickt wurde, die ID des Aufnahmedatums löschen:
          if (!event.currentTarget.checked) {
            for (var i = 0; i < this.aufnahmeverfahrenWeitereBewerbungenDatum.length; i++) {

              if (aufnahmeverfahren.weitereBewerbungDatum && this.aufnahmeverfahrenWeitereBewerbungenDatum[i].id === aufnahmeverfahren.weitereBewerbungDatum.id) {

                this.aufnahmeverfahrenWeitereBewerbungenDatum.splice(i, 1);
                break;

              } else if (aufnahmeverfahren.daten && aufnahmeverfahren.daten.length > 0 && aufnahmeverfahren.daten[0].isDummyTermin) {

                if (this.aufnahmeverfahrenWeitereBewerbungenDatum[i].id === aufnahmeverfahren.daten[0].id) {
                  this.aufnahmeverfahrenWeitereBewerbungenDatum.splice(i, 1);
                  break;
                }

              }

            }

          } else {

            // prüfe ob der Studiengang nicht schon in einer anderen Bewerbung ausgewählt wurde
            var params = {
              bewerbung_id:         this.getBewerbung(),
              aufnahmeverfahren_id: aufnahmeverfahren.aufnahmeverfahrenId
            }
            axios({
              method: 'get',
              url: url,
              params: params,
              withCredentials: true
            })
              .then(function (response) {

                if (response.data.hasDuplicate) {

                  var message = that.messages['fh.studiengang.bereitsbeworben'];
                  message     = message.replace('{0}', aufnahmeverfahren.name);
                  message     = message.replace('{1}', that.javaDateToHtml5Date(response.data.bewerbung.dateCreated));
                  bootbox.alert ({
                    message:  message,
                    backdrop: true,
                    callback: function () {
                      // deselektieren
                      aufnahmeverfahren.isWeitereBewerbung    = 'false';
                      aufnahmeverfahren.isHauptbewerbung      = 'false';
                    }
                  });

                } else {

                  // kein Duplikat, in Liste der Aufnahmeverfahren aufnehmen:
                  console.log('Kein Duplikat vorhanden, Aufnahmeverfahren ok: ' + aufnahmeverfahren);
                  if (aufnahmeverfahren.weitereBewerbungDatum) {
                    that.aufnahmeverfahrenWeitereBewerbungenDatum.push(aufnahmeverfahren.weitereBewerbungDatum.id);
                  } else if (aufnahmeverfahren.daten && aufnahmeverfahren.daten.length > 0) {
                    // ist es ein Dummy Datum?
                    if (aufnahmeverfahren.daten[0].isDummyTermin) {
                      that.aufnahmeverfahrenWeitereBewerbungenDatum.push(aufnahmeverfahren.daten[0]);
                    }
                  }

                }

              })

          }

        } else {

            aufnahmeverfahren.weitereBewerbungDatum = null;
            if (event.currentTarget.checked) {

              // prüfe ob der Studiengang nicht schon in einer anderen Bewerbung ausgewählt wurde
              var params = {
                bewerbung_id:         this.getBewerbung(),
                aufnahmeverfahren_id: aufnahmeverfahren.aufnahmeverfahrenId
              }
              axios({
                method: 'get',
                url: url,
                params: params,
                withCredentials: true
              })
                .then(function (response) {

                  if (response.data.hasDuplicate) {

                    var message = that.messages['fh.studiengang.bereitsbeworben'];
                    message     = message.replace('{0}', aufnahmeverfahren.name);
                    message     = message.replace('{1}', that.javaDateToHtml5Date(response.data.bewerbung.dateCreated));
                    bootbox.alert ({
                      message:  message,
                      backdrop: true,
                      callback: function () {
                        // deselektieren
                        aufnahmeverfahren.isWeitereBewerbung    = 'false';
                        aufnahmeverfahren.isHauptbewerbung      = 'false';
                      }
                    });

                  } else {

                    aufnahmeverfahren.isHauptbewerbung    = 'false';
                    aufnahmeverfahren.hauptbewerbungDatum = null;

                    // wenn Dummy Datum vorhanden, gleich aufnahmeverfahren.weitereBewerbungDatum setzen
                    for (var i = 0; i < aufnahmeverfahren.daten.length; i++) {
                      if (aufnahmeverfahren.daten[i].isDummyTermin) {
                        aufnahmeverfahren.isWeitereBewerbung    = 'true';
                        aufnahmeverfahren.weitereBewerbungDatum = aufnahmeverfahren.daten[i];
                        break;
                      }
                    }

                  }

                });

            } else {
                // nix zu tun?
            }

        }

    },
    /**
     * prüft beim Start der Komponente, ob eine andere Bewerbung bereits eine
     * Hauptbewerbung hat
     */
    getOtherHauptbewerbung: function () {

      // prüfe ob der User nicht schon andere Hauptbewerbungen hat
      console.log('prüfe ob nicht bereits andere Hauptbewerbung vorhanden...')
      let dieseBewerbungId = this.getBewerbung()
      this.bereitsAndereBewerbungText = null
      const params = {
        bewerbung_id: dieseBewerbungId,
        isNew: true
      }
      const url  = process.env.VUE_APP_SERVER_URL + '/datenschutz/getHauptbewerbungen'
      const that = this
      axios({
        method: 'get',
        url: url,
        params: params,
        withCredentials: true
      }).then(response => {

          if (response.data && response.data.length > 0) {

            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].id != dieseBewerbungId) {
                that.bereitsAndereBewerbungText = that.messages['fh.studiengang.bereitsAndereHauptbewerbung']
                break
              }
            }

          }

      })

    },
    /**
     * prüft ob auch nur eine Hauptbewerbung ausgewählt wurde
     */
    checkSingleHauptbewerbung: function (event, aufnahmeverfahren) {

      console.log('Hauptbewerbung Event geklickt: ' + event.currentTarget.checked);

      if (event.currentTarget.checked) {

        if (this.bereitsAndereBewerbungText) {

          this.hauptbewerbung = null;

          bootbox.alert ({
            message:  this.bereitsAndereBewerbungText,
            backdrop: true,
            callback: function () {
              // deselektieren
              aufnahmeverfahren.isHauptbewerbung    = 'false';
              aufnahmeverfahren.isWeitereBewerbung  = 'false';
            }
          });

        } else {

          // prüfe hier auf eine Doppelbewerbung:
          var params = {
            bewerbung_id:         this.getBewerbung(),
            aufnahmeverfahren_id: aufnahmeverfahren.aufnahmeverfahrenId
          }
          var url    = process.env.VUE_APP_SERVER_URL + '/studiengangswahl/checkDuplicate';
          var that   = this;
          axios({
            method: 'get',
            url: url,
            params: params,
            withCredentials: true
          })
            .then(function (response) {

              if (response.data.hasDuplicate) {

                var message = that.messages['fh.studiengang.bereitsbeworben'];
                message     = message.replace('{0}', aufnahmeverfahren.name);
                message     = message.replace('{1}', that.javaDateToHtml5Date(response.data.bewerbung.dateCreated));
                bootbox.alert ({
                  message:  message,
                  backdrop: true,
                  callback: function () {
                    // deselektieren
                    aufnahmeverfahren.isHauptbewerbung      = 'false';
                  }
                });

              } else {

                var sicheremich = aufnahmeverfahren.hauptbewerbungDatum;

                // die vorherige Hauptbewerbung wird entfernt, es darf nur einen geben!
                for (var i = 0; i < that.studienarten.length; i++) {

                  for (var j = 0; j < that.studienarten[i].aufnahmeverfahren.length; j++) {

                    if (
                      that.studienarten[i].aufnahmeverfahren[j].isHauptbewerbung === 'true' &&
                      aufnahmeverfahren.aufnahmeverfahrenId !== that.studienarten[i].aufnahmeverfahren[j].aufnahmeverfahrenId
                    ) {
                      that.studienarten[i].aufnahmeverfahren[j].isHauptbewerbung    = 'false';
                      that.studienarten[i].aufnahmeverfahren[j].hauptbewerbungDatum = null;
                    }

                  }

                }

                // wenn das Dummy Datum vorhanden ist, dann braucht keine Datumsauswahl angezeigt werden,
                // es wird gleich das Dummy Datum als hauptbewerbungDatum ausgewählt
                for (var i = 0; i < aufnahmeverfahren.daten.length; i++) {
                  if (aufnahmeverfahren.daten[i].isDummyTermin) {
                    sicheremich = aufnahmeverfahren.daten[i];
                    break;
                  }
                }

                aufnahmeverfahren.isWeitereBewerbung    = 'false';
                aufnahmeverfahren.weitereBewerbungDatum = null;
                aufnahmeverfahren.hauptbewerbungDatum   = sicheremich;
                aufnahmeverfahren.isHauptbewerbung      = 'true';
                that.hauptbewerbung                     = aufnahmeverfahren.aufnahmeverfahrenId;

              }

            });

        }

      } else {
          this.hauptbewerbung = null;
      }

    },
    init: function () {

      this.fetchMessages()
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang)
      })

      this.getOtherHauptbewerbung()

      const that = this
      // prüfe ob Bewerbung bereits abgeschickt wurde
      this.isAbgeschlossen(function(res) {
        that.abgeschlossen = res
      })

      this.getBewerbergruppe(function(result) {
        that.bewerbergruppe = result
      })

      // hole den Studenten um zu prüfen ob EU
      const params = {
        bewerbung_id: this.getBewerbung()
      }
      const url = process.env.VUE_APP_SERVER_URL + '/student';
      axios({
        method: 'GET',
        url: url,
        params: params,
        withCredentials: true
      }).then(response => {

        that.student = response.data

      })

    },
    /**
     * holt alle aktiven Studienarten
     */
    findActiveStudienarten: function () {

      this.studienarten = null
      const params = {
        bewerbung_id: this.getBewerbung()
      }
      const url  = process.env.VUE_APP_SERVER_URL + '/studiengangswahl'
      const that = this
      axios({
        method: 'GET',
        url: url,
        params: params,
        withCredentials: true,
      }).then(response => {

        that.hideSpinner()
        that.studienarten               = response.data.moeglich
        that.studienartenAbgeschlossen  = response.data.ausgewaehlt

        if (that.studienarten) {

          that.showNeueBewerbungen = true

          for (var i = 0; i < that.studienarten.length; i++) {

            for (var j = 0; j < that.studienarten[i].aufnahmeverfahren.length; j++) {

              if (that.studienarten[i].aufnahmeverfahren[j].isHauptbewerbung === 'true') {
                console.log('Hauptbewerbung ist gesetzt: ' + that.studienarten[i].aufnahmeverfahren[j].aufnahmeverfahrenId)
                that.hauptbewerbung = that.studienarten[i].aufnahmeverfahren[j].aufnahmeverfahrenId
                break
              }

            }

          }

        } else {

          console.log('Keine weiteren Nebenbewerbungen möglich.')
          that.showNeueBewerbungen = false

        }

      }).catch(function (error) {
        console.log('Fehler beim Abrufen der Studiengänge: ', error)
        that.hideSpinner()
        alert(that.messages['default.error'])
      })

    },
    /** Formulardaten prüfen und Form absenden */
    checkForm: function (e) {

      e.preventDefault();
      if (this.formValid) {

        var hauptbewerbung     = null;
        var weitereBewerbungen = [];

        // bei STUDENT wird das Aufnahmedatum übergeben,
        // bei INCOMING und AO HÖHRER das Aufnahmeverfahren
        if (this.bewerbergruppe === 'STUDENT') {

          for (var i = 0; i < this.studienarten.length; i++) {

            for (var j = 0; j < this.studienarten[i].aufnahmeverfahren.length; j++) {

              if (this.studienarten[i].aufnahmeverfahren[j].isHauptbewerbung === 'true' && this.studienarten[i].aufnahmeverfahren[j].hauptbewerbungDatum) {
                hauptbewerbung = this.studienarten[i].aufnahmeverfahren[j].hauptbewerbungDatum.id;
              } else if (this.studienarten[i].aufnahmeverfahren[j].isWeitereBewerbung === 'true' && this.studienarten[i].aufnahmeverfahren[j].weitereBewerbungDatum) {
                  var id = this.studienarten[i].aufnahmeverfahren[j].weitereBewerbungDatum.id ? this.studienarten[i].aufnahmeverfahren[j].weitereBewerbungDatum.id : this.studienarten[i].aufnahmeverfahren[j].weitereBewerbungDatum;
                  weitereBewerbungen.push(id);
              }

            }

          }

        } else {

            hauptbewerbung = this.hauptbewerbung; // hier wird einfach die ID gespeichert
            for (var i = 0; i < this.studienarten.length; i++) {

                for (var j = 0; j < this.studienarten[i].aufnahmeverfahren.length; j++) {

                    if (this.studienarten[i].aufnahmeverfahren[j].isWeitereBewerbung === 'true') {
                        var id = this.studienarten[i].aufnahmeverfahren[j].aufnahmeverfahrenId;
                        weitereBewerbungen.push(id);
                    }

                }

            }

        }

        const url    = process.env.VUE_APP_SERVER_URL + '/studiengangswahl'
        const params = {
          hauptbewerbung: hauptbewerbung,
          weitereBewerbungen: weitereBewerbungen.join(','),
          bewerbung_id: this.getBewerbung()
        }
        const that = this
        axios({
          method: 'post',
          url: url,
          params: params,
          withCredentials: true
        }).then(function (response) {

          if (response) {
            that.hideSpinner()
            // weiter zum Universitätsreife
            that.next()
          }

        })

      }

    },
    checkKaution: function () {

      const that = this;
      const url = process.env.VUE_APP_SERVER_URL + '/kaution/isKautionBezahlt';
      const params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then( response => {

        if (
          response.data &&
          ( response.data.isKautionBezahlt == 'KEINE_ZAHLUNG_NOETIG' || response.data.isKautionBezahlt == 'BEZAHLT' )
        ) {

          // für Nav setzen:
          that.$store.commit('setHasKaution', false)

        } else {

          // für Nav setzen:
          that.$store.commit('setHasKaution', true)

        }

      })

    },
    next: function () {

      // prüfe Kaution für Menüpunkt
      this.checkKaution()

      // weiter zur Reife
      this.$router.push('Reife')

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      var messages = [
        'fh.nav.studiengangswahl',
        'default.button.forward.label',
        'fh.studiengang.hauptbewerbung',
        'fh.studiengang.weitereBewerbung',
        'fh.studiengang.modus',
        'fh.studiengang.sprache',
        'fh.studiengang.studiumStart',
        'fh.studiengang.standort',
        'fh.studiengang.bewerbungsende',
        'fh.studiengang.datumWaehlen',
        'default.pleaseSelect',
        'fh.studiengang.datumWaehlen.insert',
        'default.button.next.label',
        'fh.studiengang.datumWaehlen.uhr',
        'fh.studiengang.bestehendeBewerbungen',
        'fh.studiengang.neueBewerbungen',
        'fh.studiengang.neueBewerbungen.speichern',
        'fh.datenschutz.abschliessen.error',
        'fh.studiengang.neueBewerbung.gespeichert',
        'fh.nav.studiengangswahl.title',
        'fh.studiengang.loading',
        'fh.studiengang.bitteWarten',
        'fh.studiengang.datumWaehlen.abgeschlossen',
        'fh.studiengang.bereitsbeworben',
        'fh.studiengang.bereitsAndereHauptbewerbung',
        'fh.studiengang.keineHauptbewerbung',
        'fh.bewerbung.nebenbewerbungHinzufuegen',
        'fh.userData.persoenlicheDaten.unvollstaendig',
        'fh.studiengang.weitereBewerbung.kautionFehlt',
        'fh.studiengang.endeBewerbungsfrist.nonEu',
        'default.error'
      ]
      const that = this
      this.getMessages(language, messages, function(resp) {

        that.messages = resp
        // Seiten Titel setzen:
        document.title = that.messages['fh.nav.studiengangswahl.title']
        // Studienarten holen
        that.findActiveStudienarten()

      })

    }

  }

}
</script>
