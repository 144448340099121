<template>

  <div class="form-group row" v-if="messages">
    <div class="col-md-2">
      <label for="username">{{messages['fh.login.username']}}*</label>
    </div>
    <div class="col-md-10">
      <input type="email"
             maxlength="100"
             class="form-control"
             v-bind:class="{ 'is-invalid': !isUsernameValid }"
             id="username"
             name="username"
             required
             :placeholder="messages['fh.login.username']"
             v-model="username">
      <div class="invalid-feedback">
        {{messages['fh.login.insertUsername']}}
      </div>
    </div>
  </div>

</template>

<script>

// Import the EventBus.
import { EventBus } from '../../event-bus.js';

export default {

  name: 'username-form-row',
  data () {
    return {
      messages: null,
      username: null
    }
  },
  watch: {
    /** eingegebenen Benutzername an Parent zurückgeben */
    username: function (val) {

      if (this.isUsernameValid) {
        this.$emit('set-username', val);
      } else {
        this.$emit('set-username', null);
      }

    }
  },
  computed: {

    /** prüft ob der Benutzername eingegeben wurde */
    isUsernameValid: function () {

      if (this.username && this.username !== '') {
        return this.validEmail(this.username);
      }

      return true;

    }

  },
  mounted: function () {

    this.fetchMessages();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.login.username',
        'fh.login.insertUsername'
      ];

      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
      });

    }

  }

}

</script>
