<template>

  <transition name="fade" mode="out-in">
    <div class="datenschutz" v-if="text">

      <div v-html="text"></div>

    </div>
  </transition>

</template>
<script>

  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  import axios from 'axios';
  axios.defaults.withCredentials = true;

  export default {

    name: 'datenschutz-text',
    data () {
      return {
        text: null
      }
    },
    mounted: function () {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    methods: {

      /** holt den Datenschutz Text vom Server */
      fetchMessages: function(language) {

        var that = this;

        var url    = process.env.VUE_APP_SERVER_URL + '/datenschutz/getText';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        var lang   = language && language != null ? language : localStorage.language;
        if (lang) {
          params.lang = lang;
        }

        axios.get(url, {
          params:params,
          withCredentials: true
        })
          .then(function (response) {

            that.text = response.data;

          });

      }

    }

  }

</script>
