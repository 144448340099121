<template>

  <div class="newPasswordsRows" v-if="messages">

    <div class="form-group row">
      <div class="col-md-2">
        <label for="password">{{messages['fh.login.password']}}*</label>
      </div>
      <div class="col-md-10">
        <input type="password"
               maxlength="100"
               class="form-control"
               v-bind:class="{ 'is-invalid': !isPasswordValid || !isPasswordsSame }"
               id="password"
               name="password"
               placeholder="Password"
               required
               v-model="password">
        <div class="invalid-feedback">
          <span v-if="!isPasswordValid">{{ messages['fh.login.insertPassword'] }}</span>
          <span v-if="!isPasswordsSame">{{ messages['fh.newPassword.notSame'] }}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-2">
        <label for="password2">{{messages['fh.login.password.repeat']}}*</label>
      </div>
      <div class="col-md-10">
        <input type="password"
               class="form-control"
               v-bind:class="{ 'is-invalid': !isPassword2Valid || !isPasswordsSame }"
               id="password2"
               name="password2"
               required
               maxlength="100"
               placeholder="Password"
               v-model="password2">
        <div class="invalid-feedback">
          <span v-if="!isPassword2Valid">{{messages['fh.login.insertPassword']}}</span>
          <span v-if="!isPasswordsSame">{{messages['fh.newPassword.notSame']}}</span>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

// Import the EventBus.
import { EventBus } from '../../event-bus.js';

export default {

  name: 'new-password-form-row',
  data () {
    return {
      messages: null,
      password: null,
      password2: null
    }
  },
  computed: {

    /** prüft ob das Passwort eingegeben wurde */
    isPasswordValid: function() {

      if (this.password && this.password !== '') {
        return this.checkPasswordRules(this.password);
      }

      return true;

    },
    /** prüft ob das Passwort 2 eingegeben wurde */
    isPassword2Valid: function() {

      if (this.password2 && this.password2 !== '') {
        return this.checkPasswordRules(this.password2);
      }

      return true;

    },
    /** prüft ob die beiden Passwörter übereinstimmen */
    isPasswordsSame: function() {

      if (this.password === this.password2) return true
      return false

    }

  },
  mounted: function () {

    this.fetchMessages();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  watch: {
    /** eingegebenes Passwort an Parent zurückgeben */
    password2: function (val) {
      this.emitPassword(val);
    },
    password: function (val) {
      this.emitPassword(val);
    }
  },
  methods: {

    /**
     * Passwort an Parent zurückgeben
     */
    emitPassword: function(val) {

      if (this.isPasswordsSame && this.isPassword2Valid && this.isPasswordValid) {
        this.$emit('set-password', val);
      } else {
        this.$emit('set-password', null);
      }

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function (language) {

      var messages = [
        'fh.login.password',
        'fh.login.insertPassword',
        'fh.newPassword.notSame',
        'fh.login.password.repeat'
      ];

      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
      });

    }

  }

}

</script>
