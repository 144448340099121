<template>

  <div id="spinner" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="Loading ..." aria-hidden="true">

    <div class="modal-dialog modal-sm modal-dialog-centered" v-if="messages">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">{{ messages['fh.spinner.loading'] }}</span>
          <button type="button" class="closeme" data-dismiss="modal" :aria-label="messages['default.button.close.label']">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div class="lds-ripple"><div></div><div></div></div>
        </div>
      </div>
    </div>

  </div>

</template>
<style scoped>

</style>
<script>

// Import the EventBus.
import { EventBus } from '../../event-bus.js';

export default {

  name: 'Spinner',
  data () {
    return {
      messages: null
    }
  },
  mounted: function () {

    this.fetchMessages();
    // Listen for the set-language event on EventBus
    EventBus.$on('set-language', lang => {
      this.fetchMessages(lang);
    });

  },
  methods: {

    /** holt die i18n Messages vom Server */
    fetchMessages:function(language) {

      var messages = [
        'fh.spinner.loading',
        'default.button.close.label'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
      });

    }

  }

}
</script>
