<template>

  <div class="selectSprachkenntnisseWrapper" v-if="messages">

    <div class="form-group row">
      <div class="col-md-2">
        <label for="englischTestPunkte" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.english'] }}*</label>
      </div>
      <div class="col-md-9">
        <select-sprachkenntnisse-scala
          id="englischTestPunkte"
          :id-prefix="'englisch'"
          v-model="englischScoreData"
          :readonly="readonly">
        </select-sprachkenntnisse-scala>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-2">
        <label for="deutschTestPunkte" class="col-form-label">{{ messages['fh.userData.incomings.sprachkenntnisse.sprachniveau.deutsch'] }}*</label>
      </div>
      <div class="col-md-9">
        <select-sprachkenntnisse-scala
          id="deutschTestPunkte"
          v-model="deutschScoreData"
          :id-prefix="'deutsch'"
          :readonly="readonly">
        </select-sprachkenntnisse-scala>
      </div>
    </div>

  </div>

</template>

<script>

  // Import the EventBus.
  import { EventBus } from '../../event-bus.js';
  import SelectSprachkenntnisseScala from './SelectSprachkenntnisseScala';

  export default {

    name: 'select-sprachkenntnisse',
    components: {

      SelectSprachkenntnisseScala

    },
    props: {
      readonly: null,
      deutschScore: null,
      englischScore: null
    },
    data () {
      return {
        messages: null,
        deutschScoreData: null,
        englischScoreData: null
      }
    },
    mounted: function () {

      this.init();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

    },
    watch: {

      deutschScoreData: function (newVal, oldVal) {

        if (newVal !== oldVal) {
          this.emit();
        }

      },
      englischScoreData: function (newVal, oldVal) {

        if (newVal !== oldVal) {
          this.emit();
        }

      }

    },
    methods: {

      init: function () {

        if (this.deutschScore) {
          this.deutschScoreData = this.deutschScore;
        }
        if (this.englischScore) {
          this.englischScoreData = this.englischScore;
        }
        this.fetchMessages();

      },
      /** holt die i18n Messages vom Server */
      fetchMessages: function (language) {

        var messages = [
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.english',
          'fh.userData.incomings.sprachkenntnisse.sprachniveau.deutsch'
        ];
        var that = this;
        this.getMessages(language, messages, function(resp) {
          that.messages = resp;
        });

      },
      /** emit Sprachkenntnisse */
      emit: function () {

        var obj = {
          englischScore: this.englischScoreData,
          deutschScore: this.deutschScoreData
        };
        this.$emit('set-score', obj);

      }

    }

  }

</script>
