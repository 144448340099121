<template>

  <form @submit="checkForm" name="umfrageForm" id="umfrageForm">
    <transition name="fade" mode="out-in">

      <div  v-if="messages">

        <check-login></check-login>
        <nav-tabs :active="'Umfrage'"></nav-tabs>

        <h2 v-html="messages['fh.nav.umfrage']"></h2>

        <hr class="mt-5 mb-5">

        <div class="fragen"
             v-for="item in fragen"
             v-bind:key="item.frage.bezeichnung">
              <transition name="fade" mode="out-in">
                <frage-table :frage="item.frage"
                             :fragen="item.fragen"
                             v-on:set-frage="setFrage"
                             :readonly="abgeschlossen">
                </frage-table>
              </transition>
        </div>

        <div class="form-group row mt-3">
          <div class="col-md-10 offset-col-md-2">
            <button type="submit"
                    class="button primary"
                    v-if="!abgeschlossen"
                    :disabled="!formValid">{{messages['default.button.forward.label']}}</button>
            <button type="button"
                    v-on:click="next()"
                    v-else
                    class="button primary">{{messages['default.button.next.label']}}</button>
          </div>
        </div>

      </div>

    </transition>

  </form>

</template>
<style scoped>
  .custom-control-label::before {
    position: relative;
  }
</style>

<script>

import axios from 'axios';
import NavTabs from './layout/NavTabs.vue';
import CheckLogin from './login/CheckLogin.vue';
import FrageTable from './umfrage/FrageTable';
// Import the EventBus.
import { EventBus } from '../event-bus.js';
axios.defaults.withCredentials = true;

export default {

  name: 'Umfrage',
  data () {
    return {
      messages: null,
      fragen: null,
      abgeschlossen:null
    }
  },
  mounted: function () {

    this.init();

  },
  components: {
    NavTabs,
    CheckLogin,
    FrageTable
  },
  computed: {

    /**
     * prüfe ob die Formulardaten korrekt erfasst wurden
     */
    formValid: function () {

      if (!this.fragen) return false;

      for (var i = 0; i < this.fragen.length; i++) {

        // prüfe zuerst ob eine Anmerkung eingegeben wurde:
        var anmerkungExists = false;
        for (var j = 0; j < this.fragen[i].fragen.length; j++) {

          var anmerkung = this.fragen[i].fragen[j].anmerkung;
          if (anmerkung && anmerkung.length > 2) {
            anmerkungExists = true;
            break;
          }

        }

        // wenn keine Anmerkung dann prüfe die Radios
        if (!anmerkungExists) {
          for (var j = 0; j < this.fragen[i].fragen.length; j++) {

            if (this.fragen[i].fragen[j].hasAnmerkung) continue;

            if (!this.fragen[i].fragen[j].einflussAusmass) {
              console.log('Frage ' + this.fragen[i].fragen[j].bezeichnung + ' noch nicht ausgefüllt');
              return false;
            }

          }
        }

      }

      return true;

    }

  },
  methods: {

    setFrage: function(val) {

      for (var i = 0; i < this.fragen.length; i++) {

        if (this.fragen[i].frage.frage === val.frage.frage) {
          this.fragen[i].fragen = val.fragen;
          break;
        }

      }

    },
    init: function() {

      // weiter zum Datenschutz für AO und Incoming
      var that = this;
      this.getBewerbergruppe(function(result) {
        if (result !== 'STUDENT') {
          that.$router.push('Datenschutz');
        }
      });

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
      });

      // prüfe ob Bewerbung bereits abgeschickt wurde
      this.isAbgeschlossen(function(res) {
        that.abgeschlossen = res;
      });

    },
    /** hole die Fragen aus der DB */
    getFragen: function() {

        var that = this;
        var url = process.env.VUE_APP_SERVER_URL + '/umfrage/fragen';
        var params = {
          bewerbung_id: this.getBewerbung()
        }
        axios({
            method: 'get',
            url: url,
            params: params,
            withCredentials: true
        }).then(function (daten) {

            console.log('die Fragen ' + daten);
            if (daten && daten.data) {

                that.fragen = daten.data;

            }

        });

    },
    /** Formulardaten prüfen und Form absenden */
    checkForm: function (e) {

      e.preventDefault();
      if (this.formValid) {

        var fragenlist = {};
        var anmerkungenlist = {};
        for (var i = 0; i < this.fragen.length; i++) {

          for (var j = 0; j < this.fragen[i].fragen.length; j++) {

            fragenlist[this.fragen[i].fragen[j].id] = this.fragen[i].fragen[j].einflussAusmass;
            if (this.fragen[i].fragen[j].anmerkung) {
              anmerkungenlist[this.fragen[i].fragen[j].id] = this.fragen[i].fragen[j].anmerkung;
            }

          }

        }

        var params = {
          fragen: JSON.stringify(fragenlist),
          anmerkungen: JSON.stringify(anmerkungenlist),
          bewerbung_id: this.getBewerbung()
        };

        var that = this;
        this.showSpinner();
        var url = process.env.VUE_APP_SERVER_URL + '/umfrage';
        axios({
          method: 'post',
          url: url,
          withCredentials: true,
          params: params
        }).then(function (daten) {

          if (daten) {
            that.hideSpinner();
            // weiter zum Dateiupload
            that.next();
          }

        });

      }

    },
    next: function () {

      // weiter zum Upload
      this.$router.push('Datenschutz');

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      var messages = [
        'fh.nav.umfrage',
        'default.button.forward.label',
        'default.button.next.label',
        'fh.nav.umfrage.title'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {

        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.nav.umfrage.title'];

        // hole die Fragen vom Server:
        that.getFragen();

      });

    }

  }

}
</script>
