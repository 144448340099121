<template>

  <transition name="fade" mode="out-in">
    <div class="alert alert-info" role="alert" v-if="message" v-html="message">
    </div>
  </transition>

</template>

<script>

export default {

  name: 'info-message',
  props: {
    message: {
      type: String,
      required: false
    }
  }

}

</script>
